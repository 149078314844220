import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Progress from '../util/Progress';
import Auth from '../../helpers/Auth';
import * as Constants from '../../constants/global';
import axios from 'axios';

// IMAGENES
import success from '../../assets/icon/success.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/generico.png';

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//tagmanager
// import TagManager from 'react-gtm-module'
import ReactGa from 'react-ga'

const query = require('query-string');

const PaymentResponse = (props) => {

    const [loader, setLoader] = useState(false);
    const [cliente, setCliente] = useState();
    const [params, setParams] = useState();
    const [respuestaPago, setRespuestaPago] = useState()
    const [urlReference, setUrlReference] = useState()
    const [urlAmount, setUrlAmount] = useState()

    const handleParams = () => {
        
        let renueve = (query.parse(props.location.search)).amount
        let cartera = JSON.parse(Auth.getCartera())
        let facturas = []

        let URLactual = (new URL(document.location)).searchParams;
        console.log(URLactual);
        setUrlReference(URLactual.get("reference"))
        setUrlAmount(URLactual.get("amount"))
        setParams(URLactual)
        let respuestaPago = URLactual.get("decision")
        setRespuestaPago(respuestaPago)
        console.log(respuestaPago)
        if (respuestaPago == "ERROR") {
            ReactGa.event({
                category: "Payment Proccess",
                action: "payment accepted",
                label: "Pago Tarjeta"
            })
        } else {
            ReactGa.event({
                category: "Payment Proccess",
                action: "payment declined",
                label: "Pago Tarjeta"
            })
        }
        if (query.parse(props.location.search) && cartera) {
            cartera.map((item, index) => {
                console.log(item);
                facturas.push({
                    numeroDoc: (query.parse(props.location.search).reference),
                    factura: item.referencia,
                    valor: item.saldo,
                    documentoSAP: item.documentoSAP,
                    ejercicio: item.ejercicio,
                    documentoFiscal: item.documentoFiscal,
                    financiero: item.financiero,
                    codCliente: item.codCliente
                })
            })
            console.log(facturas);
            axios.post(Constants.WSDETALLEPAGO + Auth.getCodCliente(), {
                facturas: facturas
            }).then((response) => {
                console.log(response);
                let data = (JSON.parse(Auth.getCartera()))
                console.log(data);
                let invoices = []
                data.forEach(factura => {
                    let nc = []
                    console.log(factura);
                    if (factura.notaCredito.length > 0) {
                        factura.notaCredito.forEach(ntc => {
                            nc.push({
                                'tax_document': ntc.NumFiscal,
                                'document': ntc.documento,
                                'balance': ntc.saldo
                            })
                        });
                    }
                    invoices.push({
                        'codeClient': factura.codCliente,
                        'taxDocument': factura.documentoFiscal,
                        'sapDocument': factura.documentoSAP,
                        'blackberry': factura.mora,
                        'balance': factura.saldo,
                        'reference': factura.referencia,
                        'businessName': factura.Razonsocial,
                        'creditNote': nc
                    })
                });
                console.log(data);
                // TagManager.dataLayer({
                //     dataLayer:{
                //         event:'purchase',
                //         ecommerce:{ 
                //            purchase:{
                //                actionField:{
                //                     id:query.parse(props.location.search).reference,
                //                     renueve:renueve,
                //                     affiliation: '',     
                //                     tax: 0,    
                //                     shipping: 0
                //                },
                //                invoices:invoices
                //            }
                //         }
                //     }
                // })
                Auth.setCartera({});
                Auth.setCarrito({});
                props.ActualizarCarrito({});
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const handleInfoClient = () => {
        axios.get(Constants.WSINFOCLIENT + Auth.getCodCliente()).then((response) => {
            setCliente(response.data[0])
        })
    }

    const handleRedirect = () => {
        props.history.push("/")
    }

    useEffect(() => {
        handleParams()
        handleInfoClient()


    }, [])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={5} cantidad={0} totalNavbar={0} />
            </div>

            <div className="cartera">
                <div className="card">
                    {params ?
                        <React.Fragment>
                            <img style={{ width: "20%" }}
                                src={respuestaPago == "ERROR" ? error : success}
                                alt="icon error"
                                onError={(e) => { e.target.onerror = null; e.target.src = noimage }} />

                            <div className="pedido-list">
                                {loader ? <Progress color={"#007daf"} /> : (
                                    <div className="table-responsive">
                                        <p><b>Transacción: </b><span style={{ color: (respuestaPago == "ERROR" ? "red" : "green") }}>{respuestaPago == "ERROR" ? "RECHAZADA" : "APROBADA"}</span></p> <br /><br />
                                        <p><b>Cliente: </b>{cliente ? cliente.Nombre : ""}</p>
                                        <p><b>Razón social: </b>{cliente ? cliente.RazonSocial : ""}</p>
                                        <p><b>Código cliente: </b>{cliente ? cliente.Codigo : ""}</p>
                                        <p><b>Referencia: </b>{urlReference}</p>
                                        <p><b>Transacción: </b>{params.transaction_id}</p>
                                        <p><b>Total: </b>{urlAmount}</p><br /><br />
                                        <button className="btn-large" onClick={e => handleRedirect()}>Finalizar</button>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                        : null}
                </div>
            </div>
        </React.Fragment>
    )

}

const mapStateProps = (state) => {
    return {
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateProps, { ActualizarCarrito })(PaymentResponse));