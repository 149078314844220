import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import close from '../../assets/icon/close_grey.svg';
import * as Constants from '../../constants/global';

const DetallePagoModal = (props) => {

    const image = props.detalle.adjunto

    const handleClose = () => {
        document.querySelector(".modal-detalle-pago").classList.remove("show-modal");
    }

    useEffect(()=>{
        document.querySelector(".modal-detalle-pago").classList.toggle("show-modal")
        console.log(props.detalle)
    },[props.detalle])

    return (
        <div className="modal modal-detalle-pago">
            <div className="modal-content" style={{width:"100%"}}>       
                <div className="title">
                    <p>Detalle pago en linea</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="observacion">
                    <p><b>Observaciones: </b></p>
                    <label>{props.detalle.observaciones}</label>
                    <br/><br/>
                    <p>
                        <labe>
                            {
                                image == '-1' ? <p>Documento adjunto: <labe> Sin archivo adjunto </labe></p>: <p>Documento adjunto: <labe> <a href={process.env.REACT_APP_URL_API + "SoportePagos/imagenes/" + props.detalle.numeroDoc + "." + props.detalle.adjunto} target="_blank">{ props.detalle.numeroDoc + "." + props.detalle.adjunto}</a></labe></p>
                            }

                        </labe>
                    </p>
                    {/* <p>Documento adjunto: <labe><a href={process.env.REACT_APP_URL_API + (props.detalle.adjunto=="png" ? "SoportePagos/imagenes/" : "SoportePagos/PDFs/") + props.detalle.numeroDoc + "." + props.detalle.adjunto} target="_blank">{ props.detalle.numeroDoc + "." + props.detalle.adjunto}</a></labe></p> */}
                </div>
                <div >    
                    <div>
                        {props.detalle.detalle ? (
                            <React.Fragment>
                                <DataTable 
                                    value={props.detalle.detalle} 
                                    paginator resizableColumns rows={ 5 } 
                                    autoLayout={true}
                                >
                                    <Column header="CÓDIGO CLIENTE" style={{textAlign:"center"}} body={ (rowData,column) => (
                                        props.detalle.codCliente ? props.detalle.codCliente : ""
                                    )}></Column>
                                    <Column field="nombre" header="NOMBRE" style={{textAlign:"center"}}></Column>
                                    <Column field="NumFiscal" header="NUMERO FISCAL" ></Column>
                                    <Column field="claseDocumento" header="CLASE DOCUMENTO" style={{textAlign:"center"}}></Column>
                                    <Column field="fechaDoc" style={{textAlign:"center"}} header="FECHA DOCUMENTO"body={(rowData, column) => 
                                            (new Date(rowData['fechaDoc'])).toLocaleDateString()
                                        }>
                                    </Column>
                                    <Column field="factura" style={{textAlign:"center"}} header="FACTURA"></Column>
                                    <Column field="valor" header="VALOR" style={{textAlign:"center"}} body={(rowData, column) => 
                                           Constants.formatNumber(rowData['valor'])
                                        }>
                                    </Column>                                                
                                </DataTable>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetallePagoModal);