import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";

import * as Constants from '../../constants/global';
import { isMobile }  from './../../helpers/Device';
import Auth from '../../helpers/Auth';
import logo_login from '../../assets/img/logo_login.png';
import noimage from '../../assets/img/generico.png';
import carrito from '../../assets/icon/carrito.svg';
//import {ReactComponent as Back} from '../../assets/icon/back.svg';
import {ReactComponent as Logout_icon} from '../../assets/icon/logout_icon.svg';
import SucursalModal from '../modal/SucursalModal';
import address from '../../assets/icon/address.svg'

// REDUX
import ActualizarUsuario from "../../store/usuario/action";
import ActualizarCarrito from "../../store/carrito/action";
import {selectActiveUser} from '../../store/usuario/reducer';
import {selectActiveCarrito} from '../../store/carrito/reducer';

const Navbar = (props) => {

    const [ciudad, setCiudad] = useState();
    const [sucursal, setSucursal] = useState();
    const [cantidad, setCantidad] = useState();
    const [total, setTotal] = useState()
    const [empresa, setEmpresa] = useState();

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    /* const handleBack= item => {
        props.history.goBack();
    }*/

    const handleLogOut= item => {
            localStorage.setItem("carritoAbandonado" , 1 )
            props.history.push("/carrito")
            setTimeout(() => {
                Auth.signout();
                window.location.reload(true);
            }, 500);
    }
 
    window.onscroll = function() {
        if(isMobile()){
            if(document.getElementById("nav-hidden")){
                var currentScrollPos = window.pageYOffset;
                if (currentScrollPos > 20) {
                    document.getElementById("nav-hidden").style.display = "none";
                    document.getElementById("nav-hidden").style.opacity = "0";
                    document.getElementById("nav-hidden").style.transform = "translate(9999px)";
                } else {
                    document.getElementById("nav-hidden").style.display = "initial";
                    document.getElementById("nav-hidden").style.opacity = "initial";
                }
            }
        } 
    }

    const handleCiudad = () =>{
        setCiudad(JSON.parse(Auth.getSucursal()).Nombre)
    }

    const handleSucursal = () => {
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }

    const handleCantidad = () => {
        var cant = 0;
        let cart = props.carrito || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }

    const handleTotal = () => {
        let total = 0
        let carrito = props.carrito || {}
        Object.keys(carrito).map((item,i)=>{
            total+=(carrito[item].precio * carrito[item].cantidad)
        })
        setTotal(total)
    }

    useEffect(() => {
        setEmpresa(Auth.getEmpresa())
        let sucurl = (JSON.parse(Auth.getSucursal()))
        if(sucurl){
            setSucursal(sucurl);
            handleCiudad();
        }
        handleCantidad()
        handleTotal()
    },[ciudad,props.carrito]) 

    return (
        <div className="nav-header" id="nav-hidden">
            <div className="nav-content">
                <div className="subnav">
                    {/*<div className="back" onClick={() => handleBack()} title="Atrás">
                        <Back className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                    </div> */}                    
                    <div className="nav-logo">
                        <img 
                            src={logo_login}
                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                        </img>
                    </div>
                </div>
                <div className="navmenu">
                    <ul>
                        {/* <li>
                            <a  className={ (props.item == 1 ? "tab active" : "tab")}
                                onClick={ () => handleRedirect('/pedido')}>
                                    Pedido
                            </a>
                        </li> */}
                        <li>
                            <a  className={props.item == 2 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/sugerido')}>
                                    Sugerido
                            </a>
                        </li>
                        <li>
                            <a  className={props.item == 3 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/catalogo')}>
                                    Catálogo
                            </a>
                        </li>
                        {/* <li>
                            <a className={props.item == 4 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/excel')}>
                                Excel
                            </a>
                        </li> */}                        
                        <li>
                            <a className={props.item == 5 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/ultimosPedidos')}>
                                Últimos Pedidos
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 6 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/cartera')}>
                                Pagos
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 7 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/historial/pagos')}>
                                Historial de Pagos
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 8 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/contactenos')}>
                                Contacto
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 9 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/ayuda')}>
                                Ayuda
                            </a>
                        </li>
                        {sucursal ? 
                            <li>
                                <a className="sucursal" onClick={e=>handleSucursal()}>
                                    <img  src={address}></img>
                                    <p>
                                        {ciudad ? ciudad : ''}
                                    </p>
                                </a>
                               
                            </li>
                        : null }
                        <li>
                            <a className={props.item == 10 ? "tab cart_active" : "tab cart"}
                                onClick={ () => handleRedirect('/carrito')}>
                                <img src={carrito} alt="icon menu" width="15px" height="15px"></img>
                                <div className="cart-counter">{ cantidad ? cantidad : 0 }</div>
                                <p>{/* {Constants.copFormat(props.totalNavbar)} */}
                                    {Constants.formatNumber(total ? total : 0)}
                                </p>
                            </a>
                        </li>  
                                          
                    </ul>
                    
                   
                </div>
                <div className="back" onClick={() => handleLogOut()} title="Cerrar Sesión">
                            {/* <img src={back} alt="icon menu" width="15px" height="15px"></img> */}
                            <Logout_icon className="svg-una-capa" style={{width:"40px", height:"40px"}}/>
                            <div className="titulo">
                            <p >Cerrar Sesión</p>  
                            </div>
                             
                        </div>    
            </div>
            <SucursalModal></SucursalModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}
  

export default  withRouter(connect(mapStateToProps,{ActualizarCarrito, ActualizarUsuario}) (Navbar));