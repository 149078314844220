import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
/* import logo from '../../assets/img/logo.png';
import pedido from '../../assets/icon/pedido.svg';
import sugerido from '../../assets/icon/sugerido.svg';
import catalogo from '../../assets/icon/catalogo.svg';
import excel from '../../assets/icon/excel.svg';
//import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';
import cartera from '../../assets/icon/cartera.svg'; */
import Auth from '../../helpers/Auth';
import SucursalModal from '../modal/SucursalModal';


const Tabbar = (props) => {

    const [ciudad, setCiudad] = useState();
    const [sucursal, setSucursal] = useState();

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    const handleCiudad = () =>{
        setCiudad(JSON.parse(Auth.getSucursal()).Nombre)
    }

    const handleSucursal = () => {
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }

    useEffect(()=>{
        let sucurl = (JSON.parse(Auth.getSucursal()))
        if(sucurl){
            setSucursal(sucurl);
            handleCiudad();
        }
    },[ciudad])

    return (
        <React.Fragment>
            <div className="tabmenu">
                <div className="tab-content" style={{gridTemplateColumns: sucursal ?"repeat(8, 1fr)" : null}}>
                    {/* <div className={props.item == 1 ? "tab active" : "tab"} 
                        onClick={ () => handleRedirect('/pedido')}>
                        <img src={pedido} alt="icon menu" width="20px" height="20px"></img>
                        <span>Pedido</span>
                    </div> */}
                    <div className={props.item == 2 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/sugerido')}>
                        {/**<img src={sugerido} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Sugerido</span>
                    </div>
                    <div className={props.item == 3 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/catalogo')}>
                        {/**<img src={catalogo} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Catálogo</span>
                    </div>
                    {/* <div className={props.item == 4 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/excel')}>
                        <img src={excel} alt="icon menu" width="20px" height="20px"></img>
                        <span>Excel</span>
                    </div> */}
                    <div className={props.item == 5 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/ultimosPedidos')}>
                        {/**<img src={cartera} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Últimos Pedidos</span>
                    </div>
                    <div className={props.item == 6 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/cartera')}>
                        {/**<img src={carrito} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Pagos</span>
                    </div>
                    <div className={props.item == 7 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/historial/pagos')}>
                        {/**<img src={carrito} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Historial de Pagos</span>
                    </div>
                    {/* <div className={props.item == 8 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/contactenos')}>
                        <img src={carrito} alt="icon menu" width="20px" height="20px"></img>
                        <span>Contacto</span>
                    </div>
                    <div className={props.item == 9 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/ayuda')}>
                        <img src={carrito} alt="icon menu" width="20px" height="20px"></img>
                        <span>Ayuda</span>
                    </div> */}
                    {sucursal ? 
                        <div className="tab"
                            onClick={ () => handleSucursal()}>
                           {/** <img src={carrito} alt="icon menu" width="20px" height="20px"></img>*/}
                           <span>Ubicación</span>
                        </div>
                    : null }
                    <div className={props.item == 10 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/carrito')}>
                       {/** <img src={carrito} alt="icon menu" width="20px" height="20px"></img>*/}
                        <span>Carrito</span>
                    </div>
                    {/* <div className="tab">
                        <img src={peso} alt="icon menu" width="15px" height="30px"></img>
                        <span>Sugerido</span>
                    </div> */}
                </div>
            </div>
            <SucursalModal></SucursalModal>
        </React.Fragment>
    )
}


export default withRouter(Tabbar);