import React, {useState, useEffect} from 'react'
import TableHeaderDetail from '../util/Table'

const Table = (props) =>{

    const [table, setTable] = useState()

    const handleFields = () => {
        Object.keys(props.table.header).map((item)=>{
            setTable({
                titleHeader:props.table.titleHeader,  
                titleDetail:props.table.titleDetail,  
                sub:props.table.sub,
                nameDetail:props.table.nameDetail,
                header:props.table.header,
                fieldsHeaders:Object.keys(props.table.header[item]),
                fieldsDetails: props.table.fieldsDetails,
                total: props.total
            })
        })
        
    }

    useEffect(()=>{
        handleFields()
    },[])

    return(        
        <React.Fragment>
            { table ?
                <TableHeaderDetail 
                    table={table}
                >
            </TableHeaderDetail> :  null
            }
        </React.Fragment>
    )

}

export default Table;