
export const URL_BASE = process.env.REACT_APP_URL_API;

export const WSEMPRESA = URL_BASE + "api/empresa/sucursal";
export const WSCATEGORIA = URL_BASE + "api/categoria";
export const WSPRODUCTO = URL_BASE + "api/producto";
export const WSSUGERIDO = URL_BASE + "api/sugerido";
export const WSPEDIDO = URL_BASE + "api/pedido";
export const WSCARRITOABANDONADO = URL_BASE + "api/CarritoAbandonado/carritoAbandonado";
export const WSHISTORIAL = URL_BASE + "api/pedido/historial";
export const WSBANNERS = URL_BASE + "api/Empresa/Banners";
export const WSLISTASDESCUENTO = URL_BASE + "api/Cliente/ListasDescuentos";
export const WSGRUPOSDESCUENTO = URL_BASE + "api/Cliente/GruposDescuentos";
export const WSLOGINCLIENTE = URL_BASE + "api/Cliente/Login";
export const WSVALIDARCLIENTE = URL_BASE + "api/Cliente/Validar";
export const WSREGISTROUSUARIO = URL_BASE + "api/Cliente/Registro";
export const WSENVIARCODIGO = URL_BASE + "api/Cliente/EnviarCodigo";
export const WSACTIVARCLIENTE = URL_BASE + "api/Cliente/Activar";
export const WSRECUPERACIONCLAVE = URL_BASE + "api/Cliente/RecuperacionClave";
export const WSCARTERACLIENTE = URL_BASE + "api/Cartera";
export const WSENCUESTA = URL_BASE + "api/Encuesta";
export const WSCUPOCLIENTE = URL_BASE + "api/cliente/cupo"
export const WSENVIARRECAUDO = URL_BASE + 'api/Recaudo/EnviarRecaudo?codCliente=';
export const WSBANCOS = URL_BASE + 'api/Recaudo/Bancos';
export const WSSUCURSALES = URL_BASE + 'api/Cliente/consultarSucursales?codCliente=';
export const WSSUCURSALESNIT = URL_BASE + 'api/Empresa/Sucursal?nit=';
export const WSCARTERAVENCIDA = URL_BASE + 'api/Cliente/carteraVencida?codCliente=';
export const WSINFOCLIENT = URL_BASE + 'api/cliente/ClienteByCodigo?codigoCliente=';
export const WSDETALLEPAGO = URL_BASE + 'api/Recaudo/detallePagoPasarela?codCliente=';
export const WSHISTORIALPAGOS = URL_BASE + 'api/Recaudo/GetHistorialPagos?codCliente=';
export const WSBANCODESTINO = URL_BASE + 'api/Recaudo/BancosDestino';
export const WSDOCUMENTOSCARTERA = URL_BASE + 'api/Cartera/tipoDocumentos';
export const WSUPLOADFILE = URL_BASE + 'api/Recaudo/cargarImagen?codigoCliente=';
export const WSDATOSVENDEDOR = URL_BASE + 'api/cliente/Vendedor?codigoCliente=';


// url recuperar carrito abandonado
export const WSRECUPERARCARRITOABANDONADO = URL_BASE + 'api/CarritoAbandonado/CarritoAbandonado?NumeroDoc=';
export const DESACTIVARCARRITOABANDONADO = URL_BASE + 'api/CarritoAbandonado/ActualizarEstadoCarritoAbandonado?numeroDoc='
export const WS_RECUPERAR_CARRITO_ABANDONADO_CLIENTE = URL_BASE + 'api/CarritoAbandonado/CarritoAbandonadoCliente';
export const WS_ELIMINAR_CARRITO_ABANDONADO = URL_BASE + 'api/CarritoAbandonado/EliminarCarritoAbandonado';
// controladores para descuentos
export const WSGETRANGOSDESCUENTOS = URL_BASE + 'api/Descuentos';
export const WSGETDESCUENTOSCODCLIENTE = URL_BASE + 'api/Descuentos?codCliente=';

//let empresa = JSON.parse(localStorage.getItem('empresa'));

/*let cantDecimales = 2

 if(empresa){
    if(empresa.cant_decimales){
        cantDecimales = ! isNaN(empresa.cant_decimales) ? empresa.cant_decimales : 2
    }
} */

export const stringToLowerCase = (cadena) => {
    let array = cadena.split(" ")
    let cadenaConvert = ""
    array.map((element) => {
        cadenaConvert += element.charAt(0).toUpperCase() + (element.slice(1)).toLowerCase() + " "
    })
    return cadenaConvert
}

export const getDate = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    if (second.length < 2) {
        second = '0' + second;
    }

    let fecha = [year, month, day].join('-') + " " + [minute, second].join(':');

    return fecha;
}

export const formatNumber = (nStr) => {

    let decimals = 2
    let milesSeparator = ","
    let decimalSeparator = "."

    nStr = nStr ? parseFloat(nStr) : 0

    var x = nStr.toString().split('.');
    var x1 = x[0];
    var x2 = nStr.toFixed(decimals).split(".")[1]
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + milesSeparator + '$2');
    }
    return "$" + x1 + (x2 ? decimalSeparator + x2 : "");
}
