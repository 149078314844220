import React from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';
import Progress from '../util/Progress';
import * as Constants from '../../constants/global';
import { useForm } from "react-hook-form";
import axios from 'axios';

const CodigoActivacionModal = (props) => {

    const { register, handleSubmit, errors, ...rest } = useForm();
    const [codigoActivacion, setCodigoActivacion] = React.useState(''); 
    const [ischecked, setIsChecked] = React.useState();
    const [ischeckedHv, setIsCheckedHv] = React.useState(true);
    const [loader, setLoader] = React.useState(false);

    const handleClose = () => {
        document.querySelector(".modal-codigoactivacion").classList.remove("show-modal");
        window.location.reload()
    }

    const handleConfirmarCodigo = (values) => {
        setLoader(true)
        let data = {
            "Codigo": props.validacion.Codigo,
            "CodigoVerficacion": codigoActivacion,
        };

        let url = Constants.WSACTIVARCLIENTE;
        axios.post(url, data)
        .then(res => {
            if(res.data.Activado === 1){
                alert(res.data.Mensaje);
                //document.querySelector(".modal-codigoactivacion").classList.remove("show-modal");
                //handleLogin();
                window.location.reload()                          
            } else {
                setLoader(false)
                alert(res.data.Mensaje);
            }
        });
        
    }

    /* const handleLogin = () => {
        Auth.authenticate(() => {
            let dataEmpresa = {
                nit: props.nit,
                empresas: props.empresa
            }
            Auth.setCodCliente(props.nit)
            //localStorage.setItem('empresas', JSON.stringify(dataEmpresa));
            Auth.setEmpresa(JSON.stringify(dataEmpresa))
            props.history.push('/login');
        })
    } 

    const handleCheck = (event) => {
        let isActive = event.target.checked;
        setIsChecked(isActive);
    }

    const handleCheckHv = (event) => {
        let isActive = event.target.checked;
        setIsCheckedHv(isActive);
    }*/

    return (
        
        <div className="modal alert modal-codigoactivacion">
            <div className={"modal-content info"}>       
                <div className="content">
                    { loader ? <Progress color={"#007daf"} /> : (
                        <form onSubmit={handleSubmit(e=>handleConfirmarCodigo(e))}  style={{width:"100%"}}>
                            <div className="alert-icon">                        
                            </div>
                            <div style={{className:"miFuente"}} className="alert-content">
                                <p className="alert-title">Activar cuenta</p>
                                <p className="alert-message"><b>Por favor ingresa el código de activación que te hemos enviado.</b></p>
                                <div className="codigo">
                                    <p>Código: </p>
                                    <input type="number"
                                        id="codigoActivacion"
                                        name="codigoActivacion"
                                        placeholder="Código de activación"
                                        onChange={e => setCodigoActivacion(e.target.value)}
                                        style={{borderColor:errors.codigoActivacion?"red":null}} tabIndex="1"
                                        ref={register({required:true})}>
                                    </input>
                                    {errors.codigoActivacion && <span className="error-form">*Campo Obligatorio</span>}
                                    
                                    {/*<div style={{display:"flex"}}>
                                        <div>
                                            <input type="checkbox" onChange={(event) => handleCheckHv(event)} style={{marginRight:"20px"}} />
                                        </div>
                                        <div style={{margin:"auto auto auto 10px"}}>
                                            <a href="http://ventas.epa.com.pa/Documentos/TERMINOSYCONDICIONESUSUARIOB2B.pdf" style={{margin:"auto"}} target="_blank" >Acepto Terminos y Condiciones EPA Tienda Online</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="form-check" >
                                    <input 
                                        className="input-checkbox"
                                        type="checkbox" 
                                        id="terminos_condiciones" 
                                        name="terminos_condiciones" 
                                        value="true"     
                                        ref={register({required:true})}                                             
                                        style={{border:errors.terminos_condiciones?"red":null}} tabIndex="2" >                                              
                                    </input>
                                    <label className="miFuente" htmlFor="terminos_condiciones">
                                        <span></span>
                                        <a href="http://ventas.epa.com.pa/Documentos/TERMINOSYCONDICIONESB2B.pdf" target="_blank" >Acepto Términos y Condiciones y Políticas de Privacidad EPA Tienda Online</a>
                                    </label>
                                </div>    
                            {errors.terminos_condiciones && <span className="error-form">*Campo Obligatorio</span>}
                                <div className="alert-buttons">
                                    <button type="submit" className="btn-large" >Activar cuenta</button><br/>
                                    <button type="button" className="btn-large" onClick={() => handleClose()}>Cancelar</button>
                                </div> 
                            </div>                                                    
                        </form>
                        
                    )}
                </div>
            </div>
        </div>
    )
}

export default withRouter(CodigoActivacionModal);