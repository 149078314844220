import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import Payment from '../payment/payment';
import TarjetaCredito from '../payment/tarjetaCredito';
import { Table, The , Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import back from '../../assets/icon/back.svg';
import transfer_bank from '../../assets/icon/transfer_bank.svg';
import credit_card from '../../assets/icon/credit_card.svg';
import transfer from '../../assets/icon/transfer.svg';
import Auth from '../../helpers/Auth';
import Resumen from '../pages/Resumen';

//tagmanager
// import TagManager from 'react-gtm-module'

var uniqid = require('uniqid');
const publicIp = require('public-ip');
var moment = require('moment')

const FooterTable = (props) => {

    const [payment, setPayment] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState({})
    const [error, setError] = useState()
    const [table, setTable] = useState();
    const [usuario, setUsuario] = useState()
    const [total, setTotal] = useState()
    const [header, setHeader] = useState()
    const [fieldsDetails, setFieldsDetails] = useState()
    const [invoices, setInvoices] = useState();
    const [ip, setIp] = useState();

    const handlePaymentMethod = (method, type) => {
        setError()
        setPaymentMethod({method:method,type:type})
    }

    const handleData = () =>{
        let f = [], nt = [], tF = 0, tNC = 0, fildsDetls=[], listData = []

        props.data.forEach((element)=>{
            if(element.saldo>0){
                f.push(element)
                tF+=Number(element.saldo)
                if(element.notaCredito.length>0){
                    element.notaCredito.forEach((item)=>{
                        tNC+=Number(item.saldo)
                    })
                }
            }else{
                nt.push(element)
                tNC+=Number(Number(element.saldo))
            }
        })
        f.forEach((element)=>{
            listData.push({
                documentoFiscal:element.documentoFiscal,
                referencia:element.referencia,
                Razonsocial:element.Razonsocial,
                fecha:element.fecha,
                saldo:element.saldo,
                notaCredito:element.notaCredito
                


            })
        })
        nt.forEach((element)=>{
            listData.push({
                documentoFiscal:element.documentoFiscal,
                referencia:element.referencia,
                Razonsocial:element.Razonsocial,
                fecha:element.fecha,
                saldo:element.saldo,
                notaCredito:element.notaCredito
               
            })
        })
        try {
            fildsDetls = Object.keys((listData.filter(x=>x.notaCredito.length>0)[0]).notaCredito[0])
        } catch (error) {}
        setHeader(listData)
        setFieldsDetails(fildsDetls)
        setTotal(((Math.round((tF+tNC)*100))/100).toFixed(2))
    }

    const handlePagar = () => {
        Auth.setCartera(JSON.stringify(props.data))

        let data = (JSON.parse(Auth.getCartera()))
        let invoices = []
            var datos = []
            for(let x = 0; x < data.length; x++){
                const index = data.find((e)=> e.documentoSAP === data[x].documentoSAP )
                console.log(index);
                if(index != undefined){
                    datos.push({
                        ejercicio : index.ejercicio,
                        documentoFiscal: index.documentoFiscal,
                        financiero : index.financiero
                    })
                }
            }
            localStorage.setItem("datos",JSON.stringify(datos))
        data.forEach(factura => {
            let nc = []
            if(factura.notaCredito.length>0){
                factura.notaCredito.forEach(ntc => {
                  nc.push({
                    'tax_document':ntc.NumFiscal,
                    'document':ntc.documento,
                    'balance':ntc.saldo
                  })
                });
            }
            invoices.push({
                'codeClient':factura.codCliente,
                'taxDocument':factura.documentoFiscal,
                'sapDocument':factura.documentoSAP,
                'blackberry':factura.mora,
                'balance':factura.saldo,
                'reference':factura.referencia,
                'businessName':factura.Razonsocial,
                'creditNote':nc
            })
        });
        setInvoices(invoices)
        if(paymentMethod.method){    
            if(total && total>0){    
                setTable({ 
                    titleHeader:"Detalle pago",  
                    titleDetail:"Nota Credito",  
                    sub:"notaCredito",
                    nameDetail:["Documento", "Saldo" , "Numero Fiscal"],
                    fieldsDetails:fieldsDetails ? fieldsDetails : null,
                    header:header ? header : {},
                })   
                setPayment(true)
                props.handleTable(false)
                props.handleView(true)
                // TagManager.dataLayer({
                //     dataLayer:{
                //         event:'checkout',
                //         ecommerce:{
                //             actionField:{
                //                 step:1
                //             },
                //             invoices:invoices
                //         }
                //     }
                // })
            }else{
                setError("El valor a pagar no puede ser negativo")
            }
        }else{
            setError("Por favor selecciona un metodo de pago para continuar")
        }
    }

    const handleBack = () => {
        props.handleView(false)
        props.handleTable(true)
        setPayment(false)
    }

    const handleInfoClient = () =>{
        axios.get(Constants.WSINFOCLIENT + Auth.getCodCliente()).then((response)=>{
            setUsuario(response.data[0])
        })
    } 

    const handleIp = async() => {
        setIp(await publicIp.v4())
    }

    useEffect(() => {
        handleInfoClient()
        handleData()
        handleIp()
    },[props.data])


        return (
            <React.Fragment>
                { !payment ?           
                    <div className="pago">                             

                        <div className="formas-pago">
                            <p className="title">Métodos de Pago</p>
                            
                            <p className="title">{ !error && ( "TOTAL: " + (total ? Constants.formatNumber(total) : 0))}</p>
                            <div className="card-pagos">
                                <div  className={ (paymentMethod.method==1 ? "active" : null) + " card-img"}>
                                    <a onClick={ e=> handlePaymentMethod(1,"Depósito Bancario")}>
                                        <p>Depósito Bancario</p>
                                        <img  src={transfer_bank}></img>
                                    </a>                    
                                </div>  
                                <div  className={ (paymentMethod.method==2 ? "active" : null) + " card-img"}>
                                    <a onClick={ e=> handlePaymentMethod(2, "Tarjeta Crédito")}>
                                        <p>Tarjeta de crédito</p>
                                        <img src={credit_card}></img>                                       
                                    </a>                   
                                </div>  
                                <div className={ (paymentMethod.method==3 ? "active" : null) + " card-img"}>
                                    <a onClick={ e=> handlePaymentMethod(3, "Transferencia Bancaria")}>
                                        <p>Transferencia Bancaria (ACH)</p>
                                        <img src={transfer}></img>                                      
                                    </a>                   
                                </div>                                                        
                            </div>                
                        </div>    
                        {error ? (
                                <div className="error">
                                    <p>{error}</p>
                                </div>
                            ): null }                     
                        <div className="submit">                         
                            <br/><button className="button-submit"  onClick={e=> handlePagar()}>{ "CONTINUAR" }</button>     
                        </div>  
                    </div>
                : null
            }   

            {payment ? (
                <React.Fragment>
                    <div className="footer">
                        {/* <div className="back">
                            <a onClick={e=>handleBack()}>
                                <img src={back}></img>
                            </a>                            
                        </div> */}
                        <div className="body">
                            <div>
                                <Resumen
                                    table={table}
                                ></Resumen>
                            </div>
                            {
                                (paymentMethod.method===3 || paymentMethod.method===1) ? (
                                    <Payment 
                                    invoices={invoices}
                                    data={props.data} 
                                    type={paymentMethod.method}
                                    total={total ? total : 0}
                                    handleBack={handleBack.bind()}></Payment>
                                ) : ( paymentMethod.method === 2 ? (
                                        <TarjetaCredito 
                                            invoices={invoices}
                                            referencia={"B2B" + Auth.getCodCliente() + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate()  + new Date().getHours() + new Date().getSeconds() + new Date().getMilliseconds()}
                                            uuid={ uniqid() }
                                            ip = {ip}
                                            fecha={(new Date().toISOString()).split('.')[0]+"Z"}
                                            usuario = {usuario ? usuario : null}
                                            total={total ? total : 0}
                                            handleBack={handleBack.bind()}
                                        ></TarjetaCredito>
                                    ) :  null                                    
                                )
                            }
                        </div>                        
                    </div>                
                    
                </React.Fragment>                
                ) : (
                    null
                ) 
            }        
        </React.Fragment>
        )        
    }

export default FooterTable;