import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

import SolicitudActivacionModal from '../modal/SolicitudActivacionModal';
import { useForm } from "react-hook-form";
import axios from 'axios';


const RegistroModal = (props) => {

    const { register, handleSubmit, errors, ...rest } = useForm();
    const [correoCompras, setCorreoCompras] = React.useState(''); 
    const [correoPagos, setCorreoPagos] = React.useState(''); 
    //const [celular, setCelular] = React.useState("");
    const [error, setError] = React.useState("");
    const [correos, setCorreos] = useState({Correos: []});

    const handleClose = () => {
        document.querySelector(".modal-registro").classList.remove("show-modal");
        window.location.reload()
    }

    const handleRegistro = (data) => {
        setError()
        let url =  Constants.WSREGISTROUSUARIO;
        axios.post(url, data)
        .then(res => {
            console.log(res);
            if(res.data.Registro === "1") {
                document.querySelector(".modal-activacion").classList.toggle("show-modal");
            } else {
                //alert(res.data.Mensaje)
                setError(res.data.Mensaje)
            }
        }).catch((error)=>{
            setError("Error inesperado")
        })
    }

    const handleCorreo = (values) => {
        console.log(values)
        let data = {
            "Nit": props.nit,
            "CorreoCompras": values.emailCompras,
            "CorreoPagos": values.emailPagos
            // "CorreoDian": correoDian,
            //"Telefono": celular,
        };

        let arrayCorreos = {
            correoCompras:values.emailCompras,
            correoPagos:values.emailPagos
        }
        setCorreos(arrayCorreos)
        handleRegistro(data)

    }

    useEffect(()=>{
    })

    return (
        <div className="modal alert modal-registro">
            <div className={"modal-content info"}>       
                <div className="content">
                    <form onSubmit={handleSubmit(e=>handleCorreo(e))} style={{width:"100%"}}>
                        <div style={{className:"miFuente"}} className="alert-content">
                            <p className="alert-title">Registro de usuario</p>
                            <p className="alert-message"><b>Completa el siguiente formulario para registrarte</b></p>
                            <div className="codigo">                                
                                <p style={{fontSize:"1rem"}}>Código SAP: </p>
                                <input 
                                    type="number"
                                    placeholder="Código SAP"
                                    id="codigoSAP"
                                    name="codigoSAP"
                                    disabled
                                    value={props.nit}
                                    ref={register({required:true})}
                                    style={{cursor:"not-allowed"}}>
                                </input>
                                <br/><br/>
                                <p className="miFuente" style={{fontSize:"1rem"}}>Correo compras: </p>
                                <input 
                                    type="email"
                                    placeholder="correo de compras"
                                    id="emailCompras"
                                    name="emailCompras"
                                    onChange={e => setCorreoCompras(e.target.value)}
                                    style={{border:errors.emailCompras && "1px solid red"}}
                                    ref={register({required:true,pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Dirección de correo electronico invalida"}})}
                                    value={correoCompras}>
                                </input>
                                {errors && (errors.emailCompras && <span className="error-form">{errors.emailCompras.message}</span>)}
                                <br/><br/>
                                <p className="miFuente" style={{fontSize:"1rem"}}>Correo pagos: </p>
                                <input 
                                    type="email"
                                    placeholder="correo pagos"
                                    id="emailPagos"
                                    name="emailPagos"
                                    style={{border:errors.emailPagos && "1px solid red"}}
                                    onChange={e => setCorreoPagos(e.target.value)}
                                    ref={register({required:true,pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Dirección de correo electronico invalida"}})}
                                    value={correoPagos}>
                                </input>
                                {errors && (errors.emailPagos && <span className="error-form">{errors.emailPagos.message}</span>)}
                                {/* <p>Numero celular: </p>
                                <input 
                                    type="number"
                                    placeholder={"******"+props.celularAyuda}
                                    id="celular"
                                    onChange={e => setCelular(e.target.value)}
                                    value={celular}>
                                </input> */}
                                {/*  <small>{props.celularAyuda}</small> */}
                                { error ? (
                                    <span className="error">
                                        <p>{error}</p>
                                    </span>
                                ) : null }
                                <div className="alert-buttons">
                                    <button type="submit" className="btn-large" >Registrarse</button><br/>
                                    <button type="button" className="btn-large" onClick={() => handleClose()}>Cancelar</button>
                                </div>                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
            {/* { props.validacion.Telefonos ?  */}
        <SolicitudActivacionModal nit={props.nit} empresa={props.empresa} validacion={props.validacion} correos={correos ? correos : null} /> 
            {/* :(null)} */}
        </div>     
    )
}

export default withRouter(RegistroModal);