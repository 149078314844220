import React, { useState, useEffect } from 'react';
import ReactGa from 'react-ga'
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import {ReactComponent as Left} from '../../assets/icon/left.svg';
import {ReactComponent as Rigth} from '../../assets/icon/rigth.svg';


const Categoria = (props) => {
    
    const [categorias, setCategorias] = useState();
    const [active, setActive] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [scrollEnd, setScrollEnd] = useState(true);
    const [limpiarSearch, setlimpiarSearch] = useState("john");

    useEffect(() => {
        let empresa = JSON.parse(localStorage.getItem('empresa'));
        empresa = empresa.empresas[0]
        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        const categoriaData = () => {
            let url =   Constants.WSCATEGORIA +
                        "?origen=" + ( empresa ?  empresa.codigo_empresa : '' ) + 
                        "&cliente=" + codigo;
            axios.get(url)
            .then(res => {
                let listData = res.data || [];
                if(listData.length == 0){
                    listData.splice(0, 0, {codigo: -1, descripcion: "TODAS"});
                }
                props.handleCategoria(listData[0]);
                setCategorias(listData);
            });
        };
        categoriaData();
    }, [])

    const clickCategoria = (item, i) => e => {
        console.log(item);
        setActive(i);
        props.handleCategoria(item);
        ReactGa.event({
            category:"categoria seleccionada",
            action:item.descripcion
        })
    }

    const scrollAction = (item) => {
        document.getElementById('scroll-categoria').scrollLeft += item;
    }

    setTimeout(function(){

        if(document.getElementById('scroll-categoria')){
            if(document.getElementById('scroll-categoria').scrollLeft 
            + document.getElementById('scroll-categoria').offsetWidth + 10
            >= document.getElementById('scroll-categoria').scrollWidth){
                setScrollEnd(false);
            }else{
                setScrollEnd(true);
            }
            
            document.getElementById("scroll-categoria").onscroll = function() {
                
                setScroll(document.getElementById('scroll-categoria').scrollLeft);
                
                if(document.getElementById('scroll-categoria').scrollLeft 
                + document.getElementById('scroll-categoria').offsetWidth + 10
                >= document.getElementById('scroll-categoria').scrollWidth){
                    setScrollEnd(false);
                }else{
                    setScrollEnd(true);
                }
            }
        }
        
    }, 300);

    return (
        <React.Fragment>
            { categorias ? (
                    <div className="categoria">
                        <div style={{ backgroundColor:'white', width:'100%', display:'flex' }}>
                            { scroll > 0 ? (
                                <div className="arrow" 
                                    onClick={() => scrollAction(-60)}>
                                    <Left className="svg-una-capa" style={{width:"20px", height:"20px"}}/>
                                </div>
                            ) : null }
                            <ul id="scroll-categoria">
                                {categorias.map((item, i) => (
                                    <li key={i}>
                                        <a 
                                            className={active == i ? 'active': null} 
                                            onClick={clickCategoria(item, i)}>
                                            {item.descripcion}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            { scrollEnd ? (
                                <div className="arrow" onClick={() => scrollAction(60)}>
                                    <Rigth className="svg-una-capa" style={{width:"20px", height:"20px"}}/>
                                </div>
                            ) : null }
                        </div>
                    </div>
            ) : null }
        </React.Fragment> 
    )
}


export default Categoria;