import Auth from '../../helpers/Auth';
import React, { useState, useEffect } from 'react';
/* import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import axios from 'axios'; 
import credit_card from '../../assets/icon/credit_card.svg';
import * as Constants from '../../constants/global';*/
import { useForm } from "react-hook-form";
import Iframe from 'react-iframe';


// import TagManager from 'react-gtm-module'

var CryptoJS = require("crypto-js");
var uniqid = require('uniqid');
var moment = require('moment')

const TarjetaCredito = (props) => {

    const { register, handleSubmit, errors } = useForm();

    const signed = (data) => {
        /* let signed = window.btoa(unescape(encodeURIComponent(sha256.hmac('256', fieldName.toString(), process.env.REACT_APP_SECRET_KEY, true))))
        console.log(signed) */
        let field_names=[]
        console.log(field_names);
        var formularios = document.forms;
        let signed_field_names = (document.getElementById('signed_field_names').value).split(",")
        for(let item_signed in signed_field_names){
            field_names.push(signed_field_names[item_signed]+"="+document.getElementById(signed_field_names[item_signed]).value)
        }
        // for (var i=0; i<formularios.length; i++){
        //     for (var j=0; j<formularios[i].elements.length; j++){
        //         if(formularios[i].elements[j].type!="button" && formularios[i].elements[j].name!="signature"  /* && formularios[i].elements[j].name!="signed_field_names"  && formularios[i].elements[j].name!="unsigned_field_names" */){
        //             field_names.push(formularios[i].elements[j].name+"="+formularios[i].elements[j].value)
        //         }
        //     }
        // }
        field_names=field_names.toString()
        let signed = CryptoJS.HmacSHA256(field_names,process.env.REACT_APP_SECRET_KEY)
        signed = CryptoJS.enc.Base64.stringify(signed)
        document.getElementById('signature').value=signed
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'checkout',
        //         ecommerce:{ 
        //             actionField:{
        //                 step:2
        //             },
        //             invoices:props.invoices
        //         }
        //     }
        // })
        formularios[0].submit()
        // console.log(formularios[0]);
    }

    const handleInput = () => {
        let date = document.getElementById('date').value
        date = date.split("-")
        document.getElementById("card_expiry_date").value=date[1]+"-"+date[0]
    }

    /* const handleInfoClient = () =>{
        axios.get(Constants.WSINFOCLIENT + Auth.getCodCliente()).then((response)=>{
            setClient(response.data[0])
        })
    } */

    


    useEffect(() => {
        //handleInfoClient()
    },[props.facturas])

    return (
      <React.Fragment>  
        <head>
            <script>
            <Iframe src={"https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id="+process.env.REACT_APP_MERCHANT_ID+props.referencia}>
            </Iframe>
            </script>
        </head> 
        <body>
            <script>
            <Iframe src={"https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id="+process.env.REACT_APP_MERCHANT_ID+props.referencia}>
            </Iframe>
            </script>
            {/* <noscript>
                <iframe style="width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;" 
                src={"https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id="+process.env.REACT_APP_MERCHANT_ID+props.referencia}></iframe>
            </noscript> */}
            
            <div id="wrapper">
                <h1>Formulario de Pago</h1><br/>
                {/* <img src={credit_card}></img>  */}
                {/* <form id="confirmar" action={process.env.REACT_APP_URL_PAYMENT} method="post">            
                </form> */}
                <form id="payment" action={process.env.REACT_APP_URL_PAYMENT} method="post"  onSubmit={handleSubmit(e=>signed())} >
                    <input type="hidden" id="device_fingerprint_id" name="device_fingerprint_id" value={props.referencia} />
                    <input type="hidden" id="customer_ip_address" name="customer_ip_address" value={props.ip} />
                    <input type="hidden" id="access_key" name="access_key" value={process.env.REACT_APP_ACCESS_KEY} />
                    <input type="hidden" id="profile_id" name="profile_id" value={process.env.REACT_APP_PROFILE_ID} />
                    <input type="hidden" id="transaction_uuid" name="transaction_uuid" value={uniqid()}/>
                    <input type="hidden" id="signed_field_names" name="signed_field_names" value="device_fingerprint_id,customer_ip_address,access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,bill_to_forename,bill_to_surname,bill_to_phone,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,transaction_type,reference_number,amount,currency,payment_method,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4,merchant_defined_data5,merchant_defined_data6,merchant_defined_data7,merchant_defined_data8,merchant_defined_data10,merchant_defined_data11,merchant_defined_data12,merchant_defined_data18,merchant_defined_data19,merchant_defined_data20,merchant_defined_data21,merchant_defined_data24,merchant_defined_data26,merchant_defined_data27"/>
                    <input type="hidden" id="unsigned_field_names" name="unsigned_field_names" value="card_type,card_number,card_expiry_date,card_cvn" /> 
                    <input type="hidden" id="signed_date_time" name="signed_date_time" value={(new Date().toISOString()).split('.')[0]+"Z"} />
                    <input type="hidden" id="locale" name="locale" value="en" />
                    <div className="col-2">
                        <label>Nombre(S): 
                            <input placeholder="Nombre completo" ref={register({required:true})} id="bill_to_forename" name="bill_to_forename"  style={{borderColor:errors.bill_to_forename?"red":null}} tabindex="1" />
                            {errors.bill_to_forename && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <div className="col-2">
                        <label>Apellidos: 
                            <input placeholder="Apellidos" ref={register({required:true})} id="bill_to_surname" name="bill_to_surname" style={{borderColor:errors.bill_to_surname?"red":null}} tabindex="2" />
                            {errors.bill_to_surname && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <div className="col-3">
                        <label>Número celular:
                            <input placeholder="Número de teléfono/celular" ref={register({required:true})} id="bill_to_phone" name="bill_to_phone" style={{borderColor:errors.bill_to_phone?"red":null}} tabindex="3"/>
                            {errors.bill_to_phone && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <div className="col-3">
                        <label>Correo electrónico:
                            <input placeholder="Correo electrónico personal" ref={register({required:true})} id="bill_to_email" name="bill_to_email" style={{borderColor:errors.bill_to_email?"red":null}} tabindex="4"/>
                            {errors.bill_to_email && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <div className="col-3">
                        <label>Dirección: 
                            <input placeholder="Dirección de residencia" ref={register({required:true})} id="bill_to_address_line1" name="bill_to_address_line1" style={{borderColor:errors.bill_to_address_line1?"red":null}} tabindex="5" maxLength={24}/>
                            {errors.bill_to_address_line1 && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <div className="col-4"> 
                        <label>Ciudad: 
                            <input placeholder="Ciudad de residencia" ref={register({required:true})} id="bill_to_address_city" name="bill_to_address_city"  style={{borderColor:errors.bill_to_address_city?"red":null}} tabindex="6" />
                            {errors.bill_to_address_city && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div> 
                    <input type="hidden" id="bill_to_address_state" name="bill_to_address_state" value="PA" />
                    <input type="hidden" id="bill_to_address_country" name="bill_to_address_country" value="PA" />
                    <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code" value="0801"></input>
                    <div>
                        <label style={{display:"none"}}>transaction_type:
                            <input type="hidden" id="transaction_type" name="transaction_type" value="sale" />
                        </label>
                    </div>
                    <div className="col-4"> 
                        <label>Referencia de pago:
                            <input id="reference_number" name="reference_number" value={props.referencia} tabindex="7" readOnly/>
                        </label>
                    </div>
                    <div className="col-4">  
                        <label>Total a pagar:
                            <input id="amount" name="amount" value={props.total} tabindex="8"  readOnly/>
                        </label>
                    </div> 
                    <div className="col-4">
                        <label>Moneda:
                            <input id="currency" name="currency" value="USD" tabindex="9" readOnly/>
                        </label>
                    </div>
                    <input type="hidden" id="payment_method" name="payment_method" value="card"  />                           
                    <div className="col-5">
                        <label>Tipo de tarjeta: 
                            {/* version anterior  */}
                            {/* <select onClick={e=>document.getElementById('card_type').value=e.target.value} tabindex="10"> */}

                            <select id="card_type" name="card_type" tabindex="10">
                                <option value="001">VISA</option>
                                <option value="002">MASTERCARD</option>
                            </select>
                        </label>
                    </div>
                    {/* <input type="hidden" id="card_type" name="card_type" defaultValue="001" /> */}
                    <div className="col-5"> 
                        <label>Número de la tarjeta: 
                            <input type="number" id="card_number" name="card_number" ref={register({required:true})} style={{borderColor:errors.card_number?"red":null}} tabindex="11" />
                            {errors.card_number && <span className="error-form">*Campo Obligatorio</span>}<br/>
                        </label>
                    </div>
                    <div className="col-5">
                        <label>Fecha de expiración: 
                            <input type="month" min="2020-10" max="2030-12" id="date" name="date" onChange={e=>handleInput("card_expiry_date",e.target.value)} style={{borderColor:errors.card_expiry_date?"red":null}}  tabindex="12"  />
                            <input type="hidden" id="card_expiry_date" name="card_expiry_date"  ref={register({required:true})} />
                            {errors.card_expiry_date && <span className="error-form">*Campo Obligatorio</span>}<br/>
                        </label>
                    </div>
                    <div className="col-5"> 
                        <label>Código de seguridad: 
                            <input type="password" maxLength="3" id="card_cvn" name="card_cvn" ref={register({required:true})}  tabindex="13" />
                            {errors.card_cvn && <span className="error-form">*Campo Obligatorio</span>}
                        </label>
                    </div>
                    <input type="hidden" id="merchant_defined_data1" name="merchant_defined_data1" value={process.env.REACT_APP_MERCHANT_ID} />
                    <input type="hidden" id="merchant_defined_data2" name="merchant_defined_data2" value="WEB" />
                    <input type="hidden" id="merchant_defined_data3" name="merchant_defined_data3" value={uniqid()} />
                    <input type="hidden" id="merchant_defined_data4" name="merchant_defined_data4" value={uniqid()} />
                    <input type="hidden" id="merchant_defined_data5" name="merchant_defined_data5" value="EPA" />
                    <input type="hidden" id="merchant_defined_data6" name="merchant_defined_data6" value="Compra productos tienda e-commerce" />
                    <input type="hidden" id="merchant_defined_data7" name="merchant_defined_data7" value={Auth.getCodCliente()} />
                    <input type="hidden" id="merchant_defined_data8" name="merchant_defined_data8" value="YES" />
                    <input type="hidden" id="merchant_defined_data10" name="merchant_defined_data10" value="YES" />
                    <input type="hidden" id="merchant_defined_data11" name="merchant_defined_data11" value="3" />
                    <input type="hidden" id="merchant_defined_data12" name="merchant_defined_data12" value="5" />
                    <input type="hidden" id="merchant_defined_data18" name="merchant_defined_data18" value="R" />
                    <input type="hidden" id="merchant_defined_data19" name="merchant_defined_data19" value={(new Date().toISOString()).split('.')[0]+"Z"} />
                    <input type="hidden" id="merchant_defined_data20" name="merchant_defined_data20" value={props.usuario.fechaUltCompra} />
                    <input type="hidden" id="merchant_defined_data21" name="merchant_defined_data21" value={uniqid()} />
                    <input type="hidden" id="merchant_defined_data24" name="merchant_defined_data24" value="NO" />
                    <input type="hidden" id="merchant_defined_data26" name="merchant_defined_data26" value={props.usuario.codTelefonicoPais} />
                    <input type="hidden" id="merchant_defined_data27" name="merchant_defined_data27" value="0" />
                    <input type="hidden" id="signature" name="signature" value=""></input>
                    <button type="submit" className="btn-large">Confirmar</button>
                    <button type="button" className="outline" onClick={e=>props.handleBack()} >Cancelar</button>
                </form>
            </div>
            
        </body>
       
      </React.Fragment>
    )
    
  }

export default TarjetaCredito;
