import React, {useEffect } from 'react';
import noimage from '../../assets/img/generico.png';

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const  Slide = (props) => {

    useEffect(()=>{

    })

    return (
        <React.Fragment>
            <Carousel >
                <div>
                    <img 
                        src={process.env.REACT_APP_URL_IMAGE + props.item.codigo.toString().trim() + '_1.png'}
                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                    </img>
                </div>
                <div>
                    <img 
                        src={process.env.REACT_APP_URL_IMAGE + props.item.codigo.toString().trim() + '_2.png'}
                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                    </img>
                </div>               
            </Carousel>
        </React.Fragment>
    )
}

export default Slide;