import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
//import { AppContext } from '../../context/AppState';
import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import Progress from '../util/Progress';
import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import noimage from '../../assets/img/generico.png';
import { connect } from 'react-redux';
import ReactGa from 'react-ga'

const Lista = (props) => {

    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();
    const [message, setMessage] = useState();
    const [empresa, setEmpresa] = useState();
    const [alerta, setAlerta] = useState();

    const loadProductos = async () => {
        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        let url =   Constants.WSSUGERIDO +
                    "?origen=" + ( empresa ?  empresa.codigo_empresa : '' ) + 
                    "&cliente=" + codigo;

        axios.get(url)
        .then(res => {
            setLoader(false);
            setProductos(res.data);
            if(res.data.length==0){
                setMessage("Lo sentimos, en el momento no cuentas con un pedido sugerido.")
            }
        });
    }

    const handleMas = item => {  
        console.log(item);
        let carrito = props.carrito || {}
        if(carrito[item.codigo]){
            carrito[item.codigo]["cantidad"] += 1;
        }else{
            carrito[item.codigo] = {
                nombre: item.nombre,
                precio: parseFloat(item.precio),
                iva: parseInt(item.iva),
                cantidad: 1
            }
        }
        ReactGa.event({
            category:"Event Product",
            action:"Add To Cart ",
            label:`${item.codigo} ${item.nombre}`
        })
        Auth.setCarrito(carrito)
        props.ActualizarCarrito(Auth.getCarrito())
    }

    const handleMenos = item => {
        let carrito = props.carrito || {}
        if(carrito[item.codigo]){
            if(carrito[item.codigo]["cantidad"] == 1){
                delete carrito[item.codigo];
            }else{
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(carrito)
        props.ActualizarCarrito(Auth.getCarrito())
    }

    const hadleTodos = (vlue) => {
        let carrito = props.carrito || {}
        
        if(vlue){
            productos.map((item, i) => {
                if(carrito[item.codigo]){
                    carrito[item.codigo]["cantidad"] += parseInt(item.cantidad);
                }else{
                    carrito[item.codigo] = {
                        nombre: item.nombre,
                        precio: item.precio,
                        cantidad: parseInt(item.cantidad),
                        iva: item.iva
                    }
                }
            })
        } else {
            productos.map((item, i) => {
                if(carrito[item.codigo]){
                    delete carrito[item.codigo];
                }
            })
        } 
        Auth.setCarrito(carrito)
        props.ActualizarCarrito(Auth.getCarrito())
        loadProductos();
        setTimeout(()=>{
            setAlerta({
                tipo: "success",
                titulo: "Pedido sugerido",
                mensaje: "El pedido sugerido ha sido agregado exitosamente",
                btnOk: "Ir al carrito",
                btnCancel: "Ir al catalogo",
                fnOk: function () {
                    props.history.push("/carrito");
                },
                fnCancel: function(){
                    props.history.push("/catalolo")
                }
            })
        },400)
        
    }

    const handleChangeCantidad = (item, valor) => {
        let carrito = props.carrito || {}
        if(valor <= 0){
            delete carrito[item.codigo];
        }else{
            if(carrito[item.codigo]){
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[item.codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        Auth.setCarrito(carrito)
        props.ActualizarCarrito(Auth.getCarrito())
    }
 
    useEffect(() => {
        setLoader(true);
        loadProductos();
    }, [])

    return (        
        <div className="pedido-list sugerido">
            { loader ? <Progress color={"#007daf"} /> : (
                <React.Fragment>
                    {productos && productos.length>0 ? (
                        <div className="product-list">
                            <div className="checkbox">
                                <label className="switch">
                                <input type="checkbox" 
                                        onClick={ e => hadleTodos(e.target.checked)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <label>Seleccionar todos</label>
                            </div>
                           
                            {productos.map((item, i) => (
                                <div className="item" key={i}>
                                    <img width="120"
                                        className="img-product"
                                        src={process.env.REACT_APP_URL_IMAGE + item.codigo.toString().trim() + '_1.png'}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}/>
                         
                                    <div className="start">
                                        <p className="nombre"><b>{item.nombre}</b></p>
                                        <p className="codigo"><b>Código:</b> {item.codigo}
                                         </p>
                                        <p className="codigo"><b>Cantidad:</b> {item.cantidad}</p>
                                        <p className="precio">
                                            {Constants.formatNumber(item.precio)}
                                        </p>
                                    </div>
                                    <div className="end">
                                        <div className="action">
                                            { props.carrito[item.codigo] ? (
                                                <div className="controls">
                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                    </button>
                                                    <input type="number" 
                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                        value={props.carrito[item.codigo].cantidad}/>
                                                    <button role="button" onClick={() => handleMas(item)}>
                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : 
                    <div className="message">
                        <p >{message ? message : ""}</p>
                    </div>
                    }
                    { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
                </React.Fragment>
            )}
        </div>
    )
}

const mapStateToProps = (state) =>{
    return{
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito})(Lista));