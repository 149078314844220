import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import store from './store';

//tag manager 
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId : process.env.REACT_APP_KEY_TAGMANAGER
// }

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);


serviceWorker.unregister();