import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';

import * as Constants from '../../constants/global';

const Pedido = () => {
    
    const [search, setSearch] = useState();
    const [categoria, setCategoria] = useState();
    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const handleCategoria = item => {
        setCategoria(item);
    }

    useEffect(() => {
    },[]) 

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">
                    <Navbar item={1} />
                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar producto..."
                            name="search"
                            id="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}>
                        </input>
                    </div>
                </div>
                <Categoria handleCategoria={handleCategoria}/>
            </div>
            
            <Producto tipo={2} categoria={categoria} search={search} />

            <Tabbar item={1}/>
        </React.Fragment>
    )
}

export default Pedido;