import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';

import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {connect} from "react-redux";

const SucursalModal = (props) => {
    const [sucursales, setSucursales] = useState();

    const handleClose = () => {
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const handleSucursal = (item) => {
        Auth.setSucursal(JSON.stringify(item))
        Auth.setCodProductos(item.Codigo);                          

        let url = Constants.WS_RECUPERAR_CARRITO_ABANDONADO_CLIENTE + "?codCliente=" + item.Codigo;

        axios.get(url).then(res => {
            if (res.data.IDCarrito != null) {
                localStorage.setItem("codigoCa",res.data.IDCarrito)
                recuperarCarrito();
                handleClose()
            }
            else{
                handleClose()
                Auth.removeCarrito(localStorage.getItem("codigoCa"));
                window.location.reload();
            }
        });
    }
    const recuperarCarrito = () => {
        let codigo =  localStorage.getItem("codigoCa")
        let url = Constants.WSRECUPERARCARRITOABANDONADO + codigo;
        
        axios.get(url)
        .then(res => {
            let data = res.data
            
            data.map((info =>{
                Auth.setCarrito(info);
                props.ActualizarCarrito(Auth.getCarrito());
            }))
        });
        
        props.history.push('/carrito');  
    }

    const handleSucursales = () =>{
        axios.post(Constants.WSSUCURSALES + Auth.getCodCliente()).then((response)=>{
            setSucursales(response.data)
        })
    }

    let getCodCanalSucursales = () =>{
        axios.get(Constants.WSSUCURSALESNIT + Auth.getCodCliente()).then((response)=>{
            let codigo = Auth.getCodCliente()
            let valor = response.data[0].sucursales
            
            valor.forEach(element => {
                if (element.codigo === codigo) {
                    const codCanal = element.codCanal
                    localStorage.setItem('codCanal',codCanal)
                    }
                }
            );
        })
    }

    useEffect(() => { 
        handleSucursales()
      }, [])

      useEffect(() => { 
        getCodCanalSucursales()
      }, [])


    return (
        <div className="modal modal-sucursal">
            <div className="modal-content">       
                <div className="title">
                    <p className="miFuente">Elige la sucursal para recibir tu pedido !</p>
                   <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>
                </div>
                <div className="content full-content">
                    <div className="list-items">
                        { sucursales ? 
                            sucursales.map((item, i) => (
                                <div className="item" key={i} onClick={() => handleSucursal(item)}>
                                    <p className="miFuente"><b>{item.Nombre}</b></p>
                                    <p className="miFuente"><b>{item.Codigo}</b></p>
                                    {/* <p>{item.Razonsocial}</p>
                                    <p>Tel. {item.Telefono}</p> */}
                                </div>
                            )
                        ) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito}) (SucursalModal));