import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import * as Constants from '../../constants/global';
import noimage from '../../assets/img/generico.png';
import { ReactComponent as Minus } from '../../assets/icon/minus.svg';
import { ReactComponent as Plus } from '../../assets/icon/plus.svg';
import Trash from '../../assets/img/trash.png';
import error from '../../assets/icon/error.svg';
import AlertModal from '../modal/AlertModal';
import Auth from '../../helpers/Auth';
import ActualizarUsuario from '../../store/usuario/action';
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ReactGa from 'react-ga'
import * as XLSX from 'xlsx'
//Tag manager
// import TagManager from 'react-gtm-module'
import { green } from '@material-ui/core/colors';
import { LinearScale, LocalCafeOutlined, LocationSearchingTwoTone } from '@material-ui/icons';


const Carrito = (props) => {

    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [maximoPedido, setMaximoPedido] = useState(false);
    const [descuento_total, setDescuento_total] = useState(0);
    const [alerta, setAlerta] = useState();
    const [cupoDisponible, setCupoDisponible] = useState();
    const [observacion, setObservacion] = useState();
    const [ordenCompra, setOrdenCompra] = useState();
    const [result, setResult] = useState(false);
    const [descuento, setDescuento] = useState();
    const [proxDescuento, setProxDescuento] = useState();
    const [nextDescuento, setNextDescuento] = useState();
    const [idDescuento, setIdDescuento] = useState();
    const [tieneDescuento, setTieneDescuento] = useState();
    const [abandonado, setAbandonado] = useState()
    const [documentCompra, setDocumentCompra] = ([])
    const [empresa, setEmpresa] = useState();
    const [loadingCargaExcel, setLoadingCargaExcel] = useState(false)
    const [codcanal] = useState();
    let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()

    const loadHistorial = async () => {
        let url = Constants.WSHISTORIAL +
            "?codigo=" + (empresa ? empresa.codigo_empresa : '') +
            "&cliente=" + codigo;

        axios.get(url)
            .then(res => {
                if (res.data.length >= empresa.max_pedidos) {
                    setMaximoPedido(true);
                } else {
                    setMaximoPedido(false);
                }
            });
    }

    const getCodCanal = () => {
        const codcanal = localStorage.getItem('codCanal')
        if (codcanal == 24 || codcanal == 27) {
            setResult(true);
        } else {
            setResult(false);
        }
    }

    const getIva = () => {
        let carrito = props.carrito || {}
        let iva = 0;
        Object.keys(carrito).map((item, i) => {
            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0)
            iva += ((subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0));
        })
        setIva(iva);
    }

    const getTotal = () => {
        let carrito = props.carrito || {}
        let total = 0;
        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })
        checkDescuentos(total)
        setTotal(total);
    }

    const checkDescuentos = (subtotal) => {
        axios.get(Constants.WSGETDESCUENTOSCODCLIENTE + codigo)
            .then(res => {
                let descuentos = res.data
                if (descuentos.length == 1) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= 99999) {
                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                        setIdDescuento(descuentos[0]["IdDescuento"])
                    } else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }
                } else if (descuentos.length == 2) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= descuentos[0]["rangoFinal"]) {
                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[1]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[0]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[1]["rangoInicial"] && Math.floor(subtotal) <= 99999) {
                        setDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                        setIdDescuento(descuentos[1]["IdDescuento"])
                    } else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }
                } else if (descuentos.length == 3) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= descuentos[0]["rangoFinal"]) {

                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[1]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[0]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[1]["rangoInicial"] && Math.floor(subtotal) <= descuentos[1]["rangoFinal"]) {

                        setDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[2]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[1]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[2]["rangoInicial"] && Math.floor(subtotal) <= 99999) {

                        setDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                        setIdDescuento(descuentos[2]["IdDescuento"])

                    } else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }
                } else if (descuentos.length == 4) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= descuentos[0]["rangoFinal"]) {
                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[1]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[0]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[1]["rangoInicial"] && Math.floor(subtotal) <= descuentos[1]["rangoFinal"]) {
                        setDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[2]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[1]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[2]["rangoInicial"] && Math.floor(subtotal) <= descuentos[2]["rangoFinal"]) {
                        setDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[3]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[2]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[3]["rangoInicial"] && Math.floor(subtotal) <= 99999) {
                        setDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                    }
                    else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }
                } else if (descuentos.length == 5) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= descuentos[0]["rangoFinal"]) {
                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[1]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[0]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[1]["rangoInicial"] && Math.floor(subtotal) <= descuentos[1]["rangoFinal"]) {
                        setDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[2]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[1]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[2]["rangoInicial"] && Math.floor(subtotal) <= descuentos[2]["rangoFinal"]) {
                        setDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[3]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[2]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[3]["rangoInicial"] && Math.floor(subtotal) <= descuentos[3]["rangoFinal"]) {
                        setDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[4]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[4]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[3]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[4]["rangoInicial"] && Math.floor(subtotal) <= 99999) {
                        setDescuento((descuentos[4]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                        setIdDescuento(descuentos[4]["IdDescuento"])
                    } else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }

                } else if (descuentos.length == 6) {
                    if (Math.floor(subtotal) >= descuentos[0]["rangoInicial"] && Math.floor(subtotal) <= descuentos[0]["rangoFinal"]) {
                        setDescuento((descuentos[0]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[1]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[0]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[1]["rangoInicial"] && Math.floor(subtotal) <= descuentos[1]["rangoFinal"]) {
                        setDescuento((descuentos[1]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[2]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[1]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[2]["rangoInicial"] && Math.floor(subtotal) <= descuentos[2]["rangoFinal"]) {
                        setDescuento((descuentos[2]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[3]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[2]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[3]["rangoInicial"] && Math.floor(subtotal) <= descuentos[3]["rangoFinal"]) {
                        setDescuento((descuentos[3]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[4]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[4]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[3]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[4]["rangoInicial"] && Math.floor(subtotal) <= descuentos[4]["rangoFinal"]) {
                        setDescuento((descuentos[4]["valorDescuento"]) * -1)
                        setProxDescuento(descuentos[5]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[5]["valorDescuento"]) * -1)
                        setIdDescuento(descuentos[4]["IdDescuento"])

                    } else if (Math.floor(subtotal) >= descuentos[5]["rangoInicial"] && Math.floor(subtotal) <= 99999) {
                        setDescuento((descuentos[5]["valorDescuento"]) * -1)
                        setProxDescuento(-1);
                        setNextDescuento(-1)
                        setIdDescuento(descuentos[5]["IdDescuento"])
                    } else {
                        setDescuento(0)
                        setProxDescuento(descuentos[0]["rangoInicial"] - Math.floor(subtotal));
                        setNextDescuento((descuentos[0]["valorDescuento"]) * -1)
                    }

                } else {
                    setTieneDescuento(-1)
                    setDescuento(0)
                    setProxDescuento(-1);
                    setNextDescuento(0)
                }
            })

    }

    const handleMas = (codigo, item) => {
        let carrito = props.carrito
        if (carrito[codigo]) {
            carrito[codigo]["cantidad"] += 1;
        } else {
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleEliminar = (codigo, item) => {
        let carrito = props.carrito || {}
        let cantidad = carrito[codigo].cantidad
        if (carrito[codigo]) {
            delete carrito[codigo];
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleMenos = (codigo, item) => {
        let carrito = props.carrito || {}
        if (carrito[codigo]) {
            if (carrito[codigo]["cantidad"] == 1) {
                delete carrito[codigo];
            } else {
                carrito[codigo]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleCancelarPedido = () => {
        setAlerta({
            tipo: "warning",
            titulo: "Cancelar pedido",
            mensaje: "¿Esta seguro de cancelar el pedido?",
            btnOk: "Cancelar pedido",
            btnCancel: "continuar la compra",
            fnOk: function () {
                let carrito = props.carrito || {}
                let codigo = localStorage.getItem("codigoCa")
                Auth.setCarrito({});
                props.ActualizarCarrito({});

                if (codigo != null) {
                    let url = Constants.WS_ELIMINAR_CARRITO_ABANDONADO + '?numeroDoc=' + codigo;

                    axios.delete(url).then(res => {
                        if (res.data.Estado == "OK") {
                            Auth.removeCarrito(codigo);
                        }
                    });
                }

                props.history.replace("/catalogo", "urlhistory");
            },
            fnCancel: function () {
            }
        })

    }

    const alertObservation = () => {
        setAlerta({
            tipo: "error",
            titulo: "Orden de compra",
            mensaje: "Orden de compra obligatoria !",
            btnOk: "Cerrar",
            fnOk: function () {
            }
        })
    }

    const enviarPedido = () => {


        const button = document.getElementById('comprar').disabled = "disabled"
        const codcanal = localStorage.getItem('codCanal')
        const codeCarritoAbandonado = localStorage.getItem('codigoCa')
        let url2 = Constants.DESACTIVARCARRITOABANDONADO + codeCarritoAbandonado;
        axios.post(url2)
            .then(res => {
                console.log(res);
            });

        getCodCanal()

        if (codcanal == 24) {
            if (observacion == null) {
                const button = document.getElementById('comprar').disabled = false
                return alertObservation()
            } else {
                let fechaActual = new Date();
                let carrito = props.carrito || {}
                let hora = fechaActual.getHours();
                let minutos = fechaActual.getMinutes();
                let validador = false;
                empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")
                if (empresa.hora_limite == "") {
                    validador = true
                } else if (parseInt(hora + '' + minutos) <= parseInt(empresa.hora_limite.replace(":", ""))) {
                    validador = true
                } else {
                    alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite + "")
                }
                if (validador) {
                    let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                    let data = [];
                    let count = 0;
                    let numeroDoc = codigo.toString().trim() + moment().format('YYYYMMDDHHmmss');
                    if (descuento < 0) {
                        Object.keys(carrito).map((item, i) => {
                            count++;
                            data.push({
                                "NumeroDoc": numeroDoc.toString(),
                                "CodigoCliente": codigo,
                                "CodigoProveedor": empresa.num_empresa,
                                "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                "Precio": carrito[item]["precio"],
                                "Iva": carrito[item]["iva"],
                                "Posicion": count,
                                "Cantidad": parseInt(carrito[item]["cantidad"]),
                                "CodigoProducto": item,
                                /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                "Param1": carrito[item]["porcentajeDescuento"],
                                /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                "Param4": carrito[item]["clase"], */
                                "Param5": empresa.sucursales[0].canal,
                                /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                "DescripcionParam5": "Canal",
                                "Observacion": observacion ? observacion : "",
                                "IdDescuento": -1

                            })
                        });

                        //espacio reservado para descuentos 

                        data.push({
                            "NumeroDoc": numeroDoc.toString(),
                            "CodigoCliente": codigo,
                            "CodigoProveedor": empresa.num_empresa,
                            "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                            "Precio": descuento,
                            "Iva": 0,
                            "Posicion": count,
                            "Cantidad": 1,
                            "CodigoProducto": 999999,
                            /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                            "Param1": "",
                            /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                            "Param3": parseInt(carrito[item]["codigoDescuento"]),
                            "Param4": carrito[item]["clase"], */
                            "Param5": empresa.sucursales[0].canal,
                            /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                            "DescripcionParam2": "",
                            "DescripcionParam3": "",
                            "DescripcionParam4": "",
                            "DescripcionParam5": "Canal",
                            "Observacion": observacion ? observacion : "",
                            "IdDescuento": idDescuento
                        })
                        //fin de espacio reservado

                    } else {
                        Object.keys(carrito).map((item, i) => {
                            count++;
                            data.push({
                                "NumeroDoc": numeroDoc.toString(),
                                "CodigoCliente": codigo,
                                "CodigoProveedor": empresa.num_empresa,
                                "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                "Precio": carrito[item]["precio"],
                                "Iva": carrito[item]["iva"],
                                "Posicion": count,
                                "Cantidad": parseInt(carrito[item]["cantidad"]),
                                "CodigoProducto": item,
                                /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                "Param1": carrito[item]["porcentajeDescuento"],
                                /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                "Param4": carrito[item]["clase"], */
                                "Param5": empresa.sucursales[0].canal,
                                /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                "DescripcionParam5": "Canal",
                                "Observacion": observacion ? observacion : "",
                                "IdDescuento": -1
                            })
                        });

                    }
                    if (data.length > 0) {

                        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                        let url = Constants.WSPEDIDO + "?codigo=" + codigo//empresa.codigo_empresa;
                        axios.post(url, { "ListaDetalle": data })
                            .then(res => {
                                ReactGa.event({
                                    category: "Thank You Page",
                                    action: "Pedido Enviado",
                                    label: data[0].Precio
                                })
                                // TagManager.dataLayer({
                                //     dataLayer:{
                                //         event:"purchase",
                                //         ecommerce:{
                                //             purchase:{
                                //                 actionField:{
                                //                     id:numeroDoc,
                                //                     affiliation:'',
                                //                     revenue:total - descuento_total + iva,
                                //                     tax:0,
                                //                     shipping:0
                                //                 },
                                //                 products:carrito
                                //             }
                                //         }
                                //     }
                                // })
                                setAlerta({
                                    tipo: "success",
                                    titulo: "Pedido enviado",
                                    mensaje: "Estimado usuario tu pedido ha sido enviado con éxito.",
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                        Auth.setCarrito({});
                                        props.ActualizarCarrito(Auth.getCarrito());
                                        props.history.replace("/catalogo", "urlhistory");
                                    },
                                    fnCancel: null
                                })
                            });
                    }
                }
            }

        } else {
            if (ordenCompra == true) {
                if (observacion == null) {
                    return alertObservation()
                } else {
                    let fechaActual = new Date();
                    let carrito = props.carrito || {}
                    let hora = fechaActual.getHours();
                    let minutos = fechaActual.getMinutes();
                    let validador = false;
                    empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")
                    if (empresa.hora_limite == "") {
                        validador = true
                    } else if (parseInt(hora + '' + minutos) <= parseInt(empresa.hora_limite.replace(":", ""))) {
                        validador = true
                    } else {
                        alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite + "")
                    }
                    if (validador) {
                        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                        let data = [];
                        let count = 0;
                        let numeroDoc = codigo.toString().trim() + moment().format('YYYYMMDDHHmmss');
                        if (descuento < 0) {
                            Object.keys(carrito).map((item, i) => {
                                count++;
                                data.push({
                                    "NumeroDoc": numeroDoc.toString(),
                                    "CodigoCliente": codigo,
                                    "CodigoProveedor": empresa.num_empresa,
                                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                    "Precio": carrito[item]["precio"],
                                    "Iva": carrito[item]["iva"],
                                    "Posicion": count,
                                    "Cantidad": parseInt(carrito[item]["cantidad"]),
                                    "CodigoProducto": item,
                                    /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                    "Param1": carrito[item]["porcentajeDescuento"],
                                    /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                    "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                    "Param4": carrito[item]["clase"], */
                                    "Param5": empresa.sucursales[0].canal,
                                    /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                    "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                    "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                    "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                    "DescripcionParam5": "Canal",
                                    "Observacion": observacion ? observacion : "",
                                    "IdDescuento": -1
                                })
                            });
                            //espacio reservado para descuentos 
                            data.push({
                                "NumeroDoc": numeroDoc.toString(),
                                "CodigoCliente": codigo,
                                "CodigoProveedor": empresa.num_empresa,
                                "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                "Precio": descuento,
                                "Iva": 0,
                                "Posicion": count,
                                "Cantidad": 1,
                                "CodigoProducto": 999999,
                                /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                "Param1": "",
                                /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                "Param4": carrito[item]["clase"], */
                                "Param5": empresa.sucursales[0].canal,
                                /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                "DescripcionParam2": "",
                                "DescripcionParam3": "",
                                "DescripcionParam4": "",
                                "DescripcionParam5": "Canal",
                                "Observacion": observacion ? observacion : "",
                                "IdDescuento": idDescuento
                            })
                            //fin de espacio reservado
                        } else {
                            Object.keys(carrito).map((item, i) => {
                                count++;
                                data.push({
                                    "NumeroDoc": numeroDoc.toString(),
                                    "CodigoCliente": codigo,
                                    "CodigoProveedor": empresa.num_empresa,
                                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                    "Precio": carrito[item]["precio"],
                                    "Iva": carrito[item]["iva"],
                                    "Posicion": count,
                                    "Cantidad": parseInt(carrito[item]["cantidad"]),
                                    "CodigoProducto": item,
                                    /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                    "Param1": carrito[item]["porcentajeDescuento"],
                                    /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                    "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                    "Param4": carrito[item]["clase"], */
                                    "Param5": empresa.sucursales[0].canal,
                                    /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                    "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                    "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                    "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                    "DescripcionParam5": "Canal",
                                    "Observacion": observacion ? observacion : "",
                                    "IdDescuento": -1
                                })
                            });
                        }

                        if (data.length > 0) {

                            let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                            let url = Constants.WSPEDIDO + "?codigo=" + codigo//empresa.codigo_empresa;
                            axios.post(url, { "ListaDetalle": data })
                                .then(res => {

                                    ReactGa.event({
                                        category: "Thank You Page",
                                        action: "Pedido Enviado",
                                        label: data[0].Precio
                                    })
                                    // TagManager.dataLayer({
                                    //     dataLayer:{
                                    //         event:"purchase",
                                    //         ecommerce:{
                                    //             purchase:{
                                    //                 actionField:{
                                    //                     id:numeroDoc,
                                    //                     affiliation:'',
                                    //                     revenue:total - descuento_total + iva,
                                    //                     tax:0,
                                    //                     shipping:0
                                    //                 },
                                    //                 products:carrito
                                    //             }
                                    //         }
                                    //     }
                                    // })
                                    setAlerta({
                                        tipo: "success",
                                        titulo: "Pedido enviado",
                                        mensaje: "Estimado usuario tu pedido ha sido enviado con éxito.",
                                        btnOk: "Aceptar",
                                        fnOk: function () {
                                            Auth.setCarrito({});
                                            props.ActualizarCarrito(Auth.getCarrito());
                                            props.history.replace("/catalogo", "urlhistory");
                                        },
                                        fnCancel: null
                                    })
                                });
                        }
                    }
                }


            } else {
                let fechaActual = new Date();
                let carrito = props.carrito || {}
                let hora = fechaActual.getHours();
                let minutos = fechaActual.getMinutes();
                let validador = false;
                empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")
                if (empresa.hora_limite == "") {
                    validador = true
                } else if (parseInt(hora + '' + minutos) <= parseInt(empresa.hora_limite.replace(":", ""))) {
                    validador = true
                } else {
                    alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite + "")
                }
                if (validador) {
                    let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                    let data = [];
                    let count = 0;
                    let numeroDoc = codigo.toString().trim() + moment().format('YYYYMMDDHHmmss');
                    if (descuento < 0) {
                        Object.keys(carrito).map((item, i) => {
                            count++;
                            data.push({
                                "NumeroDoc": numeroDoc.toString(),
                                "CodigoCliente": codigo,
                                "CodigoProveedor": empresa.num_empresa,
                                "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                "Precio": carrito[item]["precio"],
                                "Iva": carrito[item]["iva"],
                                "Posicion": count,
                                "Cantidad": parseInt(carrito[item]["cantidad"]),
                                "CodigoProducto": item,
                                /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                "Param1": carrito[item]["porcentajeDescuento"],
                                /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                "Param4": carrito[item]["clase"], */
                                "Param5": empresa.sucursales[0].canal,
                                /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                "DescripcionParam5": "Canal",
                                "Observacion": observacion ? observacion : "",
                                "IdDescuento": -1
                            })
                        });
                        //espacio reservado para descuentos 
                        data.push({
                            "NumeroDoc": numeroDoc.toString(),
                            "CodigoCliente": codigo,
                            "CodigoProveedor": empresa.num_empresa,
                            "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                            "Precio": descuento,
                            "Iva": 0,
                            "Posicion": count,
                            "Cantidad": 1,
                            "CodigoProducto": 999999,
                            /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                            "Param1": "",
                            /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                            "Param3": parseInt(carrito[item]["codigoDescuento"]),
                            "Param4": carrito[item]["clase"], */
                            "Param5": empresa.sucursales[0].canal,
                            /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                            "DescripcionParam2": "",
                            "DescripcionParam3": "",
                            "DescripcionParam4": "",
                            "DescripcionParam5": "Canal",
                            "Observacion": observacion ? observacion : "",
                            "IdDescuento": idDescuento
                        })
                        //fin de espacio reservado
                    } else {
                        Object.keys(carrito).map((item, i) => {
                            count++;
                            data.push({
                                "NumeroDoc": numeroDoc.toString(),
                                "CodigoCliente": codigo,
                                "CodigoProveedor": empresa.num_empresa,
                                "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                "Precio": carrito[item]["precio"],
                                "Iva": carrito[item]["iva"],
                                "Posicion": count,
                                "Cantidad": parseInt(carrito[item]["cantidad"]),
                                "CodigoProducto": item,
                                /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                                "Param1": carrito[item]["porcentajeDescuento"],
                                /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                                "Param3": parseInt(carrito[item]["codigoDescuento"]),
                                "Param4": carrito[item]["clase"], */
                                "Param5": empresa.sucursales[0].canal,
                                /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                                "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                                "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                                "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                                "DescripcionParam5": "Canal",
                                "Observacion": observacion ? observacion : "",
                                "IdDescuento": -1
                            })
                        });

                    }

                    if (data.length > 0) {
                        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
                        let url = Constants.WSPEDIDO + "?codigo=" + codigo//empresa.codigo_empresa;

                        axios.post(url, { "ListaDetalle": data })
                            .then(res => {
                                ReactGa.event({
                                    category: "Thank You Page",
                                    action: "Pedido Enviado",
                                    label: data[0].CodigoCliente
                                })
                                // TagManager.dataLayer({
                                //     dataLayer:{
                                //         event:"purchase",
                                //         ecommerce:{
                                //             purchase:{
                                //                 actionField:{
                                //                     id:numeroDoc,
                                //                     affiliation:'',
                                //                     revenue:total - descuento_total + iva,
                                //                     tax:0,
                                //                     shipping:0
                                //                 },
                                //                 products:carrito
                                //             }
                                //         }
                                //     }
                                // })
                                setAlerta({
                                    tipo: "success",
                                    titulo: "Pedido enviado",
                                    mensaje: "Estimado usuario tu pedido ha sido enviado con éxito.",
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                        Auth.setCarrito({});
                                        props.ActualizarCarrito(Auth.getCarrito());
                                        props.history.replace("/catalogo", "urlhistory");
                                    },
                                    fnCancel: null
                                })
                            });
                    }
                }
            }
        }



    }

    const handleChangeCantidad = (codigo, item, valor) => {
        let carrito = props.carrito || {}
        if (valor <= 0) {
            delete carrito[codigo];
        } else {
            if (carrito[codigo]) {
                carrito[codigo]["cantidad"] = parseInt(valor);
            } else {
                carrito[codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }

        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const loadCupo = async () => {
        let url = Constants.WSCUPOCLIENTE + '?codigo=0&codCliente=' + Auth.getCodCliente()
        axios.get(url).then((response) => {
            setCupoDisponible(response.data[0].Cupo - response.data[0].saldo > 0 ? response.data[0].Cupo - response.data[0].saldo : 0)
        })
    }

    const handleValidation = () => {
        axios.get(Constants.WSCARTERAVENCIDA + Auth.getCodCliente()).then((response) => {
            if (response.data.carteraVencida == 1) {
                setAlerta({
                    tipo: "error",
                    titulo: "Cartera Vencida",
                    mensaje: "Estimado usuario a la fecha cuentas con documentos vencidos, recuerda ponerte al día para realizar tu pedido.",
                    btnOk: "Aceptar",
                    fnOk: function () {
                        if (response.data.bloqueoCartera == 1)
                            window.location.href = ('/cartera');
                    },
                    fnCancel: null
                })
            }
        })
    }

    //modulo para carrito abandonado 

    const carritoAbandonado = (respuesta) => {

        let codigoCLiente = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        let data = [];
        let count = 0;
        let numeroDoc = codigo.toString().trim() + moment().format('YYYYMMDDHHmmss');
        let carrito = props.carrito || {}
        let codEmpresa = JSON.parse(Auth.getEmpresa()).empresas[0]

        if (respuesta == 1) {


            Object.keys(carrito).map((item, i) => {
                count++;
                data.push({
                    "NumeroDoc": numeroDoc.toString(),
                    "CodigoCliente": codigoCLiente,
                    "CodigoProveedor": codEmpresa.num_empresa,
                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                    "Precio": carrito[item]["precio"],
                    "Iva": carrito[item]["iva"],
                    "Posicion": count,
                    "Cantidad": parseInt(carrito[item]["cantidad"]),
                    "CodigoProducto": item,
                    /* "ValorDescuento": carrito[item]["descuentoAplicado"], */
                    "Param1": carrito[item]["porcentajeDescuento"],
                    /* "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                    "Param3": parseInt(carrito[item]["codigoDescuento"]),
                    "Param4": carrito[item]["clase"], */
                    "Param5": codEmpresa.sucursales[0].canal,
                    /* "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "", */
                    "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                    "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                    "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                    "DescripcionParam5": "Canal",
                    "Observacion": observacion ? observacion : "",
                    "IdDescuento": idDescuento ? idDescuento : -1,
                })
            });

            let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
            let url = Constants.WSCARRITOABANDONADO + "?codigo=" + codigo;
            axios.post(url, { "ListaDetalle": data })
                .then(res => {
                    console.log(res)
                });
        }
    }

    const temporizadorInactividad = () => {

        var IDLE_TIMEOUT = 3600; //seconds
        var _idleSecondsCounter = 0;
        document.onclick = function () {
            _idleSecondsCounter = 0;
        };
        document.onmousemove = function () {
            _idleSecondsCounter = 0;
        };
        document.onkeypress = function () {
            _idleSecondsCounter = 0;
        };
        window.setInterval(CheckIdleTime, 1000);

        function CheckIdleTime() {
            _idleSecondsCounter++;
            var oPanel = document.getElementById("SecondsUntilExpire");
            if (oPanel)
                oPanel.innerHTML = (IDLE_TIMEOUT - _idleSecondsCounter) + "";
            if (_idleSecondsCounter >= IDLE_TIMEOUT) {
                setTimeout(() => {
                    carritoAbandonado(1)
                    swal("", "Se ha cerrado la sesión por inactividad ", "warning");
                    Auth.signout();
                    window.location.reload(true);
                }, 2000);

            }
        }

    }

    // ReactGa.event({
    //     category:"Producto",
    //     label:`${item.codigo} ${item.nombre}`,
    //     action:"Añade a Carrito",
    // })
    const cargarCompras = (event) => {
        setLoadingCargaExcel(true)
        //metodo para validar la extencion del archivo
        var ext = event.target.value.split('.').pop();
        ext = ext.toLowerCase();

        //metodo para obtener el array del excel 
        var datosFinales = []
        var datosParaCarrito = []
        var datosPerdidos = []
        const target = event.target
        const value = target.type == 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        if (target.value) {
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: 'array' });

                workbook.SheetNames.forEach(function (sheetName) {
                    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    hojas.push({
                        data: XL_row_object,
                        sheetName
                    })
                })

                let result = hojas[0].data

                if (result[0]["Codigo EAN"]) {
                    result.map((data) => {
                        datosFinales.push({
                            codigoEan: data["Codigo EAN"][0] == '0' ? data["Codigo EAN"] : `0${data["Codigo EAN"]}`,
                            cantidad: parseInt(data["Cantidad"]),
                            descripcion: data["Descripcion"]
                        })
                    })
                } else {
                    setAlerta({
                        tipo: "error",
                        titulo: "Archivo incorrecto",
                        mensaje: "Estimado usuario, para cargar tu compra debes subir un archivo compatible",
                        btnOk: "Aceptar",
                        fnOk: function () {
                        },
                        fnCancel: null
                    })
                }

            }
        }
        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        let url = Constants.WSPRODUCTO + "?origen=" + (empresa ? empresa.codigo_empresa : '') + "&cliente=" + codigo + "&items=1000";
        axios.get(url)
            .then(res => {
                let resultGet = res.data.result;
                datosFinales.map((data) => {
                    resultGet.map((data2) => {
                        if (data2.ean == data.codigoEan) {
                            datosParaCarrito.push(data);
                            let carrito = props.carrito || {}
                            if (carrito[data2.codigo]) {
                                carrito[data2.codigo]["cantidad"] += data.cantidad;
                            } else {
                                carrito[data2.codigo] = {
                                    nombre: data2.nombre,
                                    precio: parseFloat(data2.precio),
                                    iva: parseInt(data2.iva),
                                    cantidad: data.cantidad,
                                    codigo: data2.codigo,
                                    categoria: data2.categoria
                                }
                            }
                            Auth.setCarrito(carrito);
                            props.ActualizarCarrito(Auth.getCarrito());
                            setTimeout(() => {
                                setLoadingCargaExcel(false)
                                let input = document.getElementById("cargaCompra")
                                input.value = ''
                            }, 2000);
                        }
                    })
                    if (resultGet.find(element => element.ean == data.codigoEan)) {
                        return
                    } else {
                        datosPerdidos.push("\n" + data.descripcion)
                    }
                })
                if (datosPerdidos.length > 0) {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Productos no disponibles",
                        mensaje: "•" + datosPerdidos.toString(),
                        btnOk: "Aceptar",
                        fnOk: function () {
                        },
                        fnCancel: null
                    })
                }
            });

    }

    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
        let checkCarritoAbandonado = localStorage.getItem("activateAbandone")
        temporizadorInactividad()
        setEmpresa((JSON.parse(Auth.getEmpresa())).empresas[0])
        let checkAbandonado = localStorage.getItem("carritoAbandonado")
        setAbandonado(localStorage.getItem("carritoAbandonado"))
        getCodCanal();
        getIva();
        getTotal();
        loadHistorial();
        loadCupo();
        if (checkCarritoAbandonado == "true") {

        } else {
            carritoAbandonado(checkAbandonado);
        }

    }, [props.carrito])

    useEffect(() => {
        handleValidation()
        localStorage.setItem("activateAbandone", "false")
    }, [])

    return (
        <React.Fragment>
            {abandonado == 1 ?
                <React.Fragment>
                    <div className="loading">
                        <ReactLoading type="spinningBubbles" color="#007DAF" height={100} width={50} />
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="navbar principal">
                        <Navbar item={10} />
                    </div>

                    <div className="pedido-list carrito">
                        {loadingCargaExcel == true ? (
                            <div className="loading">
                                <ReactLoading type="spinningBubbles" color="#007DAF" height={100} width={50} />
                            </div>
                        ) : (
                            <>
                                {props.carrito ? (
                                    <div className="product-list">
                                        {Object.keys(props.carrito).map((item, i) => (
                                            <div className="item item-carrito" key={i}>
                                                <img width="120" className="img-product"
                                                    src={process.env.REACT_APP_URL_IMAGE + item.toString().trim() + '_1.png'}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                                <div className="start">

                                                    <p className="codigo"><b>Código: </b>{item}</p>
                                                    <p className="nombre"><b>{props.carrito[item].nombre}</b></p>
                                                    <p className="precio">
                                                        {Constants.formatNumber(props.carrito[item].precio)}
                                                    </p>
                                                    <p className="regular">
                                                        Total:
                                                        {Constants.formatNumber(props.carrito[item].precio * props.carrito[item].cantidad)}
                                                    </p>
                                                </div>
                                                <div className="end">
                                                    <div className="action">
                                                        <div className="controls">
                                                            <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                                <Minus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                            </button>
                                                            <input type="number"
                                                                onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                                value={props.carrito[item].cantidad} />
                                                            <button role="button" onClick={() => handleMas(item, props.carrito[item])}>
                                                                <Plus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                            </button>
                                                        </div>
                                                        <a style={{ cursor: "pointer" }} onClick={() => handleEliminar(item, props.carrito[item])}>
                                                            <img src={Trash} width="20px" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>

                    <div className="total">
                        <div className="total-content">
                            <div className="title">
                                <p>RESUMEN DEL PEDIDO</p>
                            </div>
                            <hr />
                            <div className="item" style={{ backgroundColor: "#eeeeee" }}>
                                <p className="opc">Cupo Disponible:</p>
                                <p className="val">
                                    {Constants.formatNumber(cupoDisponible - total > 0 ? cupoDisponible - total : 0)}
                                </p>
                            </div>
                            <hr />
                            <div className="item">
                                <p className="opc">Subtotal:</p>
                                <p className="val">
                                    {Constants.formatNumber(total)}
                                </p>
                            </div>

                            <div className="item">
                                <p className="opc">ITBMS:</p>
                                <p className="val">
                                    {Constants.formatNumber(iva)}
                                </p>
                            </div>
                            <div className="item">
                                <p className="opc">Descuento:</p>
                                <p className="val">
                                    {Constants.formatNumber(descuento)}
                                </p>
                            </div>
                            <div className="item">
                                <p className="opc">Total:</p>
                                <p className="val">
                                    {Constants.formatNumber(total + descuento + iva)}
                                </p>
                            </div>
                        </div>
                        <br></br>
                        <div>
                            {tieneDescuento == -1 ? (
                                <div className="item">
                                    <p className="val">

                                    </p>
                                </div>
                            ) : (
                                <div>
                                    {proxDescuento == -1 ? (
                                        <div className="item">
                                            <p className="val">
                                                Has obtenido el limite de descuentos
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="item">
                                            <p className="val">
                                                Te faltan solo {Constants.formatNumber(proxDescuento)} para obtener un descuento de {Constants.formatNumber(nextDescuento)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="miFuente">
                            <label style={{ fontWeight: "bold" }}>Tiene orden de compra ?</label>
                            <input onChange={e => {
                                setOrdenCompra(e.target.checked)
                            }} type="checkbox" style={{ zoom: 0.5 }} defaultChecked={false} />
                        </div>



                        <div>
                            {ordenCompra == true ? (
                                <React.Fragment>
                                    <div className="observacion">
                                        <label style={{ fontWeight: "bold" }}>Orden de compra: </label>
                                        <input class="input" onChange={e => setObservacion(e.target.value)} placeholder="Orden de compra:" maxLength="12" />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>

                                </React.Fragment>
                            )}

                        </div>



                        {maximoPedido ? (
                            <div className="carrito-validacion">
                                <div>
                                    <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                </div>
                                <div className="contenido">
                                    <p><b>Cantidad maxima de pedidos superada</b></p>
                                    <p>Solo se permiten maximo ({empresa.max_pedidos}) pedidos por día.</p>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                {empresa && empresa.pedido_minimo > (total + iva) ? (
                                    <div className="carrito-validacion">
                                        <div>
                                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                        </div>
                                        <div className="contenido">
                                            <p><b>El valor de el pedido es muy bajo </b></p>
                                            <p>El pedido debe ser mayor a {Constants.formatNumber(empresa.pedido_minimo)}</p>
                                        </div>

                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {cupoDisponible - total > 0 | result == true ? (
                                            <button type="submit" className="btn-large" id="comprar" onClick={() => enviarPedido()}>Enviar pedido</button>
                                        ) : (
                                            <React.Fragment>
                                                {cupoDisponible > 0 ? (
                                                    <div className="carrito-validacion">
                                                        <div>
                                                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                        </div>
                                                        <div className="contenido">
                                                            <p><b>El valor del pedido supera el cupo disponible</b></p>
                                                            <p>El valor total del pedido debe ser menor a {Constants.formatNumber(cupoDisponible ? cupoDisponible : 0)} para tener un cupo mayor, verifica tu cartera <a href='/cartera'>Cartera</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="carrito-validacion">
                                                        <div>
                                                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                        </div>
                                                        <div className="contenido">
                                                            <p><b>No tienes cupo disponible para realizar este pedido</b></p>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}

                        <div className="carrito-informacion">
                            <div style={{ paddingTop: "2px" }}>
                                <div className="card-info">
                                    <p><b>ADVERTENCIA:</b></p>
                                    <p>Estimado usuario, si no tienes disponible recibir mercancía los días sábados,
                                        te recomendamos NO realizar pedidos los días Jueves</p>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn-large outline" style={{ className: "miFuente" }} onClick={() => handleCancelarPedido()}>Cancelar pedido</button>
                        <br />



                        <div className='excelCarrito btn-large'>
                            <input
                                required
                                type='file'
                                name='cargaCompra'
                                id='cargaCompra'
                                onChange={cargarCompras}
                                placeholder="Sube tu venta"
                            />
                        </div>
                        <div className="miFuente" style={{ marginBottom: "5px" }}>
                            <label style={{ fontWeight: "bold" }}>¡Si tienes lista tu compra, sube tu excel!</label>
                        </div>
                    </div>

                    <Tabbar item={10} />
                    {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}

                </React.Fragment>
            }
        </React.Fragment >



    )
}


const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}


export default withRouter(connect(mapStateToProps, { ActualizarCarrito, ActualizarUsuario })(Carrito));