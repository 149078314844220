import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const TableHeaderDetail = (props) => {

    const [rows, setRows] = React.useState()

    React.useEffect(()=>{
        let data = props.table.header
        data.sort(function(itemA,itemB){
            if(itemA.Razonsocial > itemB.Razonsocial){
                return 1
            }
            if(itemA.Razonsocial < itemB.Razonsocial){
                return -1
            }

            return 0
        })
        setRows(data)
    },[])

    const useRowStyles = makeStyles({
        root: {
          '& > *': {
            borderBottom: 'unset',
          },
        },
    });
    
    const Row = (data) => {
        
        const { row } = data;
        const [open, setOpen] = React.useState(false);
        const classes = useRowStyles();
        
        return (
            <React.Fragment>
                <TableRow key={row[props.table.fieldsHeaders[0]]} className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    {Object.keys(props.table.fieldsHeaders).map((index)=>(
                        props.table.fieldsHeaders[index]!=props.table.sub ?
                            <TableCell>{
                                props.table.fieldsHeaders[index]=='saldo' ? 
                                ("$"+row[props.table.fieldsHeaders[index]])
                                : row[props.table.fieldsHeaders[index]]}
                            </TableCell>
                        : null
                    ))}
                </TableRow>
                {row[props.table.sub].length>0 ? 
                    <TableRow key={row[props.table.fieldsHeaders[0]]}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                {/* <Typography variant="h6" gutterBottom component="div">
                                    {props.table.titleDetail}
                                </Typography> */}
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            {props.table.nameDetail.map((nameDetail)=>(
                                                <TableCell>{nameDetail}</TableCell>
                                            ))}                           
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row[props.table.sub].map((sub) => (
                                            <TableRow key={sub[0]}>
                                                {props.table.fieldsDetails.map((fieldDetail)=>(
                                                    <TableCell>{
                                                        fieldDetail=='saldo' ? 
                                                        ("$"+sub[fieldDetail])
                                                        : sub[fieldDetail]
                                                    }</TableCell>
                                                ))}  
                                            </TableRow>                             
                                        ))}
                                    </TableBody>
                                </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                : null }
            </React.Fragment>
        );
    }
    
    Row.propTypes = {
        row: PropTypes.shape({
            calories: PropTypes.number.isRequired,
            carbs: PropTypes.number.isRequired,
            fat: PropTypes.number.isRequired,
            history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
            ).isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            protein: PropTypes.number.isRequired,
        }).isRequired,
    };
    
  return (
    <React.Fragment>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell>{/* {props.table.titleDetail} */}</TableCell>
                { props.table.fieldsHeaders.map((item)=>(
                    <TableCell>{String(item).toLowerCase() != String(props.table.sub).toLowerCase() ? item : null}</TableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
                {rows ? (
                        rows.map((row) => (
                            <Row key={row[props.table.fieldsHeaders[0]]} row={row} />
                        ))
                    ) : null
                }
            </TableBody>
        </Table>
        </TableContainer>
        <br/><br/><br/>   
    </React.Fragment>
  );
}

export default TableHeaderDetail
