import React, { useState, useEffect} from 'react';
import * as Constants from '../../constants/global';
import axios from 'axios';
//import { AppContext } from '../../context/AppState';
import Progress from '../util/Progress';
import { ReactComponent as Minus } from '../../assets/icon/minus.svg';
import { ReactComponent as Plus } from '../../assets/icon/plus.svg';
import logo from '../../assets/img/default.png';
import SucursalModal from '../modal/SucursalModal';
import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import ReactGa from 'react-ga'

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';

import { withRouter } from "react-router-dom";
import {connect} from "react-redux";

import Slide from '../util/slide';

//tag manager
// import TagManager from 'react-gtm-module'

const Producto = (props) => {

    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();
    const [empresa, setEmpresa] = useState();
    const [sucursal, setSucursal] = useState();
    const [alerta, setAlerta] = useState();
    const [imgBanner, setImgBanner] = useState();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        let url = Constants.WSPRODUCTO + "?origen=" + (empresa ? empresa.codigo_empresa : '') + "&cliente=" + codigo;

        if (props.search) {
            url += "&search=" + props.search;
        } else {
            if (props.categoria) {
                url += "&ca=" + props.categoria.codigo;
            }
        }

        if (tipo == 1) {
            if (productos) {
                if (productos.now < productos.next) {
                    url += "&page=" + productos.next;
                }
            }
        }
        console.log(url);
        axios.get(url)
            .then(res => {
                setLoader(false);
                let array = res.data;

                if (tipo == 1) {
                    if (productos) {
                        if (productos.result) {
                            let combine = productos.result.concat(array.result);
                            array.result = combine;
                        }
                    }
                }
                setProductos(array);
                handleImpressions(array.result)               
            });
    }

    const handleImpressions = (products) => {
        let impressions = []
        Object.keys(products).map((item,i)=>{
            impressions.push({
                'name':products[item].nombre,
                'code':products[item].codigo,
                'price':products[item].precio,
                'category':products[item].categoria
            })
        })
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:"impressions",
        //         ecommerce:{
        //             currencyCode:'COP',
        //             impressions:impressions
        //         }
        //     }
        // })
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = item => {
        console.log("prueba",item);
        let carrito = props.carrito || {}
        if (carrito[item.codigo]) {
            carrito[item.codigo]["cantidad"] += 1;
        } else {
            carrito[item.codigo] = {
                nombre: item.nombre,
                precio: parseFloat(item.precio),
                iva: parseInt(item.iva),
                cantidad: 1,
                codigo:item.codigo,
                categoria:item.categoria
            }
        }
        ReactGa.event({
            category:"Event Product",
            action:"Add To Cart ",
            label:`${item.codigo} ${item.nombre}`
        })
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        //loadProductos(0);

        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'addToCart',
        //         ecommerce:{
        //             currencyCode:'COP',
        //             add:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //     }
        // })
    }

    const handleMenos = item => {
        let carrito = props.carrito || {}
        if (carrito[item.codigo]) {
            if (carrito[item.codigo]["cantidad"] == 1) {
                delete carrito[item.codigo];
            } else {
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        //loadProductos(0);
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'removeFromCart',
        //         ecommerce:{
        //             remove:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //     }
        // })
    }

    const handleChangeCantidad = (item, valor) => {
        let carrito = props.carrito || {}
        if (valor <= 0) {
            delete carrito[item.codigo];
        } else {
            if (carrito[item.codigo]) {
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            } else {
                carrito[item.codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    categoria:item.categoria,
                    codigo:item.codigo
                }
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        //loadProductos();
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'addToCart',
        //         ecommerce:{
        //             currencyCode:'COP',
        //             add:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //     }
        // })
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {
        let empresa = (JSON.parse(Auth.getEmpresa()))
        setEmpresa(JSON.parse(Auth.getEmpresa()))
        setLoader(true);
        if (empresa) {
            if (props.categoria) {
                loadProductos(0);
            }
            cargarBanners(empresa.empresas[0].num_empresa)
        }
    }, [props.categoria, props.search])


    const cargarBanners = ( codEmpresa) => {
        let url =  Constants.WSBANNERS +
            "?numEmpresa=" + codEmpresa;
        axios.get(url)
            .then(res => {
                setLoader(false);
                let categoria = props.categoria;
                if (categoria) {
                    let dataImagen = res.data.find(element => element.CodigoCategoria == categoria.codigo);
                    setImgBanner(dataImagen? dataImagen.Imagen:logo)
                }
            });
    }

    const handleChain = () =>{
        if (Auth.getIsChain() == 1) {
            setSucursal(JSON.parse(Auth.getSucursal()))
            if (!JSON.parse(Auth.getSucursal()))
                document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        }
    }

    useEffect(() => {
       handleChain()
    }, [])

    useEffect(() => {
        axios.get(Constants.WSCARTERAVENCIDA + Auth.getCodCliente()).then((response) => {
            if (response.data.carteraVencida == 1) {
                setAlerta({
                    tipo: "error",
                    titulo: "Cartera Vencida",
                    mensaje: "Estimado usuario a la fecha cuentas con documentos vencidos, recuerda ponerte al día para realizar tu pedido.",
                    btnOk: "Aceptar",
                    fnOk: function () {
                        if(response.data.bloqueoCartera==1)
                            window.location.href = ('/cartera');
                    },
                    fnCancel: null
                })
            }
        })
    }, [])

    // VISTA
    return (
        <div className="main">

            <div className="banner-ramo">
                <img id="imagen-banner-ramo" src={imgBanner ? imgBanner : ""}></img>
            </div>

            <div style={{paddingTop:"15px"}}>
                <div className="card-info">
                    <p>Realiza tus pedidos cualquier día a cualquier hora, manteniendo tus días habituales de entrega.
                    Tener en cuenta que para que el pedido pueda ser entregado, debe ser solicitado por la plataforma 
                    con 48 horas de anticipación a tu día actual de entrega.</p>
                </div>
            </div> 

            <div className='pedido-list padding-banner'>
                <div className='pedido-list'>
                    {loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>

                            {productos ? (
                                <React.Fragment>
                                    { props.tipo == 2 ? (
                                        <div className="product-list">
                                            {productos.result.map((item, i) => (
                                                <div className="item" key={i}>
                                                    <p className="nombre"><b>{item.nombre}</b></p>
                                                    <p className="codigo"><b>Código:</b> {item.codigo}
                                                        {/* - <b>EAN:</b> {item.ean} */}
                                                    </p>
                                                    <p className="precio"> {/* { Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) } */}
                                                        {Constants.formatNumber(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}
                                                    </p>
                                                    <div className="action">
                                                        {props.carrito[item.codigo] ? (
                                                            <div className="controls">
                                                                <button role="button" onClick={() => handleMenos(item)}>
                                                                    {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                    <Minus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                                </button>
                                                                <input type="number"
                                                                    onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                    value={props.carrito[item.codigo].cantidad} />
                                                                <button role="button" onClick={() => handleMas(item)}>
                                                                    {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                    <Plus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                                </button>
                                                            </div>
                                                        ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                            <div className="product-card">
                                                {productos.result.map((item, i) => (
                                                    <div className="card" key={i}>
                                                        <Slide item={item}></Slide>
                                                        <p className="nombre"><b>{item.nombre}</b></p>
                                                        <p className="codigo"><b>Código:</b> {item.codigo}</p>
                                                        {/* <p className="codigo"><b>EAN:</b> {item.ean}</p> */}
                                                        <p className="precio"> {/* {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                            {Constants.formatNumber(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}
                                                        </p>
                                                        <div className="action">
                                                            {props.carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                        <Minus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                                    </button>
                                                                    <input type="number"
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={props.carrito[item.codigo].cantidad} />
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                        <Plus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                    <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                                )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    <div className="load-mas">
                                        {productos.now < productos.next ? (
                                            <button onClick={() => cargarMas()}>Cargar mas</button>
                                        ) : null}
                                    </div>

                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    )}
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            <SucursalModal></SucursalModal>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito}) (Producto));