import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import * as Constants from '../../constants/global';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Progress from '../util/Progress';
import ReactGa from 'react-ga'

const Contactenos = (props) => {

    const [loader, setLoader] = useState();
    const [datosVendedor, setDatosVendedor] = useState();

    const handleDatosVendedor = () => {
        setLoader(true)
        axios.get(Constants.WSDATOSVENDEDOR + Auth.getCodCliente()).then((response)=>{
            setDatosVendedor(response.data)
            setLoader(false)
        })
    }

    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
        handleDatosVendedor();
    },[])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={8}  />
            </div>

            <div className="layout">
                <div className="datos">
                {loader ? (
                    <Progress color={"#0194C5"}></Progress>
                ) : (
                    <div>
                        {/* <div className="card">
                            <div className="info">
                                <h2>
                                </h2>
                            </div>
                        </div> */} 
                        <br /><br />
                        <div className="card">                    
                            <h2>Información del Vendedor</h2>
                            <div className="info">
                                {datosVendedor ?
                                    <React.Fragment>
                                        <p>
                                            <i class="fa fa-text-width" aria-hidden="true"></i>
                                            <b>Nombre:</b>  
                                            {" " +datosVendedor.nombre}                                                          
                                        </p>
                                        <p>
                                            <i class="fa fa-key" aria-hidden="true"></i>
                                            <b>Código:</b>
                                            {" " +datosVendedor.codigo}
                                        </p>
                                        <p>
                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                            <b>Correo:</b> 
                                            {" " +datosVendedor.correo}
                                        </p>
                                        <p>
                                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                            <b>Teléfono:</b> 
                                            {" " + datosVendedor.telefono}
                                        </p>
                                    </React.Fragment> 
                                : "Información del vendedor no disponible" }
                            </div>
                        </div>
                        <br /><br />
                        <div className="card">
                            <h2>Servicio al Cliente</h2>
                            <React.Fragment>
                                <div className="info" style={{borderBottom: "1px solid #ebebeb",paddingBottom: "10px"}}>
                                    <p>
                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                        <b>Correo:</b> {("info@epa.com.pa").toLowerCase()}
                                    </p>
                                    <p>
                                        <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                        <b>Teléfono:</b> +507 6550-3225
                                    </p>
                                    <p>
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        <b>Horario de atención:</b> Lunes a Viernes de 8:00 am a 5:00pm, Sábado: 8:00am a 12:00 md.
                                    </p>
                                    {/* <p>
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                        <b>SITIO:</b> N/A
                                    </p> */}
                                    <div className="btn">                               
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                        {/* <div className="panel-button">
                            <a
                            className="seguir-comprando"
                            onClick={(e) => props.history.push("/")}
                            >
                            Seguir comprando
                            </a>
                        </div> */}
                    </div>
                )}
                </div>
            </div>
              
            <Tabbar item={8}/>
            
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps,{ActualizarCarrito}) (Contactenos));