import React, { useState, useEffect} from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';
import {ReactComponent as Search} from '../../assets/icon/search.svg';
import ReactGa from 'react-ga'
import axios from 'axios';
import Auth from '../../helpers/Auth';
import * as Constants from '../../constants/global';

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';

import { withRouter } from "react-router-dom";
import {connect} from "react-redux";


const Catalogo = (props) => {

    const [search, setSearch] = useState();
    const [categoria, setCategoria] = useState();

    const handleCategoria = item => {
        setCategoria(item);
    }
    
    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleBuscarProducto()
        }
    }

    const handleBuscarProducto = () => {
        let busqueda = document.getElementById("search").value
        setSearch(busqueda)
        document.getElementsByClassName("search-input")[0].value = " "
    }

    const recuperarCarrito = () => {
        let codigo =  localStorage.getItem("codigoCa")
        let url = Constants.WSRECUPERARCARRITOABANDONADO + codigo;
        
        axios.get(url)
        .then(res => {
            let data = res.data
            
            data.map((info =>{
                Auth.setCarrito(info);
                props.ActualizarCarrito(Auth.getCarrito());
            }))
        });
        
        props.history.push('/carrito');  
    }

    useEffect(() => {
        let result = localStorage.getItem("activateAbandone")
        if(result == "true"){
            let codigo = localStorage.getItem("codigoCa")
            if(codigo != null){
                recuperarCarrito()
            }else{}
        }else{

        }
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[])

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">
                    <Navbar item={3} />                   

                    <div className="input-search">
                        <input 
                            className="search-input"
                            style={{className:"miFuente"}}
                            type="text" 
                            placeholder="Buscar producto..."
                            name="search"
                            id="search"
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            >
                        </input>
                    </div>
                    <div className="button-Search">
                        <button onClick={() => handleBuscarProducto()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                    </div>
                   
                </div>
                <Categoria handleCategoria={handleCategoria}/>
            </div>

            <Producto tipo={1} categoria={categoria} search={search} />
            <Tabbar item={3}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}


export default withRouter(connect(mapStateToProps, {ActualizarCarrito}) (Catalogo));