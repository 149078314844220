

export const KEY_TOKEN = "token";
export const KEY_UBICACION = "ubicacion";
export const KEY_CARRITO = "carrito";
export const KEY_PRODUCTO = "producto";
export const KEY_COD = 'codigo';
export const IS_CHAIN = 'esCadena';
export const EMPRESA = 'empresa';
export const SUCURSAL = 'sucursal';
export const CARTERA_VENCIDA = 'carteraVencida';
export const SUGERIDO = 'sugerido';
export const BLOQUEOCARTERA = 'bloqueoCartera';
export const CARTERA = 'cartera';

const Auth = {

    isAuthenticated: false,
    authenticate(cb) {
        this.isAuthenticated = true
        setTimeout(cb, 100)
    },
  
    signout() {
        localStorage.clear()
    },

    setCartera(cartera){
        return localStorage.setItem(CARTERA, cartera);
    },

    getCartera(){
        return localStorage.getItem(CARTERA);
    },

    setBloqueoCartera(bloqueoCartera){
        return localStorage.setItem(BLOQUEOCARTERA, bloqueoCartera);
    },

    getBloqueoCartera(){
        return localStorage.getItem(BLOQUEOCARTERA);
    },

    setSugerido(sugerido){
        return localStorage.setItem(SUGERIDO, sugerido);
    },

    getSugerido(){
        return localStorage.getItem(SUGERIDO);
    },

    setIsChain(isChain){
        return localStorage.setItem(IS_CHAIN, isChain);
    },

    getIsChain(){
        return localStorage.getItem(IS_CHAIN);
    },

    setEmpresa(empresa){
        return localStorage.setItem(EMPRESA, empresa)
    },

    getEmpresa(){
        return localStorage.getItem(EMPRESA)
    },

    setSucursal(sucursal){
        return localStorage.setItem(SUCURSAL, sucursal)
    },

    getSucursal(){
        return localStorage.getItem(SUCURSAL)
    },
    setCarteraVencida(carteraVencida){
        return localStorage.setItem(CARTERA_VENCIDA, carteraVencida)
    },

    getCarteraVencida(){
        return localStorage.getItem(CARTERA_VENCIDA)
    },

    setCodProductos(nit) {
        return localStorage.setItem(KEY_PRODUCTO, nit);
    },

    getCodProductos() {
        return localStorage.getItem(KEY_PRODUCTO);
    },

    setCodCliente(nit) {
        return localStorage.setItem(KEY_COD, nit);
    },

    getCodCliente() {
        return localStorage.getItem(KEY_COD);
    },

    setToken(token) {
        return localStorage.setItem(KEY_TOKEN, token);
    },

    getToken() {
        return localStorage.getItem(KEY_TOKEN);
    },
    
    setCarrito(carrito) {
        return localStorage.setItem(KEY_CARRITO, JSON.stringify(carrito));
    },

    getCarrito() {
        return JSON.parse(localStorage.getItem(KEY_CARRITO)) || {};
    },

    removeCarrito() {
        localStorage.removeItem(KEY_CARRITO);
    },
      
}

export default Auth
  
  