import React, { useEffect, useState } from 'react'
import buscar from '../../assets/imgAyuda/buscar.png';
import carrito_compras from '../../assets/imgAyuda/carrito_compras.png';
import historial from '../../assets/imgAyuda/historial.png';
import metodos_pago from '../../assets/imgAyuda/metodos_pago.png';
import num_sap from '../../assets/imgAyuda/num_sap.png';
import producto_dos from '../../assets/imgAyuda/producto_dos.png';
import producto_uno from '../../assets/imgAyuda/producto_uno.png';
import sucursal from '../../assets/imgAyuda/sucursal.png';
import botonExcel from '../../assets/imgAyuda/boton_excel.png'
import subirExcel from '../../assets/imgAyuda/subir_excel.png'
import ejemploExcel from '../../assets/imgAyuda/pedidoEjemplo.xlsx'
import ReactDom from 'react-dom'
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        
        
    },
};

const customStyles2 = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        height: "500px"
        
    },
};

const customStyles3 = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: "85%",
        height: "500px"
        
    },
};

const customStyles4 = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: "97%",
        height: "400px"
        
    },
};

export function Card({ item }) {

    const [alerta, setAlerta] = useState(null);
    const [openModal1, setOpenModal1] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const [openModal4, setOpenModal4] = useState(false);
    const [openModal5, setOpenModal5] = useState(false);
    const [openModal6, setOpenModal6] = useState(false);
    const [openModal7, setOpenModal7] = useState(false);
    const [openModal8, setOpenModal8] = useState(false);
    const [openModal9, setOpenModal9] = useState(false);
    const [openModal10, setOpenModal10] = useState(false);
    const [openModal11, setOpenModal11] = useState(false);
    const [openModal12, setOpenModal12] = useState(false);
    const [openModal13, setOpenModal13] = useState(false);


    useEffect(()=>{
        console.log(item.width);
    })

    const abrirModal = (idCard) => {
        let card = idCard;
        if (card === "card1") {
            setOpenModal1(true);
        } else if (card === "card2") {
            setOpenModal2(true)
        } else if (card === "card3") {
            setOpenModal3(true)
        } else if (card === "card4") {
            setOpenModal4(true)
        } else if (card === "card5") {
            setOpenModal5(true)
        } else if (card === "card6") {
            setOpenModal6(true)
        } else if (card === "card7") {
            setOpenModal7(true)
        } else if (card === "card8") {
            setOpenModal8(true)
        } else if (card === "card9") {
            setOpenModal9(true)
        } else if (card === "card10") {
            setOpenModal10(true)
        } else if (card === "card11") {
            setOpenModal11(true)
        } else if (card === "card12") {
            setOpenModal12(true)
        } else if (card === "card13"){
            setOpenModal13(true)
        }
        
    }

    const closeModal = () => {
        setOpenModal1(false);
        setOpenModal2(false);
        setOpenModal3(false);
        setOpenModal4(false);
        setOpenModal5(false);
        setOpenModal6(false);
        setOpenModal7(false);
        setOpenModal8(false);
        setOpenModal9(false);
        setOpenModal10(false);
        setOpenModal11(false);
        setOpenModal12(false);
        setOpenModal13(false);
    }

    return (
        <div>
        <React.Fragment>
            <div className="card" onClick={() => (abrirModal(item.id))}>
                <div>
                    <img
                        src={item.img} width={item.width} height={item.heigth}>
                    </img>
                </div>
                <p className="precio"> {item.title}</p>
            </div>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal1}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo comprar?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1. Dirígete a la sección de Catalogo en el menú superior</p>
                </div>
                <div className="modal-link-ayuda">
                    <a href="https://tienda.epa.com.pa/catalogo">https://tienda.epa.com.pa/catalogo</a>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2. Elige el producto que deseas comprar..</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3. Agrega la cantidad deseada, tus productos podrán ser verificados en el carrito de compras, este irá aumentando en cantidad y valor acorde vayas añadiendo productos.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">4. “Clic” en el carrito de compras</p>
                </div>
                <div className="modal-alert-icon">
                    <img
                        src={carrito_compras} />
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">5. Verifica que los artículos y cantidad que seleccionas son correctos.</p>
                </div>

                <div className="modal-contenido-ayuda">
                    <p className="alert-message">6.	Si tienes orden de compra, rellena la casilla e ingresa el número de tu orden de compra.</p>
                </div>

                <div className="modal-contenido-ayuda">
                    <p className="alert-message">7. ¡Enviar pedido y listo!.</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal2}
                onRequestClose={closeModal}
                style={customStyles2}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo añadir al carrito?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1. Debes buscar el producto ya sea en la barra de búsqueda por secciones del catálogo o dirigirte al buscador de productos:</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={buscar} />
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2. Una vez encuentres tu producto, haz clic en el botón de agregar y seleccionas la cantidad que deseas comprar haciendo clic en el símbolo (+) si deseas restar la cantidad solo tienes que hacer clic en el símbolo (-)</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={producto_uno} width="170px" height="auto"/>
                    <img src={producto_dos} width="170px" height="auto"/>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3. Una vez realizada la acción automáticamente tus productos estarán en el carrito de compras, para verlos solo haz clic en tu carrito: </p>
                </div>
                <div className="modal-alert-icon">
                    <img src={carrito_compras}/>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal3}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo cancelar o modificar mi pedido?</h1>
                </div>
                <div className="modal-alert-icon">
                    <img src={carrito_compras} />
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2. En la parte derecha de esta sección aparece tu “RESUMEN DE PEDIDO” haz clic en el botón de “cancelar pedido”, por seguridad te aparecerá un mensaje para confirmar que se cancela el pedido o deseas seguir comprando.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3. Selecciona tu opción y tu pedido quedará cancelado o modificado.</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal4}
                onRequestClose={closeModal}
                style={customStyles3}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Métodos de pago</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Te brindamos para tu comodidad 3 métodos para gestionar tus pagos:</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={metodos_pago} width="100%" height="auto"/>
                </div>
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Pago por depósito bancario:</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1. Ingresa la cuenta bancaria a la cual se hará el deposito</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2. Adjunta el archivo del documento bancario (imagen jpg o png)</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3. En observación coloca el detalle del depósito bancario (sucursal - monto)</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">4. Haz clic en enviar</p>
                </div>
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Pago por ACH:</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1.  Escoge el banco para realizar la transferencia</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2.  Selecciona tu cuenta de destino</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3.  En observación coloca el detalle del depósito bancario (sucursal - monto)</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">4.  Haz clic en enviar</p>
                </div>
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Pago por tarjeta de crédito:</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1. Llena el formulario de pago con la siguiente información del cliente:</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Total a pagar</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Tipo de tarjeta</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Número de tarjeta</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Fecha de expiración</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Código de verificación</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2.	Haz clic en enviar</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal5}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Costo de envío y entregas</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Los costos de envíos y entregas son de forma GRATUITA, es decir, no tienen ningún costo adicional.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">¿Cuál es el tiempo de entrega de mi pedido?</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">El tiempo de entrega es de 48hrs una vez se finalice el pedido, iniciando a partir de su fecha de facturación</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal6}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cuál es mi número de SAP?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Este número lo podrás encontrar en tu factura:</p>
                </div>
                <div className="modal-alert-icon">
                    <img
                        src={num_sap} width="650px" height="auto" />
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal7}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo cambiar de sucursal?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Para cambiar de sucursal dirígete a la parte superior de la plataforma, el nombre que aparece en pantalla es la sucursal seleccionada a la que estas realizando tu pedido.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Para cambiarla solo haz clic en la Imagén:</p>
                </div>
                <div className="modal-alert-icon">
                    <img
                        src={sucursal} />
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Y aparecerán todas tus sucursales, selecciona la sucursal que deseas y ya podrás iniciar a hacer tus pedidos.</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal8}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo saber a qué sucursal estoy haciendo mi pedido?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Para cambiar de sucursal dirígete a la parte superior de la plataforma, el nombre que aparece en pantalla es la sucursal seleccionada a la que estas realizando tu pedido.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Para cambiarla solo haz clic en la Imagén:</p>
                </div>
                <div className="modal-alert-icon">
                    <img
                        src={sucursal} />
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Y aparecerán todas tus sucursales, selecciona la sucursal que deseas y ya podrás iniciar a hacer tus pedidos.</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal9}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo hago un pedido para todas mis tiendas al mismo tiempo?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">No se pueden realizar varios pedidos al mismo tiempo para todas las sucursales, debes finalizar un pedido por sucursal, para así iniciar el proceso en otra sucursal.</p>
                </div>
            </Modal>
        </React.Fragment>
        
        <React.Fragment>
            <Modal
                isOpen={openModal10}
                onRequestClose={closeModal}
                style={customStyles4}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo buscar HISTORIAL DE PAGOS / FACTURAS / HISTORIAL DE PEDIDOS?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• En la parte superior de la plataforma podrás encontrar en el menú donde aparece la opción “HISTORIAL DE PAGOS” haz clic, una vez ya en esta opción podrás realizar búsqueda por fechas de pagos realizados y estados de estos.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Esta sección incluye todos los pedidos realizados desde el registro del usuario hasta la fecha actual, se puede apreciar un informe definido por fechas de los pedidos realizados. Para encontrar un pedido puedes utilizar el filtro de Buscar ingresando las fechas “DESDE” y “HASTA”.</p>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">• Se podrá verificar por fecha exacta los pagos enviados. Estatus de los pagos aprobados o rechazados. Para encontrar un historial de pago puedes utilizar el filtro de Buscar ingresando las fechas “DESDE” y “HASTA”:</p>
                </div>
                <div className="modal-alert-icon">
                    <img
                        src={historial} width="1300px" height="auto"  />
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal11}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo cambiar mi correo?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Si ya haz creado tu usuario en la plataforma y deseas cambiarlo, puedes realizar la consulta a tu vendedor o enviar un correo a:</p>
                </div>
                <div className="modal-link-ayuda">
                    <a href="mailto:info@epa.com.pa">info@epa.com.pa</a>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">solicitando el cambio que deseas realizar.</p>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal12}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">Contactar con nuestro servicio al cliente</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Para contactar a nuestro servicio al cliente puedes llamar al 217-2233 o escribirnos a:</p>
                </div>
                <div className="modal-link-ayuda">
                    <a href="mailto:info@epa.com.pa" target="_blank">info@epa.com.pa</a>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Ingreso y toma de pedidos:</p>
                </div>
                <div className="modal-link-ayuda">
                    <a href="https://drive.google.com/file/d/1CHVKe-c8ANKpw1EyfhAOWFysUH8C-nk5/view?usp=sharing" target="_blank">Ver Video...</a>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Estados de cuenta y pagos:</p>
                </div>
                <div className="modal-link-ayuda">
                    <a href="https://drive.google.com/file/d/1yk-zhDjdli7HB3eExG_H5gB9dBmXS7QP/view?usp=drivesdk" target="_blank">Ver Video...</a>
                </div>
            </Modal>
        </React.Fragment>

        <React.Fragment>
            <Modal
                isOpen={openModal13}
                onRequestClose={closeModal}
                style={customStyles2}
                contentLabel="Example Modal"
            >
                <div className="modal-content-ayuda">
                    <h1 className="modal-title">¿Cómo añadir al carrito cargando archivo de Excel?</h1>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">1. Primero haz clic en tu carrito:</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={carrito_compras}/>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">2. Luego das clic en el botón cargar Excel.</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={botonExcel}/>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">3. Seleccionas el archivo y Abrir.</p>
                </div>
                <div className="modal-alert-icon">
                    <img src={subirExcel}/>
                </div>
                <div className="modal-contenido-ayuda">
                    <p className="alert-message">Nota: Recuerda que los formatos permitidos son (XLS, XLSX y CSV); tambien puedes descargar este archivo de ejemplo.</p>
                </div>
                <div className="modal-alert-icon">
                <a href={ejemploExcel} download="Pedido Pascual"><i className='pi pi-file-excel' style={{fontSize:"2rem", color:"#0F783F"}}></i> </a>
                </div>
                
            </Modal>
        </React.Fragment>
        </div>
    )
}
