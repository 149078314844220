import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import 'primeicons/primeicons.css';
import axios from 'axios';
import Progress from '../util/Progress';
import * as Constants from '../../constants/global';
import FooterTable from './FooterTable';
import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import ReactExport from "react-export-excel";
import visibility from '../../assets/icon/visibility.svg'
// import noVisibility from '../../assets/icon/no-visibility.svg'
import NotasCreditoModal from '../modal/NotasCreditoModal';
import excel from '../../assets/icon/excel.svg'
import classNames from 'classnames';

//tag manager
// import TagManager from 'react-gtm-module';

var moment = require('moment');
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var moment = require('moment')

const TableCheck = () => {

  let empresa = JSON.parse(localStorage.getItem('empresa'));
  const [cartera, setCartera] = useState();
  const [tempCartera, setTempCartera] = useState();
  const [allSelected, setAllSelected] = useState();
  const [selection, setSelection] = useState([]);
  const [view, setView] = useState(false)
  const [fechaInicio, setFechaInicio] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [loader, setLoader] = useState();
  const [alert, setAlert] = useState();
  const [sucursales, setSucursales] = useState();
  const [sucursal, setSucursal] = useState();
  const [notasCredito, setNotasCredito] = useState();
  const [table, setTable] = useState(true);
  const [documentosCart, setDocumentosCart] = useState();

  const selectable = (item) => {
    return item.documentoFiscal != null;
  }

  const allSelectableItems = () => {

    return cartera.filter(x => selectable(x));

  }

  const isSelected = (item) => {
    return ((selection).includes(item));
  }


  const rowClassName = (item) => {
    return { 'ui-state-highlight': isSelected(item) };
  }

  const onRowClick = (e) => {
    const item = e.data;
    if (selectable(item)) {
      toggleSelection(item);
    }
  }

  const toggleSelection = (item) => {
    let select;
    let nc = []
    if (item.notaCredito.length > 0) {
      item.notaCredito.forEach(element => {
        nc.push({
          'tax_document': element.NumFiscal,
          'document': element.documento,
          'balance': element.saldo
        })
      });
    }
    if (isSelected(item)) {
      select = selection.filter(x => x !== item);
      // TagManager.dataLayer({
      //   dataLayer:{
      //     event:'removeInvoiceFromPayment',
      //     ecommerce:{
      //       remove:{
      //         invoices:[{
      //           'codeClient':item.codCliente,
      //           'taxDocument':item.documentoFiscal,
      //           'sapDocument':item.documentoSAP,
      //           'blackberry':item.mora,
      //           'balance':item.saldo,
      //           'reference':item.referencia,
      //           'businessName':item.Razonsocial,
      //         }],
      //         credit_note:nc
      //       }
      //     }          
      //   }
      // })
    } else {
      select = [...selection, item];
      // TagManager.dataLayer({
      //   dataLayer:{
      //     event:'addInvoiceToPayment',
      //     ecommerce:{
      //       currencyCode:'COP',
      //       add:{
      //         invoices:[{
      //           'codeClient':item.codCliente,
      //           'taxDocument':item.documentoFiscal,
      //           'sapDocument':item.documentoSAP,
      //           'blackberry':item.mora,
      //           'balance':item.saldo,
      //           'reference':item.referencia,
      //           'businessName':item.Razonsocial
      //         }],
      //         'creditNote':nc
      //       }
      //     }
      //   }
      // })
    }
    const allSelect = select.length === allSelectableItems().length;
    setSelection(select)
    setAllSelected(allSelect)
  }

  const toggleAll = (allSelect) => {
    const select = allSelect ? allSelectableItems() : [];
    setAllSelected(allSelect)
    setSelection(select)
  }

  /*   const displaySelection = (data) => {
      if(!data || data.length === 0) {
          return <div style={{textAlign: 'left'}}>No Selection</div>;
      }   
      else {
        return (
         <FooterTable data={data} selection={selection} handleView={setView.bind(this)}></FooterTable>
        )       
      }
    } */

  const handleView = (notasCredito) => {
    if (notasCredito.length > 0) {
      setAlert({
        tipo: "info",
        titulo: "Notas credito",
        mensaje: "Esta factura tiene notas credito asociadas",
        btnOk: "Aceptar",
        fnOk: function () {
          setNotasCredito(notasCredito)
          if (notasCredito)
            document.querySelector(".modal-detalle-ncredito").classList.toggle("show-modal")
        },
      })
    } else {
      setAlert({
        tipo: "error",
        titulo: "Notas credito",
        mensaje: "La factura seleccionada no contiene notas credito.",
        btnOk: "Aceptar",
        fnOk: function () {
        },
      })
    }
  }

  const startOfMonth = moment().startOf('month').format('YYYY/MM/DD');
  const endOfMonth = moment().endOf('month').format('YYYY/MM/DD');

  const loadCartera = async () => {
    setLoader(true)
    let codigo = Auth.getCodCliente()
    let url = Constants.WSCARTERACLIENTE + "?origen=" + (empresa ? empresa.codigo_empresa : '') + "&cliente=" + codigo + "&fechaInicial=" + startOfMonth + "&fechaFinal=" + endOfMonth + "&cadena=" + '' + "&clase=" + '' + "&busqueda=" + '';
    axios.get(url).then(res => {
      setTempCartera(res.data)
      setCartera(res.data)
      setLoader(false)
    });
  }


  const newLoadCartera = (data) => {
    setLoader(true)
    let url = data
    console.log(url);
    axios.get(url).then(res => {
      console.log(res.data);
      setTempCartera(res.data)
      setCartera(res.data)
      setLoader(false)
    });
  }


  const handleSearch = (search) => {
    console.log(cartera);
    if (cartera) {
      if (search.length == 0) {
        setTempCartera(cartera)
      }
      else {
        let array = cartera.filter(x =>
          ((x.documentoFiscal).toUpperCase()).includes(search.toUpperCase()) ||
          ((x.documentoSAP).toUpperCase()).includes(search.toUpperCase()) ||
          ((x.Razonsocial).toUpperCase()).includes(search.toUpperCase()) ||
          ((x.documentoFiscal).toUpperCase()).includes(search.toUpperCase()) ||
          (String(x.codCliente).includes(String(search))) ||
          (String(x.referencia).includes(String(search))) ||
          ((x.Nombre).toUpperCase()).includes(search.toUpperCase()) ||
          (String(x.ClaseDocumento).includes(String(search))),

        )
        handleValidate(array)
      }
    }
  }

  const handleSearchDate = () => {
    let codigo = Auth.getCodCliente()
    let fI = moment(fechaInicio)
    let fF = moment(fechaFin)
    let dif = fF.diff(fI)
    return getCartera(dif, fI, fF, codigo)
  }

  const getCartera = (data, fI, fF, codigo) => {

    if (data > 0) {
      let array = cartera.filter(function (item) {
        return (moment(item["fecha"]).format("YYYY-MM-DD") >= fI.format("YYYY-MM-DD") && moment(item["fecha"]).format("YYYY-MM-DD") <= fF.format("YYYY-MM-DD"))
      })
      let url = Constants.WSCARTERACLIENTE + "?origen=" + (empresa ? empresa.codigo_empresa : '') + "&cliente=" + codigo + "&fechaInicial=" + fI.format("YYYY/MM/DD") + "&fechaFinal=" + fF.format("YYYY/MM/DD") + "&cadena=" + '' + "&clase=" + '' + "&busqueda=" + '';

      localStorage.setItem("url", url)

      newLoadCartera(url)
      // handleValidate(array)

    } else {
      setAlert({
        tipo: "error",
        titulo: "Rango erroneo",
        mensaje: "La fecha final debe ser mayor a la incial",
        btnOk: "Aceptar",
        fnOk: function () {
        },
      })
    }

  }

  const handleValidate = (array) => {
    if (array.length == 0)
      setTempCartera({})
    if (array.length > 0)
      setTempCartera(array)
  }

  const handleSearchCadena = (search) => {
    console.log(cartera);
    if (cartera) {
      if (search != "") {
        let array = cartera.filter(function (item) {
          return (item["codCliente"] == search)
        })
        handleValidate(array)
      } else {
        setSucursal(null)
        setTempCartera(cartera)
      }
    }
  }

  const handleSearchDocumento = (search) => {
    if (search == '-1') {
      setTempCartera(cartera)
    } else {
      if (cartera && search) {
        let array = cartera.filter(function (item) {
          return (item['ClaseDocumento'] == search)
        })
        handleValidate(array)
      }
    }
  }

  const handleSearchEstado = (search) => {
    if(search == "2"){
      setTempCartera(cartera)
    }else if (search == "1"){
      let array = cartera.filter(function (item) {
        return (item['mora'] < 0)
      })
      handleValidate(array)
    }else if(search == "-1"){
      let array = cartera.filter(function (item) {
        return (item['mora'] > 0)
      })
      handleValidate(array)
    }
  }

  const handleSucursales = () => {
    axios.post(Constants.WSSUCURSALES + Auth.getCodCliente()).then((response) => {
      setSucursales(response.data)
    })
  }

  const handleDocumentosCartera = () => {
    axios.get(Constants.WSDOCUMENTOSCARTERA).then((response) => {
      console.log(response);
      setDocumentosCart(response.data)
    })
  }

  const handleLimpiar = () => {
    loadCartera()
  }

  useEffect(() => {
    if (fechaInicio && ((new Date(fechaFin).getDate()) != null))
      handleSearchDate()
    let codigo = Auth.getCodCliente()
    let fF = moment().format('YYYY/MM/DD');
    let url = Constants.WSCARTERACLIENTE + "?origen=" + (empresa ? empresa.codigo_empresa : '') + "&cliente=" + codigo + "&fechaInicial=2000/01/01" + "&fechaFinal=" + fF + "&cadena=" + '' + "&clase=" + '' + "&busqueda=" + '';
    localStorage.setItem("url", url)
    newLoadCartera(url)
  }, [fechaInicio && fechaFin])

  const handleDocumento = (item) => {
    let descripcion = documentosCart.filter(x => x.codigo == item)
    if (descripcion.length > 0) {
      descripcion = descripcion[0].descripcion
      return item + " - " + descripcion
    }
    return item + " - Sin registro"
  }

  //metodo para cambiar los estilos, se envia el estilo usando classNames

  const stockBodyTemplate = (rowData) => {
    const stockClassName = classNames({
      'lowstock': rowData.mora > 0
    });

    return (
      <div className={stockClassName}>
        {rowData.mora}
      </div>
    );
  }

  useEffect(() => {
    handleDocumentosCartera()
    loadCartera();
    rowClassName.bind(this)
    onRowClick.bind(this)
    toggleAll.bind(this)
    handleSucursales()
  }, [])

  return (
    <React.Fragment>
      <div className="export">
        <ExcelFile
          element={<button><img src={excel} className="img-excel"></img>Exportar Excel</button>}
          name="Cartera"
          filename={"cartera " + moment(new Date()).format("DD/MM/YYY")}
        >
          <ExcelSheet data={tempCartera ? tempCartera : (cartera ? cartera : [])} name="Cartera" filename={"cartera " + moment(new Date()).format("DD/MM/YYY")}>
            <ExcelColumn label="Referencia" value="referencia" />
            <ExcelColumn label="Código Cliente" value="codCliente" />
            <ExcelColumn label="Nombre Cliente" value="Nombre" />
            <ExcelColumn label="Clase Documento" value="ClaseDocumento" />
            <ExcelColumn label="Documento SAP" value="documentoSAP" />
            <ExcelColumn label="Documento Fiscal" value="documentoFiscal" />
            <ExcelColumn label="fecha" value="fecha" />
            <ExcelColumn label="Estado" value={(col) => col.mora > 0 ? "Vencido" : "Corriente"} />
            <ExcelColumn label="Mora" value="mora" />
            <ExcelColumn label="Saldo" value="saldo" />
          </ExcelSheet>
        </ExcelFile>
      </div><br /><br /><br /><br />
      {table ? (
        <React.Fragment>

          <div id="wrapper">
            <form>
             
              <div className="col-4">
                <label>Desde:
                  <input type="date" onChange={e => setFechaInicio(e.target.value)} />
                </label>
              </div>
              <div className="col-4">
                <label>Hasta:
                  <input type="date" onChange={e => setFechaFin(e.target.value)} />
                </label>
              </div>
              <div className="col-4">
                <label>Buscar
                  <input type="search" placeholder="Buscar" onChange={e => handleSearch(e.target.value)} className="p-inputtext p-component" />
                </label>
              </div>
              <div className="col-4">
                <div style={{ textAlign: "center", position: "relative" }}>
                  <button style={{ height: "35px", width: "110px", marginBottom:"-1px" }} className="outline" onClick={() => handleLimpiar()}>Limpiar filtro</button>
                </div>

                <div style={{ textAlign: "center", position: "relative" }}>
                  <button style={{ height: "35px", width: "110px", marginBottom:"5px" }} className="outline" type="button" onClick={() => handleSearchDate()}>buscar</button>
                </div>

              </div>
              <div className="col-3" >
                          <label>Clase documento:
                            {documentosCart ? 
                              <React.Fragment>
                                  <select onChange={e=>handleSearchDocumento(e.target.value)}>
                                      <option value="-1">Todos los documentos</option>
                                      {documentosCart.map((item,i)=>(
                                        <option key={i} value={item.codigo} >{item.codigo + " - " + item.descripcion}</option>
                                      ))}
                                  </select>
                              </React.Fragment>  
                            : "No tiene tipo de documento para filtrar" } 
                          </label>                    
                      </div>
              <div className='col-3'>
                <label>Estado :
                  <select onChange={e => handleSearchEstado(e.target.value)}>
                    <option value="2">Todos</option>
                    <option value="1">Corriente</option>
                    <option value="-1">Vencido</option>
                  </select>
                </label>
              </div>
              <div className="col-3">
                <label>Cadena:
                  {sucursales ?
                    <React.Fragment>
                      <select onChange={e => handleSearchCadena(e.target.value)}>
                        <option value="">Todas las cadenas</option>
                        {sucursales.map((item, i) => (
                          <option key={i} value={item.Codigo} >{item.Nombre + " - " + item.Codigo}</option>
                        ))}
                      </select>
                    </React.Fragment>
                    : "No tiene cadenas para filtrar"}
                </label>
              </div>
            </form>
          </div>
          <React.Fragment>
            {loader ? <Progress color={"007daf"}></Progress> :
              <React.Fragment>
                <div className="datatable-style-demo">
                  {cartera ? (
                    <DataTable
                      value={!view ? tempCartera : []}
                      onRowClick={e => onRowClick(e)}
                      /* footer={displaySelection(selection)} */
                      paginator resizableColumns rows={!view ? 10 : 0}
                      autoLayout={!view ? true : false}
                    >
                      <Column
                        sortable={false}
                        header={!view ?
                          <Checkbox
                            onChange={e => toggleAll(e.checked)}
                            checked={allSelected}
                          />
                          : null}
                        body={!view ? ((rowData, column) => (
                          selectable(rowData) ?
                            <Checkbox onChange={e => { }} checked={isSelected(rowData)} />
                            : ''
                        )) : null}
                      />
                      <Column field="referencia" header="REFERENCIA" ></Column>
                      <Column field="codCliente" header="CÓDIGO CLIENTE" ></Column>
                      <Column field="Nombre" header="NOMBRE CLIENTE"></Column>
                      <Column field="ClaseDocumento" header="Clase" body={(rowData, column) => (
                        handleDocumento(rowData['ClaseDocumento'])
                      )}></Column>
                      <Column field="documentoSAP" header="DOC SAP"></Column>
                      <Column field="documentoFiscal" header="Num. Fiscal" ></Column>
                      <Column field="fecha" header="FECHA"></Column>
                      <Column header="ESTADO" body={(rowData, column) =>
                        rowData['mora'] > 0 ? "Vencido" : "Corriente"
                      }></Column>
                      {/* stockBodyTemplate se crea para enviar el parametro a el metodo  */}
                      <Column field="mora" header="mora(EN DÍAS)" body={stockBodyTemplate}>
                      </Column>
                      <Column className="warning" field="saldo" header="saldo" body={(rowData, column) =>
                        Constants.formatNumber(rowData['saldo'])
                      }></Column>
                      <Column onEditorCancel={true} header="NOTA CRÉDITOS" style={{ textAlign: "center" }} body={(rowData, column) => (
                        (rowData['notaCredito']).length > 0 ? <a onClick={() => handleView(rowData['notaCredito'])} src={visibility}>Nota credito</a> : "Sin nota crédito"
                      )}></Column>
                    </DataTable>
                  ) : (
                    null
                  )}
                </div>
              </React.Fragment>
            }
          </React.Fragment>
        </React.Fragment>
      ) : null}

      {selection.length > 0 ? <FooterTable data={selection} handleView={setView.bind(this)} handleTable={setTable.bind(this)}></FooterTable> : null}
      {alert ? <AlertModal alerta={alert} updateAlerta={setAlert} ></AlertModal> : null}
      <NotasCreditoModal notasCredito={notasCredito}></NotasCreditoModal>
    </React.Fragment>
  )

}

export default TableCheck;
