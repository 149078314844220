import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';
import {connect} from "react-redux";
import * as Constants from '../../constants/global';
import ActualizarUsuario from '../../store/usuario/action';
import axios from 'axios';
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';


const LoginModal = (props) => {

    const [clave, setClave] = useState();

    const handleClose = () => {
        document.querySelector(".modal-login").classList.remove("show-modal");
    }

    const handleLogin = () => {
        let codigo =  localStorage.getItem("codigoCa")
        let url = Constants.WSLOGINCLIENTE;
        Auth.setEmpresa()
        if (clave != ""){
            let data = {
                "Codigo": props.nit,
                "Clave": clave.trim(),
            };    
            console.log(data);
            axios.post(url, data)
            .then(res => {
                if(res.data.Login === "1") {    
                    if(props.empresa[0].sucursales.length > 0){
                        props.ActualizarUsuario(res.data.cliente);
                        let dataEmpresa = {
                            nit: props.nit,
                            empresas: props.empresa
                        }
                        console.log(dataEmpresa);
                        Auth.setEmpresa(JSON.stringify(dataEmpresa)) 

                        Auth.setSucursal(null)
                        if(res.data.hasOwnProperty("codCliente")){
                            Auth.setCodCliente(res.data.codCliente)
                            Auth.setIsChain(res.data.cadena);
                            if(codigo != null){
                                recuperarCarrito()
                            }else{
                                handleClose();
                                props.history.push('/'); 
                            }                           
                        }else{
                            alert("Error al iniciar sesión. intentelo mas tarde")
                        }                    
                    } else {
                        alert("No existen sucursales")
                    }
                } else {
                    alert("No se encontraron coincidencias con el código SAP ingresado. Recuerda que tu código SAP lo podrás encontrar en la factura impresa.")
                }
            });
        } else {
            alert("Debe ingresar la clave");
        }

         //modulo de recuperacion de carritos abandonados

     const recuperarCarrito = () => {
        let codigo =  localStorage.getItem("codigoCa")
        
        let url = Constants.WSRECUPERARCARRITOABANDONADO + codigo;
        axios.get(url)
        .then(res => {
            let data = res.data
            
            data.map((info =>{
                Auth.setCarrito(info);
                props.ActualizarCarrito(Auth.getCarrito());
            }))
        });
        
        props.history.push('/carrito');  
    }

    }
    return (
        <React.Fragment>
            <div className="modal alert modal-login">
                <div className={"modal-content info"}>       
                    <div className="content">
                        <div className="alert-icon">
                        </div>
                        <div className="alert-content">
                            <p style={{className:"miFuente"}} className="alert-title">Verificación de acceso</p>
                            <p style={{className:"miFuente"}} className="alert-message">Digite el código enviado a los correos electronicos: </p>
                            { Object.keys(props.correoCodigo).map((index) => (
                                <div  style={{className:"miFuente"}} className="centerAlign" key={index}>
                                    <p style={{className:"miFuente"}}><b>{index}: </b>{props.correoCodigo[index]}{/*  <Rigth className="svg-una-capas" style={{width:"10px", height:"10px"}}/> */}</p>
                                </div>
                            ))}
                            <div className="codigo">
                                <input 
                                    style={{className:"miFuente"}}
                                    type="password"
                                    placeholder="Código"
                                    id="password"
                                    onChange={e => setClave(e.target.value)}
                                    value={clave}>
                                </input>
                            </div>
                        </div>
                        <div className="alert-buttons">
                            <button style={{className:"miFuente"}} type="submit" className="btn-large" onClick={() => handleLogin()}>Ingresar</button>
                            <button style={{className:"miFuente"}} type="submit" className="outline" onClick={() => handleClose()}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        
        
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarUsuario,ActualizarCarrito}) (LoginModal));
