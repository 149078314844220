import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Progress from '../util/Progress';
import * as Constants from '../../constants/global';

import {ReactComponent as Rigth} from '../../assets/icon/rigth.svg';
import axios from 'axios';

const SolicitudActivacionModal = (props) => {

    const [errorCorreo, setErrorCorreo] = React.useState("");
    const [loader, setLoader] = React.useState(false);

    const handleClose = () => {
        document.querySelector(".modal-activacion").classList.remove("show-modal");
        document.location.reload()
    }

    const handleEnviarCodigo = (data) => {
        console.log(data);
        setLoader(true)
        let url =  Constants.WSENVIARCODIGO;
        axios.post(url, data)
        .then(res => {
            
            if(res.data.Estado === "OK"){
                alert(res.data.Messaje);

                document.querySelector(".modal-activacion").classList.remove("show-modal");
                document.querySelector(".modal-codigoactivacion").classList.toggle("show-modal");
            } else {
                setLoader(false)
                if(res.data.Messaje){
                    alert(res.data.Messaje);
                }else{
                    alert("Error inesperado, por favor intenta mas tarde.")
                }
            }
        });
    }

    /* const handleTelefono = (item) => {
        let data = {
            "Codigo": props.validacion.Codigo,
            "Telefono": item,
        };
        
        if(window.confirm("¿Desea desea enviar el Mensaje a este número *" + item.substring(item.length - 4, item.length) + "?")){
            handleEnviarCodigo(data)
        }

    } */

    const handleCorreo = () => {
        /* var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(! item.match(mailformat)) {
            setErrorCorreo("El correo ingresado no es válido")
        } else { */
           
        let data = {
            "Codigo": props.validacion.Codigo,
            "EmailCompras": props.correos.correoCompras,
            "EmailPagos": props.correos.correoPagos
        };
        handleEnviarCodigo(data)
       // }
    }

    useEffect(()=>{

    },[])

    return (

        <div className="modal alert modal-activacion">
            <div className={"modal-content info"}>       
                <div className="content">
                    { loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>
                            <div className="alert-icon">                        
                            </div>
                            <div style={{className:"miFuente"}} className="alert-content">
                                <p className="alert-title">Activación de usuario</p>
                                <p className="alert-message"><b>Para usar nuestros servicios, debes activarte...</b></p>
                                <div className="codigo">
                                    {/* <p style={{fontSize:"13px", textAlign:"center"}}>Te enviaremos un SMS para activar tu cuenta</p> */}
                                    <hr></hr>
                                    <p style={{fontSize:"13px", textAlign:"center"}} className=
                                    "miFuente">Enviaremos un código de activación a los siguientes correos: </p>
                                    { Object.keys(props.correos).map((index) => (
                                        <div style={{fontSize:"13px", textAlign:"center"}} className="item borderline" key={index}>
                                            <p style={{fontWeight:"700",textAlign:"center"}}className="centerAlign"><b>{props.correos[index]}</b>{/*  <Rigth className="svg-una-capas" style={{width:"10px", height:"10px"}}/> */}</p>
                                        </div>
                                    ))}
                                    { errorCorreo.length > 0 ? (
                                        <span className="error">
                                            <p>{errorCorreo}</p>
                                        </span>
                                    ) : null }
                                </div>
                            </div>
                            <div className="alert-buttons">
                                <button type="submit" className="btn-large" onClick={() => handleCorreo()}>Enviar código</button>
                                <button type="button" className="outline" onClick={() => handleClose()}>Cancelar</button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {/* <div className="footer">
                <button type="submit" className="btn-large" onClick={() => handleCorreo()}>Enviar Código</button>
            </div> */}
        </div>
    )
}

export default withRouter(SolicitudActivacionModal);