import React, { useState, useRef} from 'react'
import IdleTimer from 'react-idle-timer'
import { BrowserRouter, Route, Redirect , Switch} from 'react-router-dom';
import Auth from '../helpers/Auth';


function IdleTimerContainer () {

    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const IdleTimerRef = useRef(null)
    const onIdle = () => {
       
        Auth.signout()
        window.location.reload(true)
    }

    return (
        <div>
        <IdleTimer ref = {IdleTimerRef} timeout={90 * 20000} onIdle={onIdle}></IdleTimer>
        </div>
        )
}

export default IdleTimerContainer