import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios'; 
import Help from '../../assets/img/help.png';
import * as Constants from '../../constants/global';
import agregar_carrito from '../../assets/imgAyuda/agregar_carrito.png';
import cambiar_correo from '../../assets/imgAyuda/cambiar_correo.png';
import cambiar_sucursal from '../../assets/imgAyuda/cambiar_sucursal.png';
import cancelar_modficiar_ped from '../../assets/imgAyuda/cancelar_modficiar_ped.png';
import como_comprar from '../../assets/imgAyuda/como_comprar.png';
import envios_entregas from '../../assets/imgAyuda/envios_entregas.png';
import historial_pag from '../../assets/imgAyuda/historial_pag.png';
import metodos_de_pago from '../../assets/imgAyuda/metodos_de_pago.png';
import numero_spa from '../../assets/imgAyuda/numero_spa.png';
import serv_cliente from '../../assets/imgAyuda/serv_cliente.png';
import sucursal_pedido from '../../assets/imgAyuda/sucursal_pedido.png';
import tiendas_envio from '../../assets/imgAyuda/tiendas_envio.png';
import logoExcel from '../../assets/imgAyuda/logoExcelPrincipal.png'
import { Card } from '../util/Card';
import ReactGa from 'react-ga'


const Ayuda = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [nombreVendedor, setNombreVendedor] = useState();
    const [telefonoVendedor, setTelefonoVendedor] = useState();
    const [telefonoServicioCliente, setTelefonoServicioCliente] = useState();

    //se crea arreglo para llenar las card dinamicamente
    const dataCard = [
        {
            id: "card1",
            img: como_comprar,
            title: "¿Cómo Comprar?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card2",
            img: agregar_carrito,
            title: "¿Cómo agregar al carrito?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card13",
            img: logoExcel,
            title: "¿Cómo agregar al carrito desde Excel? ",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card3",
            img: cancelar_modficiar_ped,
            title: "¿Cómo cancelar o modificar mi pedido?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card4",
            img: metodos_de_pago,
            title: "Métodos de pago",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card5",
            img: envios_entregas,
            title: "Costo de envio y entregas",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card6",
            img: numero_spa,
            title: "¿Cúal es mi número de SAP?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card7",
            img: cambiar_sucursal,
            title: "¿Cómo cambiar de sucursal?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card8",
            img: sucursal_pedido,
            title: "¿Cómo saber a que sucursal estoy haciendo mi pedido?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card9",
            img: tiendas_envio,
            title: "¿Cómo hago un pedido para todas mis tiendas al mismo tiempo?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card10",
            img: historial_pag,
            title: "¿Cómo buscar historial de pagos/facturas/pedido?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card11",
            img: cambiar_correo,
            title: "¿Cómo cambiar mi correo?",
            description: "",
            width: "100px",
            heigth: "100px"
        },
        {
            id: "card12",
            img: serv_cliente,
            title: "¿Contacta nuestro servicio al cliente?",
            description: "",
            width: "100px",
            heigth: "100px"
        }
        
    ];


    const loadInfoAyuda = async () => {

        /* setNombreVendedor(empresa.sucursal.NombreVendedor ? empresa.sucursal.NombreVendedor : "")
        setTelefonoVendedor(empresa.sucursal.TelefonoVendedor ? empresa.sucursal.TelefonoVendedor : "")
        setTelefonoServicioCliente(empresa.sucursal.TelefonoServicioAlCliente ? empresa.sucursal.TelefonoServicioAlCliente : "") */

    }

    useEffect(() => {
        loadInfoAyuda();
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

    // VISTA
    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={9} />
            </div>

            <div className="main" style={{ paddingTop: "50px" }}>

                <div className='ayuda-list'>
                    <h1>Mi Guía de Ayuda</h1>
                    <div className="help-card">
                        {dataCard.map((item) => (
                            <Card item={item} />
                        ))}
                    </div>

                </div>
            </div>

            <Tabbar item={9} />

        </React.Fragment>
    )
}

export default Ayuda;