import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import noimage from '../../assets/img/generico.png';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';
import Trash from '../../assets/img/trash.png';
import error from '../../assets/icon/error.svg';
import Navbar from '../navbar/Navbar';
import AlertModal from '../modal/AlertModal';
import Tabbar from '../navbar/Tabbar';
import ActualizarUsuario from '../../store/usuario/action';
import ActualizarCarrito from '../../store/carrito/action';
import Lista from './Lista';
import Auth from '../../helpers/Auth';
// import TagManager from 'react-gtm-module';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer'
import ReactGa from 'react-ga'


const Sugerido = (props) => {

    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [maximoPedido, setMaximoPedido] = useState(false);
    const [descuento_total, setDescuento_total] = useState(0);
    const [alerta, setAlerta] = useState();
    const [cupoDisponible, setCupoDisponible] = useState();
    const [observacion, setObservacion] = useState();
    const [empresa, setEmpresa] = useState();
    let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()

    const loadHistorial = async () => {
        let url =   Constants.WSHISTORIAL +
                    "?codigo=" +( empresa ?  empresa.codigo_empresa : '' )  + 
                    "&cliente=" + codigo ;
        console.log(url);
        
        axios.get(url)
        .then(res => {
            if(res.data.length >= empresa.max_pedidos){
                setMaximoPedido(true);
            }else{
                setMaximoPedido(false);
            }
        });
    }


    const handleMenos = (codigo, item)  => {
        let sugerido = props.Sugerido || {}
        if(sugerido[codigo]){
            if(sugerido[codigo]["cantidad"] == 1){
                delete sugerido[codigo];
            }else{
                sugerido[codigo]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(sugerido);
        props.ActualizarCarrito(Auth.getCarrito());
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'removeFromCart',
        //         ecommerce:{
        //             remove:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //     }
        // })
    }

    const handleMas = (codigo, item) => {    
        let carrito = props.Sugerido
        if(carrito[codigo]){
            carrito[codigo]["cantidad"] += 1;
        }else{
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'addToCart',
        //         ecommerce:{
        //             currencyCode:'COP',
        //             add:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //     }
        // })
    }

    const handleChangeCantidad = (codigo, item, valor) => {
        let sugerido = props.Sugerido || {}
        if(valor <= 0){
            delete sugerido[codigo];
        }else{
            if(sugerido[codigo]){
                sugerido[codigo]["cantidad"] = parseInt(valor);
            }else{
                sugerido[codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }

        Auth.setCarrito(sugerido);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleEliminar = (codigo, item) => {  
        let sugerido = props.Sugerido || {}
        let cantidad = sugerido[codigo] .cantidad
        if(sugerido[codigo]){
            delete sugerido[codigo];
        }  
        Auth.setCarrito(sugerido);
        props.ActualizarCarrito(Auth.getCarrito());
        // TagManager.dataLayer({
        //     dataLayer:{
        //         event:'removeFromCart',
        //         ecommerce:{
        //             remove:{
        //                 products:[{
        //                     'name':item.nombre,
        //                     'id':item.codigo,
        //                     'price':item.precio,
        //                     'category':item.categoria,
        //                     'quantity': cantidad
        //                 }]
        //             }
        //         }
        //     }
        // })
    }


    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[]) 

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={2} />
            </div>
            <Tabbar item={2}/>
            <Lista />
            <div className="pedido-list sugerido">
                {props.carrito ? (
                    <div className="product-list">
                        {Object.keys(props.carrito).map((item, i) => (
                            <div className="item item-carrito" key={i}>
                                <img width="120" className="img-product"
                                    src={process.env.REACT_APP_URL_IMAGE + item.toString().trim() + '_1.png'}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                </img>
                                <div className="start"> 
                                    
                                    <p className="codigo"><b>Código: </b>{item}</p>
                                    <p className="nombre"><b>{props.carrito[item].nombre}</b></p>
                                    <p className="precio">
                                        {Constants.formatNumber(props.carrito[item].precio)}
                                        </p>
                                    <p className="regular">
                                        Total:  
                                         {Constants.formatNumber(props.carrito[item].precio * props.carrito[item].cantidad)} 
                                    </p>
                                </div>
                                <div className="end">
                                    <div className="action">
                                        <div className="controls">
                                            <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                            <input type="number" 
                                                    onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                    value={props.carrito[item].cantidad} />
                                            <button role="button" onClick={() => handleMas(item, props.carrito[item])}>
                                                <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                        </div>
                                        <a style={{cursor:"pointer"}} onClick={() => handleEliminar(item, props.carrito[item])}>
                                            <img src={Trash} width="20px" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    )
}


export default Sugerido;