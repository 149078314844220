import React, { useEffect } from 'react';
import { BrowserRouter, Route, Redirect , Switch} from 'react-router-dom';
import {connect} from "react-redux";
import { AppProvider } from './context/AppState';
import ReactGa from 'react-ga'




// COMPONENTS
import Login from './components/auth/Login';
import Home from './components/home/Home';
import Pedido from './components/pedido/Pedido';
import Sugerido from './components/pedido/Sugerido';
import Catalogo from './components/pedido/Catalogo';
import Carrito from './components/pedido/Carrito';
import Excel from './components/pedido/Excel';
import HistorialPagos from './components/pages/HistorialPagos';
import UltimosPedidos from './components/pages/UltimosPedidos';
import Cartera from './components/pages/Cartera';
import Contactenos from './components/pages/Contactenos';
import Ayuda from './components/pages/Ayuda';
import PaymentResponse from './components/payment/PaymentResponse';

import Auth from './helpers/Auth';

//REDUX
import ActualizarUsuario from './store/usuario/action';
import ActualizarCarrito from './store/carrito/action';

import * as Constants from './constants/global';

// SASS

import './styles/app.scss'

// LOGOUT POR INACTIVIDAD
import IdleTimerContainer from './components/TimerContainer'

// export const cambiarTema = (empresa) => {
//   if (empresa){
//     if (empresa.num_empresa == Constants.num_empresa_ramo) {
//       import ('./styles/appRamo.scss').then((Appcss) => {
//       });
//     } else {
//       import ('./styles/app.scss').then((Appcss) => {
//       });
//     }
//   } else {
//     import ('./styles/app.scss').then((Appcss) => {
//     });
//   }
// }

// COMPONENTES


const App = (props) => {

 
  const getUser = async () => {
   
  }

  useEffect(() => {
    // resetTimer()
    ReactGa.initialize('UA-180947181-1')
    ReactGa.pageview(window.location.pathname + window.location.search)
    window.onpopstate = function (e) {window.history.forward (1); }
    if(!Auth.getCodCliente()){
      Auth.signout();
    }
    if(Auth.getCarrito()){
      props.ActualizarCarrito(Auth.getCarrito());
    }
  }, [])

  const NotFoundRedirect = () => <Redirect to='/' />


    return (<React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Catalogo} />
          <PrivateRoute path="/respuesta/pago" component={PaymentResponse} />
          <PrivateRoute path="/pedido" component={Pedido} />
          <PrivateRoute path="/sugerido" component={Sugerido} />
          <PrivateRoute path="/catalogo" component={Catalogo} />
          <PrivateRoute path="/excel" component={Excel} />
          <PrivateRoute path="/carrito" component={Carrito} />
          <PrivateRoute path="/historial/pagos" component={HistorialPagos} />
          <PrivateRoute path="/ultimosPedidos" component={UltimosPedidos} />
          <PrivateRoute path="/cartera" component={Cartera} />
          <PrivateRoute path="/contactenos" component={Contactenos} />
          <PrivateRoute path="/ayuda" component={Ayuda} />
          <Route component={NotFoundRedirect} />
        </Switch>
       
      </BrowserRouter>
      <IdleTimerContainer></IdleTimerContainer>

    </React.Fragment>)
}

// RUTAS PRIVADAS
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.getCodCliente()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)


export default connect(null, {ActualizarUsuario,ActualizarCarrito}) (App);