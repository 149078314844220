// import React, { useState, useEffect } from 'react';
import React, {useEffect } from 'react';

// IMAGENES
import success from '../../assets/icon/success.svg';
import info from '../../assets/icon/info.svg';
import warning from '../../assets/icon/warning.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/generico.png';


const AlertModal = (props) => {
    
    const getIcon = () => {
       
        if(props.alerta.tipo === 'success'){
            return success;
        }
        if(props.alerta.tipo === 'info'){
            return info;
        }
        if(props.alerta.tipo === 'warning'){
            return warning;
        }
        if(props.alerta.tipo === 'error'){
            return error;
        }
    }

    const hanldeClose = () => {
        props.updateAlerta(null);
        document.querySelector(".alert").classList.remove("show-modal");
    }

    useEffect(() => {        
        if(props.alerta){
            document.querySelector(".alert").classList.toggle("show-modal");
        }
    }, [props.alerta]);

    return (
        <div className="modal alert">
            <div className={"modal-content " + props.alerta.tipo}>       
                <div className="content">
                    <div className="alert-icon">
                        { props.alerta.icon ? (
                            <img 
                                src={ props.alerta.icon ? props.alerta.icon : getIcon()}  
                                alt="icon error"
                                onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}/>
                        ) : (
                            <img 
                                src={ props.alerta.icon ? props.alerta.icon : getIcon()} 
                                width="80" 
                                height="80" 
                                alt="icon error"/>
                        ) }
                    </div>
                    <div className="alert-content">
                        <p className="alert-title">{props.alerta.titulo}</p>
                        <p className="alert-message">{props.alerta.mensaje}</p>
                    </div>
                    <div className="alert-buttons">
                        <button type="submit" className="btn-large" onClick={() => (hanldeClose(), props.alerta.fnOk())}>{props.alerta.btnOk}</button>
                        { props.alerta.fnCancel ? (
                            <button type="submit" className="btn-large outline" onClick={() => (hanldeClose(),props.alerta.fnCancel())}>{props.alerta.btnCancel}</button>
                        ) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertModal;