import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import TableCheck from '../util/TableCheck'
import ReactGa from 'react-ga'
import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';

const Cartera = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [cupo, setCupo] = useState();
    const [cliente, setCliente] = useState();

    const loadCupo = async() =>{
        let url = Constants.WSCUPOCLIENTE + '?codigo=0&codCliente=' + Auth.getCodCliente()
        axios.get(url).then((response)=>{
            setCupo(response.data[0])
        })
    }

    const handleInfoClient = () =>{
        axios.get(Constants.WSINFOCLIENT + Auth.getCodCliente()).then((response)=>{
            setCliente(response.data[0])
        })
    }

    useEffect(() => {
        loadCupo();
        handleInfoClient()
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[])

    return (
        <React.Fragment>           
            <div className="navbar principal">
                <Navbar item={6} />
            </div>            
            <div className="cartera">
                <div className="card">
                    <div className="pedido-list ">
                        <p className="tamaño" style={{color:"#555555"}}><b>Estado de cuenta</b></p><br/><br/> 
                        <div className="wrapper">
                            <div className="item1">
                                <div className="seccion-ayuda">
                                    <div className="start">
                                        <p><b>Nombre: </b>{cliente ? Constants.stringToLowerCase(cliente.Nombre) : ""}</p>
                                        <p><b>Razón social: </b>{cliente ? Constants.stringToLowerCase(cliente.RazonSocial) : ""}</p>
                                        <p><b>Código: </b>{cliente ? cliente.Codigo : ""}</p>
                                    </div> 
                                </div>
                            </div>
                            <div className="item2">
                                <div className="seccion-ayuda">
                                    <div className="start">
                                        <p className="success"><b>Cupo: </b> 
                                            {Constants.formatNumber(cupo ? cupo.Cupo : 0)}
                                        </p>
                                        <p className="warning"><b>Cupo usado: </b> 
                                            {Constants.formatNumber(cupo ? cupo.saldo : 0)}
                                        </p>                                        
                                        <p className={cupo ? ( cupo.Cupo - cupo.saldo > 0 ? "success" : "error" ) : null}><b>Cupo disponible: </b> {/* {Constants.copFormat(cupo ? ( cupo.Cupo - cupo.saldo > 0 ?  cupo.Cupo - cupo.saldo : 0 ) : 0)} */}
                                            {Constants.formatNumber(cupo ? ( cupo.Cupo - cupo.saldo > 0 ?  cupo.Cupo - cupo.saldo : 0 ) : 0)}
                                        </p>
                                    </div>  
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="pedido-list">                       
                        <div className = "table-responsive">                                                                  
                            <TableCheck />                                                  
                        </div>                       
                    </div>                     
                </div>
            </div>
            <Tabbar item={6}/>

        </React.Fragment>
    )
}


export default Cartera;