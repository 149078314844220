import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Progress from '../util/Progress';
import logo_login from '../../assets/img/logo_login.png';
import maqueta from '../../assets/img/fondo_login.png';
import Fondo from '../../assets/img/Fondo_3000x3000.png';
import LoginModal from '../modal/LoginModal';
import AlertModal from '../modal/AlertModal';
import RegistroModal from '../modal/RegistroModal';
import CodigoActivacionModal from '../modal/CodigoActivacionModal';
import { blue } from '@material-ui/core/colors';

const Login = (props) => {

    const [nit, setNit] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [error, setError] = React.useState("");
    const [correoCodigo, setCorreoCodigo] = React.useState("");
    const [celularAyuda, setCelularAyuda] = React.useState("");
    const [validacion, setValidacion] = useState({ Telefonos: [] });
    const [empresa, setEmpresa] = React.useState("");
    const [alerta, setAlerta] = useState();

    const handleSubmit = event => {
        setLoader(true);
        setError()
        let url = Constants.WSEMPRESA + "?nit=" + ("0" + String(parseInt(nit)));
        axios.get(url)
            .then(resEmpresa => {
                if (resEmpresa.data.length > 0) {
                    setEmpresa(resEmpresa.data)
                    url = Constants.WSVALIDARCLIENTE;
                    let data = {
                        "Nit": ("0" + String(parseInt(nit))),
                        "DeviceType": "Web"
                    };
                    axios.post(url, data)
                        .then(res => {
                            localStorage.setItem("carritoAbandonado", 0)
                            setLoader(false);
                            setValidacion(res.data);
                            localStorage.setItem("carritoRecuperado", "true")

                            if (res.data.Activo == 0) {
                                document.querySelector(".modal-registro").classList.toggle("show-modal");
                            } else {
                                if (res.data.Correos.length > 0) {
                                    let arrayCorreos = {
                                        correoCompras: res.data.Correos[0].substring(0, 2) + "*".repeat(res.data.Correos[0].indexOf("@", 0) - 2) + res.data.Correos[0].substring(res.data.Correos[0].indexOf("@", 0), res.data.Correos[0].length),
                                        correoPagos: res.data.Correos[1].substring(0, 2) + "*".repeat(res.data.Correos[1].indexOf("@", 0) - 2) + res.data.Correos[1].substring(res.data.Correos[1].indexOf("@", 0), res.data.Correos[0].length)
                                    }
                                    setCorreoCodigo(arrayCorreos)
                                    document.querySelector(".modal-login").classList.toggle("show-modal");
                                } else {
                                    setAlerta({
                                        tipo: "info",
                                        titulo: "Datos del cliente",
                                        mensaje: "El cliente identificado con código: " + nit + " no cuenta con correos registrados.",
                                        btnOk: "Aceptar",
                                        fnOk: function () { }
                                    })
                                }
                            }
                        });
                } else {
                    setLoader(false);
                    setError(<p className="miFuente" >No se encontraron coincidencias con el código SAP ingresado.
                        <br />
                        Recuerda que tu código SAP lo podrás encontrar en la factura impresa.
                    </p>
                    );
                }
            }).catch((err) => {
                console.log(err);
            });
        event.preventDefault();
    }

    setTimeout(function () {
        if (document.querySelector(".login-content")) {
            document.querySelector(".login-content").classList.add("show");
        }
    }, 3000);

    useEffect(() => {
        let validate = localStorage.getItem("codigo");
        let URLactual = (new URL(document.location)).searchParams;
        let codCliente = ("0" + String(parseInt(nit)))
        let url = Constants.WS_RECUPERAR_CARRITO_ABANDONADO_CLIENTE + "?codCliente=" + codCliente;
        let codigo = URLactual.get("ca");

        if (validate != null) {
            if (codigo != null) {
                localStorage.setItem("codigoCa", codigo)
                localStorage.setItem("activateAbandone", "true")
            } else {
                axios.get(url).then(res => {
                    if (res.data.IDCarrito != null) {
                        let idCarritoAbandonadoCliente=res.data.IDCarrito
                        localStorage.setItem("codigoCa", res.data.IDCarrito)
                        localStorage.setItem("activateAbandone", "true")
                    }
                });
            }
            props.history.push('/catalogo');
        } else {
            if (codigo != null) {
                localStorage.setItem("codigoCa", codigo)
            } else {
                axios.get(url).then(res => {
                    if (res.data.IDCarrito != null) {
                        let idCarritoAbandonadoCliente=res.data.IDCarrito
                        localStorage.setItem("codigoCa", res.data.IDCarrito)
                    }
                });
            }
        }
    })

    return (
        <div className="login" style={{ backgroundImage: `url(${Fondo})` }}>
            <div className="login-content">
                <div className="splash" id="splash" >
                    <div className="logo-img">
                        {/* <img src={logo} alt="logo app"></img> */}
                    </div>
                    <div className="maqueta-img">
                        <img src={maqueta} alt="logo app"></img>
                    </div>
                    <div className="footer">
                        <div className="footer-text">
                            {/* <p>Celuweb desde hace varios meses venia trabajando en la siguiente iniciativa, y quire poner a disposición de sus clientes como una contingencia la plataforma Emart</p> */}
                        </div>
                        <div className="footer-img">
                            {/* <img src={subtitulo} alt="logo app"></img> */}
                        </div>
                    </div>
                </div>
                <div className="login-form" id="login-form">
                    <div className="logo-img">
                        <img src={logo_login} alt="logo app"></img>
                    </div>
                    {loader ? <Progress color={"#007daf"} /> : (
                        <form onSubmit={handleSubmit}>
                            <div className="form-control">
                                <label className="miFuente">Código SAP:</label>
                                <input
                                    type="number"
                                    placeholder="Código SAP "
                                    name="nit"
                                    id="nit"
                                    onChange={e => setNit(e.target.value)}
                                    value={nit}
                                    style={{ className: "miFuente" }}
                                >
                                </input>
                                {error ? (
                                    <span className="error">
                                        <p>{error}</p>
                                    </span>
                                ) : null}
                            </div>`
                            <button style={{ className: "miFuente" }} type="submit" className="btn-large">Ingresar</button>

                        </form>
                    )}
                    <br></br>
                    <div className="info" style={{ borderBottom: "1px solid #ebebeb", paddingBottom: "10px" }}>
                        <p className="miFuente" >
                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                            <b> Para soporte escríbenos a info@epa.com.pa</b>
                        </p>
                    </div>

                    <RegistroModal empresa={empresa} nit={("0" + String(parseInt(nit)))} validacion={validacion} correoCodigo={correoCodigo} celularAyuda={celularAyuda} />
                    <CodigoActivacionModal validacion={validacion} empresa={empresa} />
                    <LoginModal nit={("0" + String(parseInt(nit)))} empresa={empresa} correoCodigo={correoCodigo} />

                    <div className="logo-footer">
                        {/* <img  src={logo_login} alt="logo app"></img>
                        <img  src={logo_login} alt="logo app"></img> */}
                    </div>

                </div>
            </div>
            {alerta ? <AlertModal alerta={alerta} updateAlerte={setAlerta}></AlertModal> : null}
        </div>
    )
}


export default withRouter(Login);