import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { useForm } from "react-hook-form";
import Progress from '../util/Progress'

//tagmanager
// import TagManager from 'react-gtm-module'
import ReactGa from 'react-ga'
import { BorderColor } from '@material-ui/icons';

const Payment = (props) => {

    const { register, handleSubmit, errors, ...rest } = useForm();
    const [nameFile, setNameFile] = useState();
    const [file, setFile] = useState();
    const [extension, setExtension] = useState();
    const [bancos, setBancos] = useState();
    const [banco, setBanco] = useState();
    const [bancosDestino, setBancosDestino] = useState();
    const [bancoDestino, setBancoDestino] = useState();
    const [mensaje, setMensaje] = useState()
    const [error, setError] = useState()
    const [loader, setLoader] = useState();
    const [observacion,setObservacion] = useState();
    

    const handleFile = (e) => {
        if(e.target.files.length>0){
            setNameFile(e.target.files[0].name)
            setExtension((e.target.files[0].name).split(".").pop())
            setFile(e.target.files[0])
        }
    }

    const handleBancos = () =>{
        axios.get(Constants.WSBANCOS).then((response)=>{
            setBancos(response.data)            
        }).catch((error)=>{
            console.log(error)           
        })
        axios.get(Constants.WSBANCODESTINO).then((response)=>{
            console.log(response);
            setBancosDestino(response.data)        
        }).catch((error)=>{
            console.log(error)
        })
    }

    const submit = (data) =>{
        if(props.type==3){
            if(!banco){
                setError({banco:true})
                return
            }
        }if(!bancoDestino){
            setError({bancoDestino:true})
            return
        }
        
        setLoader(true)
        let fecha = new Date();
        let numeroDoc = "B2B" + Auth.getCodCliente() + fecha.getFullYear() + (fecha.getMonth()+1) + fecha.getDate()  + fecha.getHours() + fecha.getSeconds() + fecha.getMilliseconds();
        let facturas = []
        props.data.map((item,index)=>{
            console.log(item);
            facturas.push({
                numeroDoc:numeroDoc,
                factura:item.referencia,
                valor:item.saldo,
                documentoSAP:item.documentoSAP,
                ejercicio: item.ejercicio,
                documentoFiscal: item.documentoFiscal,
                financiero: item.financiero,
                codCliente: item.codCliente
            })
            let body = {    
                listaRecaudos:[{
                    numeroDoc: numeroDoc,
                    fecha: moment().format("YYYY/MM/DD"),
                    codCliente: Auth.getCodCliente(),
                    total: props.total,
                    formaPago: props.type == 1 ? "7": "6",
                    bancoOrigen: banco ? banco : 0,
                    bancoDestino: bancoDestino ? bancoDestino : 0,
                    fechaFp:  moment().format("YYYY/MM/DD"),
                    facturas: facturas,
                    observaciones: observacion ? observacion : '',
                }]
            };

            //
        console.log(body);
        axios.post(Constants.WSENVIARRECAUDO + Auth.getCodCliente(), body).then((response)=>{
            console.log(response);
            setMensaje(response.data.Mensaje)
            if(file){
                // TagManager.dataLayer({
                //     dataLayer:{
                //         event:'checkout',
                //         ecommerce:{ 
                //             actionField:{
                //                 step:2
                //             },
                //             invoices:props.invoices
                //         }
                //     }
                // })
                const formData=new FormData()
                formData.append('file',(file ? file : null))
                let url = Constants.WSUPLOADFILE+Auth.getCodCliente()+"&numeroDoc="+numeroDoc+"&extencion="+(extension?extension:null)
                axios.post(url, formData,{
                    headers:{
                        "Content-type": "multipart/form-data"
                    }
                }).then((responseUpload)=>{
                    setTimeout(()=>{
                        setLoader(false)
                        ReactGa.event({
                            category:"Payment Proccess",
                            action:"payment accepted",
                            label:"Pago Cuenta Bancaria"
                        })
                        // TagManager.dataLayer({
                        //     dataLayer:{
                        //         event:'purchase',
                        //         ecommerce:{ 
                        //            purchase:{
                        //                actionField:{
                        //                     id:numeroDoc,
                        //                     renueve:props.total,
                        //                     affiliation: '',     
                        //                     tax: 0,    
                        //                     shipping: 0
                        //                },
                        //                invoices:props.invoices
                        //            }
                        //         }
                        //     }
                        // })
                        // window.location.href="/cartera"
                        
                    },2000)
                })               
            }else{
                setTimeout(()=>{
                    setLoader(false)
                    // window.location.href="/cartera"
                },2000)
            }
           
                
            
            
        }).catch((error)=>{     
            console.log(error);
            ReactGa.event({
                category:"Payment Proccess",
                action:"payment declined",
                label:"Pago Cuenta Bancaria"
            })   
            if(error.response)
                setError({submit:error.response.data.Message})
            else
                setError({submit:"Ocurrio un error inesperado al enviar tu información, por favor intentalo mas tarde."})

            setLoader(false)
        })
        });
    }

    useEffect(() => {
        handleBancos()
    },[])


    return (
      <React.Fragment>
           <div className="transferencia">
                <h1>{props.type == 1 ? "Depósito Bancario" : "Transferencia Bancaria (ACH)"}</h1>
                <p style={{fontSize:"1.5rem", color:"#808B96"}}>{"TOTAL: $ "+props.total}</p> 
                {mensaje == null ?
                    <div className="body">
                        { loader ? <Progress color={"#007daf"} /> : (  
                            <div>
                                <form onSubmit={handleSubmit(e=>submit())}>                                
                                    {props.type==3 ?
                                        <React.Fragment>
                                            <p>BANCO</p>
                                            <select id="banco" name="banco"
                                            onChange={e=>setBanco(e.target.value)} 
                                            style={{border: error && (error.banco && "1px solid red")}}>
                                                <option>BANCO</option>
                                                {bancos ? (
                                                    bancos.map((item,index)=>(
                                                        <option id="banco" name="banco" key={index} value={item.codigo}>{item.nombre}</option>
                                                    ))
                                                )
                                                : null}
                                            </select><br/>
                                            {error && (error.banco && <span className="error-form">*Campo Obligatorio</span>)}
                                        </React.Fragment>
                                        : null 
                                    }
                                    <p>Cuenta Destino</p>
                                    <select id="bancoDestino" name="bancoDestino" 
                                        onChange={e=>setBancoDestino(e.target.value)}
                                        style={{border: error && (error.bancoDestino && "1px solid red")}}
                                    >
                                        <option>CUENTA DESTINO</option>
                                        {bancosDestino ? (
                                            bancosDestino.map((item,index)=>(
                                                <option id="bancoDestino" name="bancoDestino" key={index} value={item.id}>{item.nombre+" "+item.cuenta}</option>
                                            ))
                                        )
                                        : null}
                                    </select><br/>
                                    {error && (error.bancoDestino && <span className="error-form">*Campo Obligatorio</span>)}
                                    <br/> <br/>
                                    
                                    <div className="upload">
                                        <label className="custom-file-upload" style={{border:errors.uploadFile && "1px solid red"}}>
                                            <input type="file" className="none"
                                            if="uploadFile"
                                            name="uploadFile" 
                                            onChange={e=>handleFile(e)} 
                                            accept="image/png, .jpeg, .jpg, application/pdf"
                                            ref={register({required:true})}
                                            />
                                            {nameFile ? nameFile : "Buscar archivo..."}
                                        </label>
                                        {errors.uploadFile && <span className="error-form">*Campo Obligatorio</span>}
                                        {/* <label for="custom-file-upload" className="filupp">
                                            <span className="filupp-file-name js-value">{nameFile ? nameFile : "Buscar archivo..."}</span>
                                            <input type="file" name="attachment-file" id="custom-file-upload" onChange={e=>handleFile(e)}/>
                                        </label>*/}
                                    </div>
                                    <br/>
                                    <div>
                                        <input 
                                        className="forms"
                                        placeholder="OBSERVACIÓN" 
                                        onChange={e=>setObservacion(e.target.value)}
                                        ref={register({required:false})} 
                                        id="observaciones" 
                                        name="observaciones" 
                                        maxLength="25"
                                        style={{borderColor:errors.bill_to_surname?"red":null}}     
                                        />
                                    </div>    

                                    {error && error.submit ? (
                                        <span className="error-form">{error.submit}</span>
                                    ): null }<br/>
                                    {/* <div className="submit">
                                    </div> */}
                                    <button type="submit" className="btn-large" /* onClick={e=>handleSubmit()} */ >Enviar</button>
                                    <button type="button" className="outline" onClick={e=>props.handleBack()} >Cancelar</button>
                                </form>
                            </div>
                        )}
                    </div>
                : <div className="success">
                    <p>{mensaje}</p>
                </div> 
                }
           </div>
      </React.Fragment>
    )
    
  }

export default Payment;