import React, { useEffect, useState } from 'react';
import Progress from '../util/Progress';
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import DetallePagoModal from '../modal/DetallePagoModal';
import ReactGa from 'react-ga'
var moment = require('moment')

const HistorialPagos = () => {

    const [listHistorial, setListHistorial] = useState()
    const [detalle, setDetalle] = useState();
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState();
    const [fechaInicio, setFechaInicio] = useState()
    const [fechaFin, setFechaFin] = useState()
    const [estado, setEstado] = useState(-1)

    const handleHistorial = () =>{
        setLoader(true)
        axios.get(Constants.WSHISTORIALPAGOS + Auth.getCodCliente() + "&fechaInicial="+moment(fechaInicio).format("YYYY/MM/DD")+"&fechaFinal="+moment(fechaFin).format("YYYY/MM/DD")+(estado!=-1 ? "&estado="+estado : '')).then((response)=>{
            console.log(response);
            setListHistorial(response.data)
            setLoader(false)
        })
    }

    const handleSelect = (detalle, codCliente, observaciones, adjunto, numeroDoc) =>{
        if(detalle.length>0){
            setDetalle({
                detalle:detalle,
                adjunto:adjunto,
                codCliente:codCliente,
                observaciones:observaciones,
                numeroDoc:numeroDoc
            })
            //if(detalle)
                //document.querySelector(".modal-detalle-pago").classList.toggle("show-modal")
        }else{
            setAlert({
                tipo:"warning",
                titulo:"Detalle",
                mensaje:"Lo sentimos este pago no cuenta con detalles asociados.",
                btnOk:"Aceptar",
                fnOk:function(){}
            })
        }
    }

    useEffect(()=>{
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={7} />
            </div>            
            <div className="cartera">
                <div className="card">
                    <h1>Historial de Pagos</h1>
                    <div className="pedido-list"> 
                        <div id="wrapper">                                                               
                            <form>                     
                                <div className="col-4">
                                    <label>Desde:
                                        <input type="date" onChange={e=>setFechaInicio(e.target.value)} />
                                    </label>                    
                                </div>
                                <div className="col-4">
                                    <label>Hasta:
                                        <input type="date" onChange={e=>setFechaFin(e.target.value)} />
                                    </label>
                                </div>    
                                <div className="col-4">
                                    <label>Estado:
                                        <select id="estado" name="estado" onChange={e=>setEstado(e.target.value)}>
                                            <option value={-1} >Todos los estado</option>
                                            <option value="0">Rechazados</option>
                                            <option value="1">Aprobados</option>
                                            <option value="2">Pendientes</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="col-4">
                                    <button type="button" style={{width:"28%"}} className="btn-large" onClick={e=>handleHistorial()}>Buscar</button>
                                    <button type="button" style={{width:"37%"}} className="outline" onClick={e=>setListHistorial()}>Limpiar filtro</button>
                                </div>                  
                            </form>                
                        </div>
                        { loader ? <Progress color={"#007daf"} /> : (                   
                            <div className >  
                                {listHistorial ? (
                                    <React.Fragment>
                                        <DataTable 
                                            value={listHistorial ? listHistorial : []} 
                                            paginator resizableColumns rows={ 10 } 
                                            autoLayout={true}
                                            >
                                            <Column  style={{width:"22%", textAlign:"center"}} field="numeroDoc" header="NÚMERO DE DOCUMENTO" ></Column>
                                            <Column style={{textAlign:"center"}} field="Nombre" header="NOMBRE" body={(rowData, column)=>
                                                (Auth.getIsChain() == 1) ? rowData['cadena'] : rowData['Nombre']
                                                }>
                                            </Column>
                                            {/* <Column field="Direccion" header="DIRECCIÓN"></Column> */}
                                            <Column field="fecha" header="FECHA" style={{textAlign:"center"}} body={(rowData, column) => 
                                                    (new Date(rowData['fecha'])).toLocaleDateString()
                                                }>
                                            </Column>
                                            <Column field="estado" header="ESTADOS" style={{textAlign:"center"}}></Column>
                                            <Column field="metodoPago" header="MÉTODO PAGO" style={{textAlign:"center"}}></Column>     
                                            <Column field="observaciones" header="OBSERVACIONES" style={{textAlign:'center'}} body={(rowData, column) =>
                                                    (rowData['observaciones'] ? (String(rowData['observaciones'])).substring(0,30)  + ( rowData['observaciones'].length > 15 ? "..." : "" ) : "" )
                                                }>
                                            </Column>                                           
                                            <Column field="valor" header="VALOR" style={{textAlign:"center"}} body={(rowData, column) => 
                                                    Constants.formatNumber(rowData['valor'])
                                                }>
                                            </Column>   
                                            <Column onEditorCancel={true} header="DETALLE" style={{textAlign:"center"}} body={(rowData, column)=>(
                                                (rowData['detalle']).length>0 ? <a  onClick={e=>handleSelect(rowData['detalle'],rowData['codCliente'], rowData['observaciones'], rowData['adjunto'], rowData['numeroDoc'])}>Ver detalle</a> : "Sin detalle"
                                            )}></Column>                                             
                                        </DataTable>
                                    </React.Fragment>
                                ) : null}
                                {/* </div> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Tabbar item={7}/>
            {alert ? <AlertModal alerta={alert} updateAlerta={setAlert}></AlertModal> : null}
            {detalle ? <DetallePagoModal detalle={detalle} ></DetallePagoModal> : null }
            
        </React.Fragment>
    )
}


export default HistorialPagos;