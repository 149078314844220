import React, {useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    var columns=['Código','Cantidad']

    const uploadFile=(e)=>{

    }

    useEffect(() => {
    },[])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={4} />
            </div>

            <div className="excel">
                <form >
                    <div className="align-button"> 
                        <div className="custom-file">
                            <label htmlFor="cargarExcel">Cargar excel</label>                                               
                            <input id="cargarExcel" onChange={(e) => uploadFile(e)} type="file" className="left" /> 
                        </div>
                        <ExcelFile element={<a id="descargarPLantilla" className="button download">Descargar plantilla</a>}>
                            <ExcelSheet data={columns} name="Leaves">
                                <ExcelColumn label="Código" />
                                <ExcelColumn label="Cantidad" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div>
                            <a id="agregarCarrito" className="button right">Agregar al carrito</a>
                        </div>
                    </div> 
                </form>
            </div>

            <Tabbar item={4}/>
        </React.Fragment>
    )
}

export default Excel;