import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import close from '../../assets/icon/close_grey.svg';
import * as Constants from '../../constants/global';

const NotasCreditoModal = (props) => {

    const handleClose = () => {
        document.querySelector(".modal-detalle-ncredito").classList.remove("show-modal");
    }

    useEffect(()=>{
        
    },[])

    return (
        <div className="modal modal-detalle-ncredito">
            <div className="modal-content" style={{width:"100%"}}>       
                <div className="title">
                    <p>Notas Credito</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div >    
                    <div >
                        {props.notasCredito ? (
                            <React.Fragment>
                                <DataTable 
                                    value={props.notasCredito} 
                                    paginator resizableColumns rows={10 }
                                    autoLayout={true}  
                                    onRowClick={e => null}
                                    >
                                    <Column field="documento" header="DOCUMENTO" ></Column>
                                    <Column field="NumFiscal" header="NUMERO FISCAL"></Column>
                                    <Column field="saldo" header="SALDO" body={(rowData, column) => 
                                            Constants.formatNumber(rowData['saldo'])
                                        }>
                                    </Column>                                                
                                </DataTable>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NotasCreditoModal);