import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import close from '../../assets/icon/close_grey.svg';
import * as Constants from '../../constants/global';

const DetallePedidoModal = (props) => {

    const handleClose = () => {
        document.querySelector(".modal-detalle-pedido").classList.remove("show-modal");
    }

    useEffect(()=>{
    },[])

    return (
       <React.Fragment> 
        <div className="modal modal-detalle-pedido" style={{overflow:"scroll"}}>
            <div className="modal-content" style={{width:"100%"}}>       
                <div className="title">
                    <p>Detalle pedido</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div  >    
                    <div >
                        {props.detalle ? (
                            <React.Fragment>
                                <DataTable 
                                    value={props.detalle} 
                                    paginator resizableColumns rows={10 }
                                    autoLayout={true} 
                                    onRowClick={e => null}
                                    >
                                    <Column field="nombre" header="PRODUCTO" ></Column>
                                    <Column field="codigo" header="CÓDIGO"></Column>
                                    <Column field="cantidad" header="CANTIDAD"></Column>
                                    <Column field="precio" header="SUBTOTAL" body={(rowData, column) => 
                                            Constants.formatNumber(rowData['precio'])
                                        }>
                                    </Column>   
                                    <Column field="iva" header="ITBMS" body={(rowData, column) => 
                                            Constants.formatNumber(rowData['iva'])
                                        }>
                                    </Column>   
                                    <Column field="total" header="TOTAL" body={(rowData, column) => 
                                            Constants.formatNumber(rowData['total'])
                                        }>
                                    </Column>                                               
                                </DataTable>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="modal modal-detalle-pedido">
            <div className="modal-content">       
                <div className="title">
                    <p>Detalle Factura</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { props.detalle ? Object.keys(props.detalle).map((item, i) => (
                            <div className="item" style={{cursor:"default"}} key={i} >
                                <p><b>CÓDIGO DE PRODUCTO:</b> {props.detalle[item].codigo}</p>
                                <p><b>DESCRIPCIÓN:</b> {props.detalle[item].nombre_producto ? props.detalle[item].nombre_producto : props.detalle[item].nombre}</p>
                                <p><b>CANTIDAD:</b> {props.detalle[item].cantidad}</p>
                                <p><b>SUBTOTAL:</b> 
                                    {" $" + new Intl.NumberFormat("es-CO", {
                                            numberingSystem: "latn",
                                            style: "decimal",
                                            currency: "USD",
                                            
                                    }).format(props.detalle[item].precio)}
                                </p>
                                <p><b>IVA:</b> 
                                    {" $" + new Intl.NumberFormat("es-CO", {
                                            numberingSystem: "latn",
                                            style: "decimal",
                                            currency: "USD",
                                            
                                    }).format(props.detalle[item].iva)}
                                </p>
                                <p><b>TOTAL:</b> 
                                    {" $" + new Intl.NumberFormat("es-CO", {
                                        numberingSystem: "latn",
                                        style: "decimal",
                                        currency: "USD",
                                        
                                    }).format(props.detalle[item].total)}
                                </p>
                            </div>
                        )) : null }
                    </div>
                </div>
            </div>
        </div> */}
       </React.Fragment> 
    )
}

export default withRouter(DetallePedidoModal);