import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import DetallePedidoModal from '../modal/DetallePedidoModal';
import Progress from '../util/Progress';
import Auth from '../../helpers/Auth';
import * as Constants from '../../constants/global';
import ReactGa from 'react-ga'
const moment = require('moment')


const UltimosPedidos = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));
    const [loader, setLoader] = useState(false);
    const [ultimosPedidos, setUltimosPedidos] = useState();
    const [temp, setTemp] = useState();
    const [fechaInicio, setFechaInicio] = useState()
    const [fechaFin, setFechaFin] = useState()
    
    const [detalle, setDetalle] = useState();

    const loadUltimosPedidos = async () => {
        setLoader(true)
        let codigo = Auth.getCodProductos() ? Auth.getCodProductos() : Auth.getCodCliente()
        let url =   Constants.WSPEDIDO +
                    "?origen=" + (empresa ? empresa.codigo_empresa : '') + 
                    "&cliente=" + codigo;

        axios.get(url).then(res => {
            setUltimosPedidos(res.data);
            setTemp(res.data)
            setLoader(false)
            searchFecha(res.data)
        });
    }

    const handleDetallePedido = (dataDetalle) => {
        setDetalle(dataDetalle)
        if(dataDetalle)
            document.querySelector(".modal-detalle-pedido").classList.toggle("show-modal");
    }

    const searchFecha = (data) => {
        let fi = moment(fechaInicio).format("YYYY/MM/DD")
        let ff = moment(fechaFin).format("YYYY/MM/DD")
        let array = (ultimosPedidos ? ultimosPedidos : data).filter(function(item){
             return ( moment(item.fecha).format("YYYY/MM/DD")>=fi && moment(item.fecha).format("YYYY/MM/DD")<=ff )
        })
        setTemp(array)
    }

    const handleDefaultDate = () =>{
        var date = new Date(), y = date.getFullYear(), m = date.getMonth(); 
        var firstDay = new Date(y, m, 1); 
        var lastDay = new Date(y, m + 1, 0);   
        setFechaInicio(moment(firstDay).format("YYYY-MM-DD"))
        setFechaFin(moment(lastDay).format("YYYY-MM-DD"))
    }

    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search)
        loadUltimosPedidos();
        handleDefaultDate()
             
    },[])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={5}  />
            </div>

            <div className="cartera">
                <div className="card">
                    <h1>Últimos Pedidos</h1>
                    <div className="pedido-list">    
                        <div id="wrapper">                                                               
                            <form>                     
                                <div className="col-3">
                                    <label>Desde:
                                        <input type="date" defaultValue={fechaInicio} onChange={e=>setFechaInicio(e.target.value)} />
                                    </label>                    
                                </div>
                                <div className="col-3">
                                    <label>Hasta:
                                        <input type="date" defaultValue={fechaFin} onChange={e=>setFechaFin(e.target.value)} />
                                    </label>
                                </div>  
                                <div className="col-3">
                                    <button type="button" style={{width:"40%"}} className="btn-large" onClick={e=>searchFecha()}>Buscar</button>
                                    <button type="button" style={{width:"40%"}} className="outline" onClick={e=>(handleDefaultDate(), searchFecha() )}>Limpiar filtro</button>
                                </div>                                                
                            </form>                
                        </div>
                        { loader ? <Progress color={"#007daf"} /> : (                   
                            <div className = "table-responsive">  

                                {/* <div className="pedido-list sugerido"> */}
                                    {ultimosPedidos ? (
                                        <React.Fragment>
                                            {/* <div className="product-list">
                                                {Object.keys(ultimosPedidos).map((item, i) => (
                                                    <div className="item item-carrito" key={i} style={{cursor:"pointer"}} onClick={() => handleDetallePedido(ultimosPedidos[item].detalle)}>
                                                        <div className="start">
                                                            <p className="nombre"><b>TIPO PEDIDO:</b> {(ultimosPedidos[item].numero_doc.substring(0, 1) == "E" ? "Cliente" : "Vendedor" )}</p>
                                                            <p className="nombre"><b>NÚMERO DOCUMENTO:</b> {ultimosPedidos[item].numero_doc}</p>
                                                            <p className="nombre"><b>FECHA:</b> { retornarFecha(ultimosPedidos[item].fecha.replace("T", " "))}</p>
                                                            // <p className="precio">Total: {
                                                            //         new Intl.NumberFormat("es-CO", {
                                                            //         style: "currency",
                                                            //         currency: "USD"
                                                            //         }).format(Object.values(ultimosPedidos[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))
                                                            //     }
                                                            // </p>
                                                            <p className="nombre"><b>NÚMERO PEDIDO:</b> {ultimosPedidos[item].numeroPedido}</p>
                                                            <p className="nombre"><b>DIRECCIÓN DE ENTREGA:</b> {ultimosPedidos[item].direccionEntrega}</p>
                                                            // <p className="precio">Valor Pedido: {
                                                            //         new Intl.NumberFormat("es-CO", {
                                                            //         style: "currency",
                                                            //         currency: "USD"
                                                            //         }).format(ultimosPedidos[item].valorPedido)
                                                            //     }
                                                            // </p> 
                                                            <p className="nombre"><b>NÚMERO DE FACTURA:</b> {ultimosPedidos[item].factura}</p>
                                                            <p className="precio"><b>VALOR FACTURADO:</b>  //{Constants.copFormat(ultimosPedidos[item].valorFacturado)}
                                                                {" $" + 
                                                                    new Intl.NumberFormat("es-CO", {
                                                                        numberingSystem: "latn",
                                                                        style: "decimal",
                                                                        currency: "USD",
                                                                        
                                                                    }).format(ultimosPedidos[item].valorFacturado)
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="end">
                                                        </div>
                                                    </div>
                                                ))}
                                            </div> */}
                                            <DataTable 
                                                value={temp ? temp : ultimosPedidos} 
                                                paginator resizableColumns rows={10 }
                                                autoLayout={true} >
                                                <Column field="codigo" header="CÓDIGO" ></Column>
                                                <Column field="numeroPedido" header="NUM. PEDIDO"></Column>
                                                <Column field="numero_doc" header="NUM. DOC" ></Column>
                                                <Column field="fecha" header="FECHA" body={(rowData, column) => 
                                                        (new Date(rowData['fecha'])).toLocaleDateString()
                                                    }>
                                                </Column>
                                                {/* <Column field="valorFacturado" header="VALOR FACTURADO" body={(rowData, column) => 
                                                        " $" + new Intl.NumberFormat("es-CO", {
                                                            numberingSystem: "latn",
                                                            style: "decimal",
                                                            currency: "USD",
                                                            
                                                        }).format(rowData['valorFacturado'])
                                                    }>
                                                </Column> */}
                                                <Column field="valorPedido" header="VALOR PEDIDO" body={(rowData, column) => 
                                                        Constants.formatNumber(rowData['valorPedido'])
                                                    }>
                                                </Column>
                                                <Column header="Detalle" body={(rowData, column)=>(
                                                    rowData['detalle'].length>0 ? <a onClick={ ()=> handleDetallePedido(rowData['detalle'])}>Ver detalle</a> : "Sin detalle"
                                                )}></Column>
                                            </DataTable>
                                        </React.Fragment>
                                    ) : null}
                                {/* </div> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Tabbar item={5}/>

            <DetallePedidoModal detalle={detalle}/>
            
        </React.Fragment>
    )
}


export default UltimosPedidos;